import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: ".625rem .6875rem .8125rem",
        paddingRight: "1rem",
        width: "50%"
    },
    heading: {
        marginTop: "0px !important",
        color: theme.palette.primary.main,
        "font-weight": `${theme.typography.fontWeightMedium} !important`,
        lineHeight: "22px !important",
        fontSize: "1rem",
    },
    subtitleClass: {
        color: "#3C3C3C",
        fontWeight: 700,
    },
    textStyle: {
        marginTop: 3,
    },
    gridContainer: {
        marginBottom: theme.spacing(0),
        display: "flex",
        alignItems: "center",
    },
    subTitle: {
        marginLeft: 5,
        marginTop: "0px !important",
    },
    textField: {
        borderRadius: "5px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "5px",
        },
        marginBottom: theme.spacing(1),
    },
    logo: {
        width: "2.1875rem",
        height: "2.1875rem",
        backgroundColor: "#ECECEA",
        borderRadius: "50%",
        minWidth: "2.1875rem",
    },
    headerAlignment: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    errorStyle: {
        marginTop: "4px",
        marginLeft: "14px",
        marginRight: "14px",
        color: "#FE5A5A",
        fontSize: "0.75rem",
    },
    btnGrid: { textAlign: "center", marginTop: 22, },
}));

export default useStyles;
