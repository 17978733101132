import { addDriver, updateDriverInfo } from "./actions";
import { get, post, put } from "../../services/api/api";
import { toast } from "react-hot-toast";

export const fetchDrivers =
  (org_id: string, offset: number) => (dispatch: any) => {
    get(
      `/api/v1/organizations/${Number(org_id)}/drivers?offset=${offset}`
    ).then((resp) => {});
    return;
  };

export const addNewDriver = (driverInfo, orgId: any, nav) => {
  return async (dispatch: any) => {
    try {
      const url = `/api/v1/organizations/${Number(orgId)}/users`;
      await post(url, driverInfo);
      dispatch(addDriver(driverInfo));
      toast.success("Successfully created new driver!");
      nav("/drivers");
    } catch (err) {
      const error = err as Error;
      toast.error(
        "Something went wrong while creating new driver: " + error.message
      );
      console.error(err);
    }
  };
};

export const updateDriver = (driverId, updatedDriverInfo) => {
  return async (dispatch) => {
    try {
      const url = `/api/v1/driver/${driverId}`;
      await put(url, { driver: updatedDriverInfo });
      dispatch(updateDriverInfo(driverId, updatedDriverInfo));
      toast.success("Successfully updated driver!");
    } catch (err) {
      const error = err as Error;
      toast.error(
        "Something went wrong while updating driver: " + error.message
      );
      console.error(err);
    }
  };
};
