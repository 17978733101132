import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useStyles as styles } from "./styles";
import Button from "@material-ui/core/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/types";

import { changeUserAccess } from "../store/authentication/access/actions";
import { useAppDispatch } from "../hooks";
import { get } from "../services/api/api";
import { getOrganization } from "../store/organizations/middleware";

interface PharmaciesProps {
  org: any;
  user: any;
  readOnly?: boolean;
}

interface Pharm {
  id: number;
  name: string;
  unit: string;
  address: string;
  addressKey: string;
  lat: number;
  long: number;
  dist: number;
  org_settings: {};
  phone_number: string;
  email: string;
  org_tags: string[];
  date_created: string;
}

const SwitchMenu: React.FC<PharmaciesProps> = (props) => {
  const dispatch = useAppDispatch();
  const [pharmaciesMock, setPharmaciesMock] = useState<Pharm[]>();

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const pharmaciesList: Pharm[] = [];
      for (const access of userAccess) {
        try {
          const response = await new Promise((resolve, reject) => {
            const url: string = `/api/v1/organizations/${Number(
              access.org_id
            )}`;
            get(url)
              .then((response) => resolve(response.data))
              .catch((error) => reject(error));
          });

          const pharmacy: any = response;

          if (pharmacy) {
            pharmaciesList.push(pharmacy);
          }
        } catch (error) {
          console.error(
            `Error fetching organization ${access?.org_id}:`,
            error
          );
        }
      }
      setPharmaciesMock(pharmaciesList);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nav = useNavigate();
  const classes = styles();

  const userAccess = useSelector((state: RootState) => state.user.access);

  const [selected, setSelected] = useState(userAccess[0].org_id);

  const handleCreateClick = () => {
    if (pharmaciesMock && pharmaciesMock.length > 0) {
      const selectedPharmacy = pharmaciesMock?.find(
        (pharmacy) => pharmacy?.id === selected
      );





      if (selectedPharmacy) {
        const updatedAccess = userAccess.filter(
          (pharmacy) => pharmacy.org_id !== selectedPharmacy.id
        );
        const selectedAccess = userAccess.find(
          (pharmacy) => pharmacy.org_id === selectedPharmacy.id
        );
        if (selectedAccess) {
          updatedAccess.unshift(selectedAccess);
        }
        dispatch(getOrganization(Number(selectedPharmacy.id)));
        dispatch(changeUserAccess(updatedAccess));

        updatedAccess[0].org_type === "pharmacy"
          ? nav(`/deliveries`)
          : nav(`/pharmacies`);
      }
    }
  };

  const handleMenuItemClick = (key: number) => {


    console.log("selecd key ", key)
    setSelected(key);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid className={classes.gridContainer}>
          <Grid item xs={12} md={12}>
            <img
              className={classes.logo}
              src="https://weel-branding.s3.ca-central-1.amazonaws.com/icon.png"
              alt=""
            />

            <div className={classes.listContainer}>
              <List className={classes.pharmaciesList}>
                {pharmaciesMock?.map((pharmacy) => (
                  <ListItem
                    className={classes.pharmacyItem}
                    key={pharmacy.id}
                    button
                    onClick={() => handleMenuItemClick(pharmacy.id)}
                  >
                    <ListItemText
                      style={{ maxWidth: "92%" }}
                      primary={
                        <Typography className={classes.pharmacyTextName}>
                          {pharmacy.name}
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.pharmacyTextAddress}>
                          {pharmacy.address}
                        </Typography>
                      }
                    />
                    {selected === pharmacy.id && (
                      <svg
                        width="24"
                        style={{ position: "absolute", right: 11 }}
                        height="20"
                        viewBox="0 0 24 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.7625 19.425L0 11.6625L3.5375 8.125L7.7625 12.3625L20.1125 0L23.65 3.5375L7.7625 19.425Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={5}>
            <div className={classes.buttonContainer}>
              <Button
                fullWidth
                className={classes.switchBtn}
                disableFocusRipple={true}
                disableRipple={true}
                onClick={handleCreateClick}
              >
                Switch
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SwitchMenu;
