import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { TableHeader } from "./header";
import { HeaderCell } from "./types";
import { useStyles as styles } from "./styles";
import TableToolBar from "./toolbar";
import { TableFooter } from "@material-ui/core";

type PatientTableProps = {};

interface Patient {
  id: string;
  entity: string;
  type: string;
  name: string;
  phoneNumber: number;
  status: string;
  address: string;
  date_created: string;
}

const headCells: Array<HeaderCell> = [
  { id: "id", leftAlign: false, disablePadding: false, label: "ID" },
  { id: "entity", leftAlign: false, disablePadding: false, label: "Entity" },
  { id: "type", leftAlign: false, disablePadding: false, label: "Type" },
  { id: "name", leftAlign: false, disablePadding: false, label: "Name" },
  { id: "status", leftAlign: false, disablePadding: false, label: "Status" },
  { id: "address", leftAlign: false, disablePadding: false, label: "Address" },
  {
    id: "createdAt",
    leftAlign: false,
    disablePadding: false,
    label: "Created At",
  },
];

const DataLookupTable: React.FC<PatientTableProps> = (props) => {
  const patients = {
    patients: [
      {
        id: "0",
        entity: "entity",
        type: "type",
        name: "name",
        phoneNumber: 12121212123,
        status: "status",
        address: "address",
        createdAt: "01/01/23",
      },
      {
        id: "1",
        entity: "entity2",
        type: "type2",
        name: "name2",
        phoneNumber: 13333333333,
        status: "status2",
        address: "address2",
        createdAt: "02/01/23",
      },
      {
        id: "2",
        entity: "entity3",
        type: "type3",
        name: "name3",
        phoneNumber: 14433333333,
        status: "status3",
        address: "address3",
        createdAt: "03/01/23",
      },
    ],
  };

  const [data, setData] = useState([]);
  const classes = styles();

  const rowsPerPage = 10;
  const [page] = useState(0);
  // const { patients } = props;

  // useEffect(() => {}, []);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   if(newPage > page) {
  //     getMorePatients();
  //   }
  //   setPage(newPage);
  // };

  // const emptyRows =
  //   rowsPerPage -
  //   Math.min(
  //     rowsPerPage,
  //     Object.keys(patients.patients).length - page * rowsPerPage
  //   );

  const formatDate = (date) => {
    const createdDate = new Date(date);

    // Options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit", // Short year (e.g., 23)
      month: "2-digit", // Zero-padded month (e.g., 03)
      day: "2-digit", // Zero-padded day (e.g., 01)
    };

    // Format the date using toLocaleDateString()
    return createdDate.toLocaleDateString(undefined, options);
  };

  return (
    <Paper className={classes.paper}>
      <TableToolBar patients={patients?.patients} setData={setData} />
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="patients table"
        >
          <TableHeader headerCells={headCells} />
          <TableBody>
            {data.map((patient: Patient, idx: number) => {
              return (
                <TableRow
                  className={classes.row}
                  tabIndex={-1}
                  key={`enhanced-table-checkbox-${idx}`}
                >
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {patient?.id || "---"}
                  </TableCell>
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={patient?.entity || "---"}
                  >
                    <TableCell
                      className={classes.cells}
                      align="center"
                      padding="normal"
                    >
                      {patient?.entity || "---"}
                    </TableCell>
                  </Tooltip>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {patient?.type || "---"}
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {patient?.name || "---"}
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {patient.status || "---"}
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {patient?.address || "---"}
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {formatDate(patient?.date_created) || "---"}
                  </TableCell>
                </TableRow>
              );
            })}
            {Object.entries(data).length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={headCells.length}
                  align="center"
                  className={classes.zeroResults}
                >
                  Search for organizations/users
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              className={classes.pages}
              rowsPerPageOptions={[]}
              count={Object.entries(data).length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={({ from, to, count }) =>
                count === 0 ? "0 results" : `${from}-${to} of ${count}`
              }
              onPageChange={() => {}}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

export default DataLookupTable;
