import { useFormik } from "formik";
import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import cross from "../../assets/cross.svg";
import * as Yup from "yup";

import { TextField, Button, Grid, Typography, Box, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";

import useStyles from "./styles";
import { addZone } from "../../services/api/requests";

export default function AddZoneModal({ open, handleClose, handleOpen, orgId, pharmacyId }) {
  const classes = useStyles();

  const accountSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    unit: Yup.string().required("Required"),
    start_distance: Yup.number().required(),
    end_distance: Yup.number().required(),
    rate: Yup.number().required(),
  });
  const initialValues = {
    name: "",
    unit: "",
    rate: "0",
    start_distance: 0,
    end_distance: 0
  }
  const resetTouched = () => {
    const allUntouched = Object.keys(initialValues).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});

    formik.setTouched(allUntouched); // Reset all fields to "untouched"
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      unit: "",
      rate: "0",
      start_distance: 0,
      end_distance: 0
    },
    onSubmit: async (values) => {

      const updatedValues = {
        ...values,
        rate: parseFloat(values?.rate),
      };

      const response = await addZone(orgId, pharmacyId, updatedValues)
      handleClose()
      formik.setValues(initialValues)
      resetTouched()
    },
    enableReinitialize: true,
    validationSchema: accountSchema,
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: {
            backgroundColor: "#FFFFFF99",
          },
        }}
      >
        <Fade in={open}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.paper}>
              <Box className={classes.headerAlignment}>
                <Typography variant="subtitle1" className={classes.subtitleClass}>
                  Add a New Zone
                </Typography>
                <Button className={classes.logo} onClick={() => {
                  handleClose()
                  resetTouched()
                }}>
                  <img src={cross} alt="Logo" />
                </Button>
              </Box>

              <Box style={{ marginRight: 16 }}>
                <Grid className={classes.gridContainer} container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="name"
                      name="name"
                      label="name"
                      size="small"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="start_distance"
                      name="start_distance"
                      label="Start Distance"
                      size="small"
                      variant="outlined"
                      type="number"
                      fullWidth
                      margin="normal"
                      value={formik.values.start_distance}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.start_distance && Boolean(formik.errors.start_distance)
                      }
                      helperText={
                        formik.touched.start_distance && formik.errors.start_distance
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="end_distance"
                      name="end_distance"
                      label="End Distance"
                      size="small"
                      variant="outlined"
                      type="number"
                      fullWidth
                      margin="normal"
                      value={formik.values.end_distance}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.end_distance && Boolean(formik.errors.end_distance)
                      }
                      helperText={
                        formik.touched.end_distance && formik.errors.end_distance
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="normal"
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                    >
                      <InputLabel id="unit-label">Unit</InputLabel>
                      <Select
                        labelId="unit-label"
                        id="unit"
                        name="unit"
                        value={formik.values.unit}
                        onChange={formik.handleChange}
                        label="Unit"
                      >
                        <MenuItem value="km">Kilometer (km)</MenuItem>
                        <MenuItem value="m">Mile (m)</MenuItem>
                      </Select>
                      {formik.touched.unit && formik.errors.unit && (
                        <FormHelperText>{formik.errors.unit}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{ marginTop: 10 }}
                      className={classes.textField}
                      id="rate"
                      name="rate"
                      label="Rate"
                      size="small"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={formik.values.rate}
                      onChange={formik.handleChange}
                      error={formik.touched.rate && Boolean(formik.errors.rate)}
                      helperText={formik.touched.rate && formik.errors.rate}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.btnGrid}>
                  <Button
                    fullWidth={true}
                    style={{ width: "20%" }}
                    onClick={handleOpen}
                    disableFocusRipple={true}
                    disableRipple={true}
                    type="submit"
                  >
                    Add Zone
                  </Button>
                </Grid>
              </Box>
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
}