import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        textField: {
            background: "#FFF",
            borderRadius: 5,
            width: "7.3125rem",
            "& .MuiOutlinedInput-root": {
                borderRadius: 5,
            },
        },

    })
);
