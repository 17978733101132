import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "513px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "650px",
    },
    padding: "15px",
    minHeight: "200px",
  },
  heading: {
    fontWeight: "bold",
  },
  nameInput: {
    width: "250px",
  },
  largeInput: {
    width: "250px",
  },
  selectLabel: {
    fontSize: "13px",
  },
  searchField: {
    margin: "25px 0 0 15px",
  },
  addressSearchField: {
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "300px",
    },
    fontSize: "13px",
  },
  confirmingText: {
    "& .MuiTypography-root": {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
  createButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  confirmCheckbox: {
    padding: "8px",
  },
  createButton: {
    width: "100%",
    maxWidth: "466px",
    color: "#fff",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    // backgroundColor: "#3C3C3C",
    backgroundColor: theme.palette.error.light,
    "&:hover": {
      // backgroundColor: '#3C3C3C',
      backgroundColor: theme.palette.error.light,
    },
  },
}));

export default useStyles;
