import { getOrg, addNewTag, tagDelete } from "./actions";
import { get, post, del } from "../../services/api/api";
import { Tag } from "./types";

export const getOrganization = (org_id: number) => {
  return (dispatch: any) => {
    const url: string = `/api/v1/organizations/${Number(org_id)}`;
    get(url)
      .then((response) => {
        dispatch(
          getOrg({
            org_name: response.data.name,
            unit: response.data.unit,
            address: response.data.address,
            addressKey: response.data.addressKey,
            lat: response.data.lat,
            long: response.data.long,
            dist: response.data.dist,
            org_settings: {},
            phone_number: response.data.phone_number,
            email: response.data.email,
            org_tags: response.data.org_tags,
            date_created: response.data.date_created,
            org_id: response.data.id,
            ext: response.data.ext,
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const addTag = (org_id: number, addedTag: string) => (dispatch: any) => {
  const url: string = `api/v1/organizations/${Number(org_id)}/tags`;

  post(url, {
    tag: addedTag,
  })
    .then(() => {
      dispatch(addNewTag(addedTag));
      dispatch(getOrganization(org_id));
    })
    .catch((err) => {});
};

export const deleteTag =
  (org_id: number, deletedTag: Tag) => (dispatch: any) => {
    const url: string = `api/v1/organizations/${Number(
      org_id
    )}/tags/${deletedTag}`;

    del(url)
      .then(() => {
        dispatch(tagDelete(deletedTag));
      })
      .catch((err) => {});
  };
