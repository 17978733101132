import { PatientInfo } from "../patients/types";

export const ADD_ORDER = "ADD_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const SEARCH_ORDERS = "SEARCH_ORDERS";

export type OrderInfo = {
  status: string;
  orgId: string;
  tyoe: string;
  amount: number;
  notes: string;
  name: string;
  boxes: number;
  employee: string;
  tags: Object;
  orderType: string;
  dateDelivery: string;
  from_address: string;
  to_address: string;
  type: string;
  from_unit: string;
  date_created: string;
  delivery_date: string;
  to_unit: string;
  delivery_preference: string;
  preference_start: string;
  preference_end: string;
};

export type DriverInfo = {
  id: string;
  name: string;
  phoneNumber: number;
};

export type Order = {
  customer: PatientInfo;
  order: OrderInfo;
  driver: DriverInfo;
};

export type Orders = {
  ordersList: {
    [id: string]: OrderInfo;
  };
  moreOrders: boolean;
  totalOrders: number;
};


export type Zones = {

  id: string;
  rate: null
  name: string;
  zone_start: number;
  zone_end: string;
  unit: number;

  date_created: string;
  delivery_date: string;

};

type GetOrders = {
  type: typeof GET_ORDERS;
  payload: Orders;
};

type AddOrder = {
  type: typeof ADD_ORDER;
  payload: Order;
};

type SearchOrder = {
  type: typeof SEARCH_ORDERS;
  payload: Order;
};

export type OrderAction = AddOrder | GetOrders | SearchOrder;
