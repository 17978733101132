import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeading: { marginTop: 12 },
    cardBody: {
      flex: 1,
      boxShadow: "none !important",
    },
    title: {
      fontStyle: "normal",
      fontWeight: ` 600 !important ` as "normal" | "bold" | number,
      marginBottom: "18px",
    },
  })
);
