import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import { FormikProps } from "formik";
import { useStyles } from "./styles";
import { FormikCreateOrder } from "../types";
import StyledPhoneInput from "../../components/styledPhoneInput";

type CreateNewDeliveryProps = {
  formik: FormikProps<FormikCreateOrder>;
};

const CreateNewBulk: React.FC<CreateNewDeliveryProps> = (props) => {
  const { formik } = props;

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.header}>
          <Typography variant="body1" component="div" className={classes.title}>
            Address
          </Typography>
        </Box>
        <Box className={classes.body}>
          <Stack
            className={classes.stack}
            display="flex"
            flexDirection="column"
            spacing={2}
          >
            <Box>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    className={classes.textField}
                    name="customer.extension"
                    type="number"
                    size="small"
                    fullWidth
                    label="Unit"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.customer.extension}
                  />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    required={true}
                    className={classes.textField}
                    name={`customer.fullAddress[].address`}
                    type="text"
                    size="small"
                    label="Address"
                    variant="outlined"
                    onChange={formik.handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                component="div"
                className={classes.title}
                style={{ marginBottom: 10 }}
              >
                Contact Details
              </Typography>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={4}>
                  <StyledPhoneInput
                    formik={formik}
                    fieldName="customer.phoneNumber"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className={classes.textField}
                    name="customer.email"
                    size="small"
                    onChange={formik.handleChange}
                    value={formik.values.customer.email}
                    variant="outlined"
                    required={true}
                    label="Email"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              className={classes.createOrder}
              type="submit"
            >
              Update
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default CreateNewBulk;
