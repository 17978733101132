import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      width: "100%",
      maxWidth: "100%",
      borderRadius: "5px",
    },
    table: {
      flexFlow: "column nowrap",
      overflow: "hidden",
      margin: "0 0.75rem",
    },
    pages: {
      display: "contents",
    },
    row: {
      flexFlow: "row nowrap",
      borderBottom: "1px solid #CFCFCF",
      width: "100%",
      "&:hover": {
        background: "#F5F5F5",
        cursor: "pointer",
      },
    },
    pillCell: {
      borderBottom: "none",
      width: "100px",
      height: "25px",
      fontSize: "12px",
    },
    cells: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      borderBottom: "none",
      textTransform: "capitalize",
      padding: "0.375rem 1.5rem 0.375rem 1.375rem",
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
    },
    emptyRowsTitle: {
      color: "#CFCFCF",
    },
    container: {
      width: 30,
      height: 30,
      backgroundColor: "#3C3C3C",
      borderRadius: 5, // To create a circular shape
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      color: "white",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 700,
    },
  })
);
