import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    grid: {
      padding: "0 8px !important",
    },
    gridContainer: {
      marginTop: "9px",
      marginBottom: "9px",
      display: "flex",
    },
    textField: {
      lineHeight: "22px !important",
      borderRadius: "5px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px",
      },
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      margin: "0",
    },
    stack: {
      marginBottom: theme.spacing(3),
    },
    paper: {
      width: "100%",
      maxWidth: "550px",
      borderRadius: "10px !important",
    },
    title: {
      marginTop: "0px !important",
    },
    subTitle: {
      marginLeft: 5,
      marginTop: "0px !important",
      fontWeight: 700,
      lineHeight: "22px !important",
    },
    underline: {
      marginTop: 10,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "1px",
      background: "#CFCFCF",
    },
    header: {
      padding: "1.063rem 1.125rem",
      borderBottom: "1px solid #CFCFCF",
    },
    body: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(2),
    },
    line: {
      flex: "1 1 auto",
      height: 1,
      background: "#CFCFCF",
    },
    createDriver: {
      whiteSpace: "nowrap",
      width: "420px",
      "text-transform": "capitalize !important",
    },
    addressSearchField: {
      fontSize: "13px",
      marginTop: theme.spacing(2),
    },
    addressErrorField: {
      padding: "-0.5px",
      margin: 0,
      fontSize: "0.75rem",
      textAlign: "left",
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
      marginTop: theme.spacing(2),
      outline: "solid 1px red",
      borderRadius: "4px",
    },
    errMessage: {
      marginTop: "4px",
      marginLeft: "14px",
      marginRight: "14px",
      color: "#FE5A5A",
      fontSize: "0.75rem",
    },
    label: {
      color: theme.palette.common.black,
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      lineHeight: "22px",
    },
    logo: {
      height: "70px",
      width: "70px",
      margin: "25px auto",
      alignSelf: "flex-start",
    },
    errorCtnr: {
      marginTop: "15px",
      width: "80%",
      backgroundColor: theme.palette.error.light,
      color: "#ffffff",
      fontSize: "12px",
      padding: "15px",
      height: "20px",
      textAlign: "center",
      borderRadius: "10px",
    },

    startText: {
      fontWeight: theme.typography.fontWeightRegular as
        | "normal"
        | "bold"
        | number,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1.25),
    },
  })
);

export default useStyles;
