import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useStyles } from "./style";

type StatCardProps = {
  label: string;
  stat: number | string;
};

const StatCard: React.FC<StatCardProps> = (props) => {
  const { label, stat } = props;
  const classes = useStyles();

  return (
    <Card sx={{ borderRadius: "5px", width: 230 }}>
      <CardContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          className={classes.title}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />

        <Typography
          variant="body1"
          component="div"
          sx={{ textAlign: "center" }}
          fontWeight={700}
        >
          {stat === 0 ? "---" : stat}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatCard;
