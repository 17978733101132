import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "./header";
import { HeaderCell } from "./types";
import { useStyles as styles } from "./styles";
import { TableFooter } from "@material-ui/core";
import { Typography } from "@mui/material";

type PharmaciesTableProps = {
  pharmacies: any;
};

const headCells: Array<HeaderCell> = [
  { id: "name", leftAlign: true, disablePadding: false, label: "Name" },
  { id: "address", leftAlign: true, disablePadding: false, label: "Address" },
  {
    id: "created",
    leftAlign: true,
    disablePadding: false,
    label: "Created",
  },
];

const PharmaciesTable: React.FC<PharmaciesTableProps> = (props) => {
  const classes = styles();
  const nav = useNavigate();

  const rowsPerPage = 10;
  const [page] = useState(0);
  const { pharmacies } = props;

  const formatDate = (inputDate) => {
    const createdDate = new Date(inputDate);

    // Options for formatting the date and time
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit", // Short year (e.g., 23)
      month: "2-digit", // Zero-padded month (e.g., 03)
      day: "2-digit", // Zero-padded day (e.g., 01)
    };

    // Format the date and time together with a line break
    const formattedDate = createdDate.toLocaleDateString(undefined, options);

    // Options for formatting the time
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric", // Hour in 12-hour format (e.g., 5)
      minute: "numeric", // Minutes (e.g., 40)
      hour12: true, // Use 12-hour format (AM/PM)
    };

    // Format the time using toLocaleTimeString()
    const formattedTime = createdDate.toLocaleTimeString(
      undefined,
      timeOptions
    );
    // Return the formatted date and time
    return `${formattedDate}<br/>${formattedTime}`;
  };
  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="pharmacies table"
        >
          <TableHeader headerCells={headCells} />
          <TableBody>
            {Object.entries(pharmacies.pharmacies).map((value, idx) => {
              let pharmacy: any = value[1];
              return (
                <TableRow
                  className={classes.row}
                  tabIndex={-1}
                  key={`enhanced-table-checkbox-${idx}`}
                  onClick={() => {
                    nav(`/pharmacy/${pharmacy.ID}`);
                  }}
                >
                  <TableCell className={classes.cells} padding="normal">
                    <Typography variant="body1">{pharmacy.Name}</Typography>
                  </TableCell>
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={pharmacy.Address}
                  >
                    <TableCell className={classes.cells} padding="normal">
                      <Typography variant="body1">
                        {pharmacy.Address}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <TableCell className={classes.cells} padding="normal">
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: formatDate(pharmacy.DateCreated),
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.pages}
                rowsPerPageOptions={[]}
                count={Object.entries(pharmacies.pharmacies).length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelDisplayedRows={({ from, to, count }) =>
                  count === 0 ? null : `${count} pharmacies`
                }
                onPageChange={() => {}}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PharmaciesTable;
