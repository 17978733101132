import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "2rem",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: "0.9375rem",
    borderRadius: "0.625rem",
    paddingBottom: "2rem",
    background: "#FFF",
    boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  backIcon: {},
  icon: {
    cursor: "pointer",
    color: theme.palette.common.black,
    height: "1rem",
    width: "1rem",
    zIndex: 1,
  },
  heading: {
    marginTop: "0px !important",
  },
  backAction: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "&:hover": {
      cursor: "pointer",
    },
  },
  label: {
    marginRight: "5px",
  },
  actionCard: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    minHeight: "100px",
    width: "100px",
    marginLeft: "15px",
  },

  paper: {
    width: "100%",
    borderRadius: "10px !important",
    marginBottom: 30,
    paddingLeft: theme.spacing(1.5),
    height: "4.188rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(1.5),
  },
  gridContainer: {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(1.5),
  },
  title: {
    "font-weight": `${theme.typography.fontWeightBold} !important`,
    marginTop: "0px !important",
    color: theme.palette.common.black,
    lineHeight: "22px !important",
    fontSize: "1.25rem",
  },
  textField: {
    lineHeight: "22px !important",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
    },
    "font-weight": `${theme.typography.fontWeightBold} !important`,
    marginBottom: 0,
    marginTop: 0,
  },
  subtitleClass: {
    color: "#3C3C3C",
    fontWeight: 700,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  subHeading: {
    margin: "0.5rem 0",
  },
  divider: { width: "90%", height: "1px", marginLeft: "1rem !important" },
  tags: {
    color: "#000",
    fontSize: "0.75rem",
    fontWeight: 700,
    padding: ".25rem .5625rem",
    marginRight: ".6875rem",
    borderRadius: 5,
  },
  tagSection: {
    display: "flex",
    gap: 12,
    overflow: "auto",
  },
  paperRight: {
    width: "100%",
    borderRadius: "10px !important",
    marginBottom: 30,
    height: "28rem",
    position: "relative",
  },
  headerBox: {
    padding: "0.625rem 0.875rem",
    borderBottom: "1px solid #CFCFCF",
  },
  row: {
    padding: ".5625rem .875rem",
    borderBottom: "1px solid #CFCFCF",
  },
  detail: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "0.875rem",
  },
  detailHeading: {
    color: "#000",
    fontSize: "0.937rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.375rem" /* 146.667% */,
  },
  timelineBox: {
    overflow: "auto",
    height: "23rem",
  },
  bottomBox: {
    borderTop: "1px solid #CFCFCF",
    position: "absolute",
    bottom: "0",
    height: "2.25rem",
    width: "22rem",
  },
  textStyle: {
    marginTop: 3,
  },
  IconBox: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    marginTop: 9,
  },
  tagsBox: {
    overflowY: "auto",
    padding: "0 !important",
    marginLeft: 16,
    marginTop: 16,
  },
  chip: {
    backgroundColor: "#E0E0E0",
    color: "#222222",
    fontSize: "0.75rem",
    fontWeight: 700,
    width: "6rem",
    height: "1.8rem",
    borderRadius: 5,
  },
  statusChip: {
    backgroundColor: "#D0E7B7",
    textTransform: "capitalize",
    color: "#000000",
    fontSize: "0.75rem",
    fontWeight: 700,
    width: "6rem",
    height: "1.8rem",
    borderRadius: 5,
  },
}));
