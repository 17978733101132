import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useStyles as styles } from "./styles";
import { getUserAccess } from "../store/authentication/access/middleware";
import { getOrganization } from "../store/organizations/middleware";
import Loader from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import weelTheme from "../components/material-ui/theme";
import { RootState } from "../store/types";
import { getOrganizationStats } from "../services/api/requests";
import { Typography } from "@mui/material";
import BulkTable from "./bulkTable";

const BulkContainer: React.FC<{}> = () => {
  const nav = useNavigate();
  const classes = styles();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.account);
  const userAccess = useSelector((state: RootState) => state.user.access);
  const orders = useSelector((state: RootState) => state.orders);

  const [stats, setStats] = useState<any>({
    deliveriesToday: 0,
    deliveriesAllTime: 0,
    patientsServiced: 0,
    deliveryChart: {
      categories: [],
      data: [],
    },
    patientChart: {
      categories: [],
      data: [],
    },
  });

  useEffect(() => {
    if (!userAccess || userAccess.length === 0) {
      dispatch(getUserAccess(user.user_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      userAccess &&
      userAccess[0]?.org_id &&
      userAccess.length > 0 &&
      userAccess[0]?.org_id
    ) {
      dispatch(getOrganization(userAccess[0]?.org_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess]);

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition((pos) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrganizationStats(userAccess[0]?.org_id)
      .then((resp) => {
        setStats({
          ...stats,
          deliveriesToday: resp.data.deliveriesToday,
          deliveriesAllTime: resp.data.deliveriesAllTime,
          patientsServiced: resp.data.patientsServiced,
        });
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.actions}>
        <Typography variant="h6" fontWeight={700}>
          Bulk Locations
        </Typography>
        <Button
          fullWidth
          disableFocusRipple={true}
          disableRipple={true}
          onClick={() => {
            nav("/bulk-actions/create");
          }}
          className={classes.btnStyle}
        >
          Create
        </Button>
      </Box>
      {orders != null ? (
        <div className={classes.ordersWrapper}>
          <br />
          <BulkTable
            getMoreOrders={() => {}}
            orders={{
              ordersList: {},
              moreOrders: false,
              totalOrders: 0,
            }}
          />
        </div>
      ) : (
        <div className={classes.spinner}>
          <Loader
            type="Bars"
            color={weelTheme.palette.secondary.main}
            height={100}
            width={100}
          />
        </div>
      )}
    </div>
  );
};

export default BulkContainer;
