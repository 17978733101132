import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import auth from "./authentication";
import orders from "./orders/reducers";
import organizations from "./organizations/reducers";
import patients from "./patients/reducers";
import drivers from "./driver/reducers";

import pharmacies from "./pharmacy/reducers";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConf = {
  key: "currentUser",
  storage,
};

const persistOrgConf = {
  key: "org",
  storage,
};

const persistedReducer = persistReducer(persistConf, auth);

const persistedOrg = persistReducer(persistOrgConf, organizations);
const persistedDrivers = persistReducer(persistOrgConf, drivers);

const rootReducer = combineReducers({
  user: persistedReducer,
  organization: persistedOrg,
  orders,
  patients,
  pharmacies,
  drivers: persistedDrivers,
});
const clearStorageReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:currentUser");
    storage.removeItem("persist:org");
    // Clear other persisted data if needed
    state = undefined; // Reset state to initial state
  }
  return rootReducer(state, action);
};

export default () => {
  const store = configureStore({
    reducer: clearStorageReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });

  const peristor = persistStore(store);

  return { store, peristor };
};
