import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { HeaderCell } from "../types";
import { useStyles as styles } from "./styles";
import { Typography } from "@mui/material";

type TableHeaderProps = {
  headerCells: Array<HeaderCell>;
};

export const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const classes = styles();
  const { headerCells } = props;

  return (
    <TableHead>
      <TableRow className={classes.headerRoot}>
        {headerCells.map((headCell) => (
          <TableCell
            className={classes.cellRoot}
            key={headCell.id}
            align={headCell.leftAlign ? "left" : "center"}
          >
            <Typography variant="body1" fontWeight={600}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
