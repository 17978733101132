import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const StyledPhoneInput = ({ formik, fieldName, style = {} }) => {
  const handlePhoneChange = (value) => {
    const numberValue = Number(value);
    formik.setFieldValue(fieldName, numberValue);
  };

  const fieldNames = fieldName.split(".");

  return (
    <PhoneInput
      specialLabel=""
      country={"ca"}
      regions={["north-america"]}
      onChange={handlePhoneChange}
      value={
        fieldNames.length > 1
          ? String(formik.values?.[fieldNames[0]][fieldNames[1]])
          : String(formik.values[fieldName])
      }
      placeholder="Phone number"
      inputStyle={{
        borderWidth: 0.3,
        borderColor: "rgb(192, 192, 192)",
        borderRadius: "5px",
        paddingTop: "10.5px",
        paddingBottom: "10.5px",
        width: 250,
        height: 38,
        fontSize: 15,
        lineHeight: "1.1876em",
        letterSpacing: "0.00938em",
        boxShadow: "none",
        ...style,
      }}
      buttonStyle={{ borderRadius: "5px 0 0 5px" }}
    />
  );
};

export default StyledPhoneInput;
