import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Box,
  Button,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Stack, TextareaAutosize, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useStyles } from "./styles";
import { FormikPatient } from "../types";
import StyledPhoneInput from "../../components/styledPhoneInput";
import { RootState } from "../../store/types";
import { useSelector } from "react-redux";
import { AddressSearch } from "../../components/addressSearch";

type updatePatientsProps = {
  formik: FormikProps<FormikPatient>;
};

const UpdatePatientForm: React.FC<updatePatientsProps> = (props) => {
  const { formik } = props;

  const org = useSelector((state: RootState) => state.organization);

  const classes = useStyles();

  const handleAdd = () => {
    if (formik.values.locations.length < 3) {
      formik.setFieldValue("locations", [
        ...formik.values.locations,
        {
          unit: "",
          id: org.org_id,
          preferred: false,
          address: "",
          lat: 0,
          long: 0,
          distance: 0,
        },
      ]);
      return;
    }
  };

  const handleDelete = (idx) => {
    const fullAddressState = formik.values.locations.filter((_, i) => {
      return i !== idx;
    });
    if (formik.values.locations[idx].preferred === true) {
      fullAddressState[0].preferred = true;
    }

    formik.setFieldValue("locations", fullAddressState);
  };

  const handleChangeRadio = (idx) => {
    const fullAddressState = formik.values.locations.map((address) => {
      return {
        ...address,
        preferred: false,
      };
    });
    fullAddressState[idx].preferred = true;

    formik.setValues({
      ...formik.values,
      locations: fullAddressState,
    });
  };

  const handleAddressSelect = (address: any, idx: number) => {
    formik.setFieldValue(`locations[${idx}].address`, address.address);
    formik.setFieldValue(`locations[${idx}].lat`, address.lat);
    formik.setFieldValue(`locations[${idx}].long`, address.long);
  };

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.body}>
          <Stack
            className={classes.stack}
            display="flex"
            flexDirection="column"
            spacing={2}
          >
            <Box>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    label="First Name"
                    required={true}
                    name="firstName"
                    fullWidth={true}
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    label="Last Name"
                    name="lastName"
                    fullWidth={true}
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    className={classes.textField}
                    name="email"
                    size="small"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    variant="outlined"
                    required={true}
                    label="Email"
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={5}>
                  <StyledPhoneInput formik={formik} fieldName="phoneNumber" />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className={classes.textField}
                    name="ext"
                    type="number"
                    size="small"
                    fullWidth
                    label="Primary Ext"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.ext}
                  />
                </Grid>
              </Grid>
              {formik.values.locations.map((_, idx) => (
                <div
                  key={idx}
                  style={{ marginBottom: 23 }}
                  className={classes.addressContainer}
                >
                  <div className={classes.addressContainer}>
                    <Checkbox
                      checked={
                        formik.values.locations[idx].preferred === true
                          ? true
                          : false
                      }
                      onChange={() => {
                        handleChangeRadio(idx);
                      }}
                      value={formik.values.locations[idx].preferred}
                      name={`locations[${idx}].preferred`}
                      style={{
                        marginRight: 8,
                        borderRadius: 5,
                        width: 29,
                        height: 29,
                        color: "#01C3A1",
                      }}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />

                    <div style={{ width: 125, flexShrink: 0, paddingRight: 8 }}>
                      <TextField
                        className={classes.textField}
                        name={`locations[${idx}].unit`}
                        type="text"
                        size="small"
                        error={false}
                        label="Unit"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.locations[idx].unit}
                      />
                    </div>

                    <div style={{ flexGrow: 1, paddingLeft: 8 }}>
                      <Box
                        className={
                          formik.touched.locations && !!formik.errors.locations
                            ? classes.addressErrorField
                            : classes.addressSearchField
                        }
                      >
                        <AddressSearch
                          onAddressSelect={(value) =>
                            handleAddressSelect(value, idx)
                          }
                          currentAddress={
                            formik.values?.locations[idx]?.address
                          }
                          lat={formik.values?.locations[idx]?.lat}
                          long={formik.values?.locations[idx]?.long}
                        />
                      </Box>
                      {formik.touched.locations &&
                        !!formik.errors.locations && (
                          <div className={classes.errMessage}>
                            {formik.touched.locations &&
                              formik.errors.locations}
                          </div>
                        )}
                    </div>
                    <Typography variant="body1" className={classes.subText}>
                      Distance - {formik.values?.locations[idx]?.distance} km
                    </Typography>
                  </div>

                  {formik.values.locations.length > 1 && (
                    <IconButton
                      className={classes.deleteIcon}
                      onClick={() => {
                        handleDelete(idx);
                      }}
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}
              {formik.values.locations.length < 3 && (
                <Box className={classes.iconContainer}>
                  <IconButton
                    className={classes.addIcon}
                    onClick={handleAdd}
                    color="secondary"
                    aria-label="add"
                    size="small"
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              )}

              <Grid
                className={classes.gridContainer}
                container
                style={{ marginTop: 8 }}
              >
                <Grid item xs={12}>
                  <TextareaAutosize
                    className={classes.textArea}
                    name="notes"
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                    placeholder="Notes"
                    minRows={3}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="end" alignItems={"center"}>
            <Button
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              className={classes.createOrder}
              type="submit"
            >
              Update
            </Button>

            <Button
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              className={classes.deleteBtn}
              style={{
                backgroundColor: "#FE5A5A",
                borderColor: "#FE5A5A",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default UpdatePatientForm;
