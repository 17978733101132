import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: (props: any) => ({
      display: props.showNavbar ? "flex" : "none",
      flexFlow: "column",
      width: "200px",
      top: 65,
      height: "91%",
      zIndex: 10,
      background: "transparent",
      borderRight: "none",
    }),

    accordionPaper: (props: any) => ({
      display: props.showNavbar ? "flex" : "none",
      flexFlow: "column",
      width: "200px",
      zIndex: 10,
      height: "92vh",
      marginTop: "3.875rem",
      overflowX: "hidden",
      borderRight: "none",
      background: "none",
    }),
    routeContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      marginTop: "10px",
      color: "#000000",
      fontStyle: "normal",
      fontWeight: 700,
      overflow: "hidden",
    },
    navFoot: {
      display: "flex",
      flexFlow: "column nowrap",
      paddingTop: "10px",
      paddingBottom: "1.0625rem",
      color: "#000000",
      fontStyle: "normal",
      fontWeight: 700,
      position: "absolute",
      bottom: 0,
      width: "87%",
    },
    logo: {
      height: "44px",
      width: "44px",
      margin: "10.5px auto",
    },
    label: {
      fontWeight: 600,
    },
    icon: {
      marginRight: ".5rem",
    },
    iconCntnr: {
      minWidth: "45px",
    },
    item: {
      marginLeft: "12px",
      marginRight: "5px",
      paddingTop: "10px",
      paddingBottom: "10px",
      maxWidth: "100%",
      borderRadius: "10px",
      textAlign: "center",

      "&:hover": {
        background: "#D9D9D980",
      },
    },
    itemSelected: {
      background: "#D9D9D980",
    },
    root: {
      width: "100%",
    },
    heading: {
      fontWeight: 600,
    },
    accordionList: {
      flexDirection: "column",
      padding: "0",
      borderRadius: "10px",
    },
    summary: {
      minHeight: "18px !important",
      margin: "0",
    },
    accordion: {
      border: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
    },
    appBar: {
      margin: " 0.6rem",
      textAlign: "center",
      height: "100vh",
    },
    divider: { background: "#ffffff" },
  })
);
