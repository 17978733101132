import React, { useEffect, useState } from "react";
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useStyles } from "./style";
import weelTheme from "../../../components/material-ui/theme";
import { RootState } from "../../../store/types";
import { useSelector } from "react-redux";
import {
  assignDriverApi,
  getOrganizationDrivers,
} from "../../../services/api/requests";

interface Driver {
  id: string;
  name: string;
  phone_number: number;
}

const statusList = [
  "cancelled",
  "customer_declined",
  "customer_absent",
  "routed",
  "in_progress",
  "delivered",
  "returned",
  "deleted",
  "with_driver",
];
const DriverAssignment = ({ orderId, driverId, status }) => {
  const classes = useStyles(weelTheme);
  const [blockChangeDriver, setBlockChangeDriver] = useState(false);

  useEffect(() => {
    driverId && setBlockChangeDriver(statusList.includes(status));
  }, [driverId, status]);

  const [selectedDriver, setSelectedDriver] = useState({
    id: "",
    name: "",
  });
  const organization = useSelector((state: RootState) => state.organization);
  const [drivers, setdrivers] = useState<Driver[]>([]);
  const fetchDriver = async () => {
    try {
      const response = await getOrganizationDrivers(organization.org_id);
      const specificDriver = response.data.drivers.find(
        (driver) => driver.id === driverId
      );
      if (specificDriver) {
        setSelectedDriver({
          id: specificDriver.id,
          name: specificDriver.name,
        });
      }
      setdrivers(response.data.drivers);
    } catch (error) {}
  };

  const assignDriver = async (driver) => {
    try {
      const payload = {
        driver_id: Number(driver.id),
        order_id: Number(orderId),
      };
      await assignDriverApi(organization.org_id, payload);
      setSelectedDriver(driver);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("selectedDriver :>> ", selectedDriver);
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormControl fullWidth variant="outlined">
        <InputLabel shrink style={{ padding: 3, background: "#FFFF" }}>
          Drivers
        </InputLabel>
        <Select
          required={true}
          style={{ height: "2.5rem", borderRadius: 5 }}
          classes={{ select: classes.select }}
          value={selectedDriver?.name}
          name="selectedDriver"
          defaultValue={"select driver"}
          disabled={blockChangeDriver}
        >
          <MenuItem value="select driver">Select Driver</MenuItem>
          {drivers?.length > 0 &&
            drivers.map((driver) => (
              <MenuItem
                key={driver.id}
                value={driver.name}
                onClick={() => assignDriver(driver)}
              >
                {driver.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Paper>
  );
};

export default DriverAssignment;
