import { login, logout } from "./actions";
import { post } from "../../../services/api/api";

export const handleLogin = (response) => async (dispatch: any) => {
  try {
    dispatch(
      login({
        user_id: response.data.user_id,
        unique_id: response.data.unique_id,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        email: response.data.email,
        username: response.data.username,
        user_role: response.data.user_role,
        verified: response.data.verified,
        active: true,
        loggedIn: true,
      })
    );
    return response.data;
  } catch (error) {
    // Handle errors, maybe dispatch an error action or set an error state
    throw error; // Rethrow the error to be caught in the calling function
  }
};

export const handleLogout = () => (dispatch: any) => {
  post("/api/v1/auth/logout", {})
    .then(() =>
      dispatch(
        logout({
          user_id: "",
          unique_id: "",
          firstName: "",
          lastName: "",
          email: "",
          username: "",
          user_role: "",
          verified: false,
          active: false,
          loggedIn: false,
        })
      )
    )
    .then(() => {
      localStorage.clear();
    })
    .catch((err) => {
      console.error(err);
    });
};
