import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const StyledPhoneSearch = ({ value, handleChange }) => {
  return (
    <PhoneInput
      specialLabel=""
      country={"ca"}
      regions={["north-america"]}
      onChange={handleChange}
      value={String(value)}
      placeholder="Search by phone number"
      inputStyle={{
        width: "100%",
        borderWidth: 0.3,
        borderColor: "rgb(192, 192, 192)",
        borderRadius: "10px",
        paddingTop: "10.5px",
        paddingBottom: "10.5px",
        height: 37,
        fontSize: 14,
        color: "#3C3C3C",
        lineHeight: "1.1876em",
        letterSpacing: "0.00938em",
        boxShadow: "none",
      }}
      buttonStyle={{ borderRadius: "10px 0 0 10px" }}
    />
  );
};

export default StyledPhoneSearch;
