import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import {  Typography, Box } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { useStyles } from "./styles";

import { OrganizationSearch } from "./orgSearch";

const TableToolbar = (props) => {

  const classes = useStyles();

  const {  setData } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
       Data Lookup
      </Typography>
       <Box
            display="flex"
            flexDirection="row"
            className={classes.searchContainer}
          >
            <Box flexGrow={1}>
              <OrganizationSearch
                currentValue={''}
                patients={props?.patients}
                setData={setData}
              />
            </Box>
            <SearchIcon className={classes.searchIcon} />
          </Box>
    </Toolbar>
  );
};

export default TableToolbar;
