import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      boxShadow: "none",
    },
    title: {
      marginTop: "0px !important",
      fontWeight: 700,
      color: theme.palette.common.black,
      lineHeight: "22px !important",
    },
    textFeild: {
      borderRadius: "10px",
      border: 0,
      background: "#FFF",
      height: "2.25rem",
      padding: 0,
      marginRight: "5px",
      width: "100%",
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px",
        padding: "0.125rem 0.625rem",
      },
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      borderBottom: "1px solid #CFCFCF",
    },
    body: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(2),
    },
    content: {
      maxHeight: "200px",
      overflowY: "auto",
      marginBottom: "1rem",
    },
    subTitle: {
      fontWeight: 700,
      color: theme.palette.common.black,
      lineHeight: "22px !important",
    },
    description: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightRegular as
        | "normal"
        | "bold"
        | number,
      lineHeight: "22px !important",
    },
    card: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      border: "1px solid #CFCFCF",
      borderRadius: "10px",
      background: theme.palette.common.white,
      cursor: "pointer",
      "&:hover": {
        background: "#f4f4f4",
      },
    },
    searchIcon: {
      marginLeft: `${theme.spacing(2)}px !important`,
      background: `${theme.palette.primary.main} !important`,
      color: theme.palette.common.white,
      height: "32px !important",
      width: "32px !important",
      borderRadius: "10px !important",
      padding: theme.spacing(0.5),
      cursor: "pointer",
    },
    searchContainer: {
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      alignItems: "end",
    },
    btnClose: {
      padding: 0,
      width: "1.5rem",
      minWidth: "1.5rem",
      height: "2.5rem",
    },
    select: {
      "&:focus": {
        backgroundColor: "inherit",
      },
      "&.Mui-disabled": {
        color: theme.palette.action.active,
      },
    },
  })
);
