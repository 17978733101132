import { combineReducers } from 'redux';
import { currentUser } from './user/reducers';
import { currentUserAccess } from './access/reducers';
import { authErrors } from './errors/reducers'; 

const auth = combineReducers({
  account: currentUser,
  access: currentUserAccess, 
  errors: authErrors
})

export default auth