export const GET_ORG = "GET_ORG";
export const UPDATE_ORG = "UPDATE_ORG";
export const ADD_TAG = "ADD_TAG";
export const DELETE_TAG = "DELETE_TAG";

export type Tag = { id: number; name: string };

export type Organization = {
  org_name: string;
  unit: string;
  address: string;
  addressKey: string;
  lat: number;
  long: number;
  dist: number;
  org_settings: any;
  phone_number: string;
  email: string;
  org_tags: Array<Tag>;
  date_created: string;
  org_id: string;
  ext: number;
};

export type Organizations = {
  [id: string]: Organization;
};

// ACTIONS

export type GetOrg = {
  type: typeof GET_ORG;
  payload: Organization;
};

export type UpdateOrg = {
  type: typeof UPDATE_ORG;
  payload: Organization;
};

export type AddNewTag = {
  type: typeof ADD_TAG;
  tag;
};

export type DeleteTag = {
  type: typeof DELETE_TAG;
  tag;
};

export type OrgActions = UpdateOrg | GetOrg | AddNewTag | DeleteTag;
