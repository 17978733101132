// libraries
import React from "react";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-hot-toast";

// constants

// styles
import useStyles from "../assignServiceProviderForm/styles";
import { post } from "../../../services/api/api";

const AssignUserForm: React.FC<{}> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      serviceProviderId: "",
      pharmacyId: "",
    },
    onSubmit: async (values) => {
      try {
        const payload = {
          org_id: values.pharmacyId,
          user_id: values.serviceProviderId,
        };
        if (!values.serviceProviderId || !values.pharmacyId) {
          toast.error(`Woops! user or organization id missing`);
          return;
        }
        const url = `/api/v1/users/assign`;
        const response = await post(url, payload);
        if (!response) {
          throw new Error();
        }
        toast.success(`Successfully assigned pharmacy to service provider`);
        formik.resetForm();
      } catch (error) {
        toast.error(
          `Woops! something went wrong when trying to assign a pharmacy to a service provider`
        );
        console.error(error);
      }
    },
  });

  return (
    <Paper className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.heading} variant="h6">
              Assign user
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              name="serviceProviderId"
              size="small"
              className={classes.nameInput}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.serviceProviderId}
              label="User Id"
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              name="pharmacyId"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.pharmacyId}
              variant="outlined"
              label="org id"
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              className={classes.createButton}
              disableFocusRipple={true}
              disableRipple={true}
              type="submit"
            >
              Link
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AssignUserForm;
