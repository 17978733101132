import {
  GET_DRIVERS,
  UPDATE_DRIVER,
  ADD_DRIVER,
  DriverAction,
  Drivers,
  DriverInfo,
  Driver,
  ADD_SELECTED_DRIVERS,
} from "./types";

export const getDrivers = (drivers: Drivers): DriverAction => {
  return {
    type: GET_DRIVERS,
    payload: drivers,
  };
};

export const addDriver = (driver: DriverInfo): DriverAction => {
  return {
    type: ADD_DRIVER,
    payload: driver,
  };
};

export const addSelectedDriver = (driver: Driver[]) => {
  return {
    type: ADD_SELECTED_DRIVERS,
    payload: driver,
  };
};
export const updateDriverInfo = (driverId, updatedDriverInfo) => ({
  type: UPDATE_DRIVER,
  driverId,
  updatedDriverInfo,
});
