import React from "react";
import { useStyles } from "./style";
import { IconButton } from "@material-ui/core";

const CloseButton = ({ handleClick }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.closeIcon}
      onClick={handleClick}
      aria-label="delete"
      size="small"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.3333 2.35L20.9833 0L11.6667 9.31667L2.35 0L0 2.35L9.31667 11.6667L0 20.9833L2.35 23.3333L11.6667 14.0167L20.9833 23.3333L23.3333 20.9833L14.0167 11.6667L23.3333 2.35Z"
          fill="black"
        />
      </svg>
    </IconButton>
  );
};
export default CloseButton;
