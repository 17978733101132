import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: "0.625rem",
    background: "#fff",
    boxShadow: "0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.25)",
    border: "none !important",
    marginTop: "1.5rem",
    height: "45vh",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 1.5rem .75rem 1.375rem",
    borderBottom: "1px solid #CFCFCF",
    margin: "0 0.75rem",
  },
  heading: {
    fontSize: "1.25rem",
    color: "#000000",
    lineHeight: "1.375rem",
    fontWeight: 700,
  },
  listItem: {
    borderBottom: "1px solid #CFCFCF",
    cursor: "pointer",
    padding: "0.375rem 1.5rem 0.375rem 1.375rem",
    "&:hover": {
      cursor: "pointer",
      background: "#F5F5F5",
    },
  },
  listText: {
    color: "#000",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: theme.typography.fontWeightRegular as
      | "normal"
      | "bold"
      | number,
    lineHeight: "22px",
  },
  textField: {
    width: "16rem",
    flexDhrink: 0,
    borderRadius: "10px",
    background: "#FFF",
    color: "green",
  },
  zeroPadding: {
    padding: 0,
    margin: "0 .75rem",
  },
  dateRangeContainer: {
    flex: "0 0 auto",
  },
}));

export default useStyles;
