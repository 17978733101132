export const ADD_USER_ACCESS = "SAVE_USER_ACCESS"

export const CHANGE_USER_ACCESS = "CHANGE_USER_ACCESS";

export type UserAccess = {
    user_id: number,
    org_id: number,
    user_role: string,
    org_name: string, 
    org_type: string
}

export type UserAccessList = Array<UserAccess>

// ACTIONS 

export type AddUserAccess = {
    type: typeof ADD_USER_ACCESS, 
    access: Array<UserAccess> 
}

export type ChangeUserAccess = {
    type: typeof CHANGE_USER_ACCESS, 
    access: Array<UserAccess> 
}

 export type AccessActionTypes = AddUserAccess | ChangeUserAccess;