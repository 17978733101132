import {
  LOGIN_ERROR,
  CLEAR_ERROR,
  AuthError
} from './types'

const initialState: AuthError = {
  isLoginErr: false,
  errorMsg: ''
}

export const authErrors = (state: AuthError = initialState, action): AuthError => {
  const type: any = action?.type; 

  switch(type) {
    case LOGIN_ERROR:
      return action?.payload;
    case CLEAR_ERROR: 
      return {
        isLoginErr: false, 
        errorMsg: ''
      }
    default: 
      return state;
  }
}