import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-hot-toast";

import { AddressSearch } from "../../../components/addressSearch";
import { RootState } from "../../../store/types";
import { useStyles } from "./styles";
import StyledPhoneSearch from "../../../components/styledPhoneSearch";
import { updateOrganisation } from "../../../services/api/requests";
import { getOrganization } from "../../../store/organizations/middleware";

type OrgInfoProps = {
  lat: number;
  long: number;
};

const OrgInfo: React.FC<OrgInfoProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const org = useSelector((state: RootState) => state.organization);
  const { lat, long } = props;
  const formik = useFormik({
    initialValues: {
      name: org.org_name,
      unit: org.unit,
      address: org.address,
      latitude: org.lat,
      longitude: org.long,
      email: org.email,
      phone_number: org.phone_number,
      ext: org.ext,
    },
    onSubmit: async (values) => {
      try {
        await updateOrganisation(org.org_id, values);
        dispatch(getOrganization(Number(org.org_id)));

        toast.success(`Successfully updated Organization`);
      } catch (error) {}
    },
  });

  const handleAddressSelect = (address: any) => {
    formik.values.address = address.address;
    formik.values.latitude = address.lat;
    formik.values.longitude = address.long;
  };

  return (
    <Paper className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Organization Info</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="org-info-name"
            variant="outlined"
            size="small"
            label="Name"
            name="name"
            fullWidth={true}
            value={formik.values.name}
            style={{ height: 33 }}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="org-info-name"
            variant="outlined"
            size="small"
            label="Unit"
            name="unit"
            fullWidth={true}
            value={formik.values.unit}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="org-info-email"
            variant="outlined"
            size="small"
            type="number"
            label="Extension"
            name="ext"
            fullWidth={true}
            value={formik.values.ext}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AddressSearch
            onAddressSelect={handleAddressSelect}
            currentAddress={formik.values.address}
            lat={lat}
            long={long}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="org-info-email"
            variant="outlined"
            size="small"
            label="Email"
            name="email"
            fullWidth={true}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPhoneSearch
            value={formik.values.phone_number}
            handleChange={(value) =>
              formik.setFieldValue("phone_number", value)
            }
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            fullWidth={true}
            style={{ width: "50%" }}
            onClick={() => {
              formik.handleSubmit();
            }}
            disableFocusRipple={true}
            disableRipple={true}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrgInfo;
