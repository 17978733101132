import { path } from "ramda";
import {
  GET_DRIVERS,
  ADD_DRIVER,
  Drivers,
  ADD_SELECTED_DRIVERS,
} from "./types";

const initialDriverState: Drivers = {
  drivers: {},
  moreDrivers: false,
  totalDrivers: 0,
  selectedDrivers: [],
};

const drivers = (state: Drivers = initialDriverState, action: any): Drivers => {
  const type: any = path(["type"], action);
  const payload: any = path(["payload"], action);

  switch (type) {
    case ADD_DRIVER:
      return Object.assign({}, state, payload);
    case ADD_SELECTED_DRIVERS:
      return {
        ...state,
        selectedDrivers: action.payload,
      };
    case GET_DRIVERS:
      return {
        ...state,
        drivers: Object.assign({}, state.drivers, payload.drivers),
        moreDrivers: payload.moreDrivers,
        totalDrivers: payload.totalDrivers,
      };
  }

  return state;
};

export default drivers;
