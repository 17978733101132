import { 
  GET_PHARMACIES,
  ADD_PHARMACY,
  UPDATE_PHARMACY,
  PharmacyAction,
  Pharmacies, 
  PharmacyInfo
} from "./types";

export const getPharmacies = (pharmacies: Pharmacies): PharmacyAction => {
  return {
    type: GET_PHARMACIES,
    payload: pharmacies
  }
}

export const addPharmacy = (pharmacy: PharmacyInfo): PharmacyAction => {
  return {
    type: ADD_PHARMACY,
    payload: pharmacy
  }
}

export const updatePharmacyInfo = (pharmacyId, updatedPharmacyInfo) => ({
  type: UPDATE_PHARMACY,
  pharmacyId,
  updatedPharmacyInfo,
});