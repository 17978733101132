import React, { useEffect } from "react";
import { useStyles as styles } from "./styles";
import Button from "@material-ui/core/Button";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import weelTheme from "../components/material-ui/theme";
import PharmaciesTable from "./components/pharmaciesTable";
import { fetchPharmacies } from "../store/pharmacy/middleware";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Box, Typography } from "@mui/material";

interface PharmaciesProps {
  readOnly?: boolean;
}

const Pharmacies: React.FC<PharmaciesProps> = (props) => {
  const dispatch = useAppDispatch();
  const pharmacies = useAppSelector((state) => state.pharmacies);

  const org = useAppSelector((state) => state.organization);

  useEffect(() => {
    dispatch(fetchPharmacies(org?.org_id, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  const nav = useNavigate();
  const classes = styles();
  const handleCreateClick = () => {
    nav(`/pharmacies/create`);
  };
  return (
    <div className={classes.root}>
      <Box className={classes.btnContainer}>
        <Typography variant="h6" fontWeight={700}>
          Pharmacies
        </Typography>
        <Button
          fullWidth
          className={classes.createBtn}
          disableFocusRipple={true}
          disableRipple={true}
          onClick={handleCreateClick}
        >
          Create
        </Button>
      </Box>
      {true != null ? (
        <div className={classes.pharmaciesWrapper}>
          <br />
          <PharmaciesTable pharmacies={pharmacies} />
        </div>
      ) : (
        <div className={classes.spinner}>
          <Loader
            type="Bars"
            color={weelTheme.palette.secondary.main}
            height={100}
            width={100}
          />
        </div>
      )}
    </div>
  );
};

export default Pharmacies;
