import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { useFormik } from "formik";
import { useAppDispatch } from "../hooks";
import { handleLogin } from "../store/authentication/user/middleware";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { COURIER_ADMIN, WEEL_ADMIN } from "../services/roles";
import { get, post } from "../services/api/api";
import { uploadProgress } from "../services/progressCalculator";

type LoginProps = {
  authenticated: boolean;
};

const LoginForm: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const [loginError, setLoginError] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [loading, setloading] = useState(false);
  const uploadFilePercentage = (progressEvent) => {
    setProgress(uploadProgress(progressEvent));
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        setloading(true);
        const response = await post(
          "/api/v1/auth/login",
          {
            username: values.username,
            password: values.password,
          },
          uploadFilePercentage
        );

        dispatch(handleLogin(response));
        setloading(false);

        if (response.user_role === WEEL_ADMIN) {
          nav("/admin", { replace: true });
        } else if (response.data.user_role === COURIER_ADMIN) {
          const url = `/api/v1/users/${Number(
            response.data.user_id
          )}/useraccess`;
          get(url)
            .then((resp) => {
              // Assuming resp.data.user_access_list is an array of objects
              const userAccessList = resp.data.user_access_list;

              // Iterate over the array to check if any object has org_type equal to "pharmacy"
              const isPharmacy = userAccessList.some(
                (obj) => obj.org_type === "pharmacy"
              );
              nav(isPharmacy ? "/deliveries" : "/pharmacies", {
                replace: true,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          nav("/deliveries", { replace: true });
        }
      } catch (err) {
        setloading(false);
        setLoginError(true);
      }
    },
  });

  const goPrivacyPolicy = () => {
    nav("/privacy-policy");
  };

  const goTermOfUse = () => {
    nav("/terms-of-use");
  };

  const onClickHere = () => {
    window.open("https://google.com/", "_blank");
  };

  return (
    <Container>
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        <Paper className={classes.card} elevation={1}>
          <div className={classes.header}>
            <img
              className={classes.logo}
              src="https://weel-branding.s3.ca-central-1.amazonaws.com/icon.png"
              alt="Logo"
            />
            <Typography variant="body1">Login to your Weel account</Typography>
            {loginError ? (
              <div className={classes.errorCtnr}>
                "Something went wrong when trying to login."
              </div>
            ) : null}
            <TextField
              id="username"
              name="username"
              className={classes.textField}
              type="text"
              size="medium"
              error={loginError}
              required={true}
              label="Username"
              variant="outlined"
              onChange={(e) => {
                formik.handleChange(e);
                setLoginError(false);
              }}
              value={formik.values.username}
            />
            <TextField
              id="password"
              name="password"
              className={classes.textField}
              type="password"
              size="medium"
              error={loginError}
              required={true}
              label="Password"
              variant="outlined"
              onChange={(e) => {
                formik.handleChange(e);
                setLoginError(false);
              }}
              value={formik.values.password}
            />
            <Button
              id="login"
              className={classes.button}
              type="submit"
              disableFocusRipple={true}
              disableRipple={true}
              role="button"
            >
              Login
            </Button>
          </div>
          <Box>
            <Typography className={classes.startText} variant="body1">
              Not using Weel yet? Click
              <Typography
                component="span"
                className={classes.hereText}
                variant="body1"
                onClick={() => {
                  onClickHere();
                }}
              >
                here
              </Typography>
              to start.
            </Typography>
            <Box className={classes.termOfUseContainer}>
              <Typography
                onClick={() => {
                  goTermOfUse();
                }}
                className={classes.link}
                variant="body1"
              >
                Terms of Use
              </Typography>
              <Typography
                onClick={() => {
                  goPrivacyPolicy();
                }}
                style={{ paddingLeft: 12 }}
                className={classes.link}
                variant="body1"
              >
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </Paper>
      </form>
      {loading && (
        <Box className={classes.loadingOverlay}>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              value={progress}
              color="secondary"
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                style={{ color: "white", fontSize: "28", fontWeight: "bold" }}
              >{`${Math.round(progress)}%`}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default LoginForm;
