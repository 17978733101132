import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    createBtn: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      width: "9.75rem",
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    pharmaciesWrapper: {
      display: "flex",
      marginTop: theme.spacing(4),
      maxWidth: "100%",
    },
    spinner: {
      margin: "50%",
    },
    btnContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);
