import { path } from 'ramda';
import { ADD_PATIENT, GET_PATIENTS, Patients } from './types';

const initialPatientState: Patients = {
  patients: {}, 
  morePatients: false, 
  totalPatients: 0
};

const patients = (state: Patients = initialPatientState, action: any): Patients => {
  const type: any = path(['type'], action);
  const payload: any = path(['payload'], action);

  switch (type) {
    case ADD_PATIENT: 
      return Object.assign({}, state, payload);
    case GET_PATIENTS: 
      return {
        ...state, 
        patients: Object.assign({}, state.patients, payload.patients),
        morePatients: payload.morePatients,
        totalPatients: payload.totalPatients
      }
  }
  
  return state;
}

export default patients;