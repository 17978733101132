import React from "react";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-hot-toast";

import useStyles from "./styles";
import { addNewUser } from "../../../services/api/requests";
import StyledPhoneInput from "../../../components/styledPhoneInput";

const AddUser: React.FC<{}> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      type: "",
      orgId: "",
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      email: "",
      ext: "",
      phoneNumber: "",
      isSuperUser: false,
    },
    onSubmit: (values) => {
      let convertedValues = {
        ...values,
        orgId: parseInt(values.orgId),
        ext: parseInt(values.ext),
      };
      addNewUser(convertedValues)
        .then((resp) => {
          toast.success(`Successfully added ${values.username} as a new user`);
          //formik.resetForm();
        })
        .catch((err) => {
          toast.error(
            `Woops! Something went wrong when trying to create a new user`
          );
          console.error(err);
        });
    },
  });

  return (
    <Paper className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.heading} variant="h6">
              Add User
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required={true}
              name="username"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.username}
              variant="outlined"
              label="Username"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required={true}
              name="password"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.password}
              variant="outlined"
              label="Password"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" size="small">
              <InputLabel id="type-outlined-label">Type</InputLabel>
              <Select
                required={true}
                className={classes.largeInput}
                label="Type"
                labelId="type-outlined-label"
                defaultValue={"driver"}
                value={formik.values.type}
                name="type"
                onChange={formik.handleChange}
              >
                <MenuItem key={3} value={"driver"}>
                  Driver
                </MenuItem>
                <MenuItem key={5} value={"pharmacy_owner"}>
                  Pharmacy Owner
                </MenuItem>
                <MenuItem key={5} value={"pharmacy_employee"}>
                  Pharmacy Employee
                </MenuItem>
                <MenuItem key={3} value={"courier_admin"}>
                  Courier Admin
                </MenuItem>
                <MenuItem key={3} value={"weel_admin"}>
                  Weel Admin
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required={true}
              name="orgId"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.orgId}
              variant="outlined"
              label="Organization"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required={true}
              name="firstName"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              variant="outlined"
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required={true}
              name="lastName"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              variant="outlined"
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledPhoneInput formik={formik} fieldName="phoneNumber" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="ext"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.ext}
              variant="outlined"
              label="Ext"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="email"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
              label="Email"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isSuperUser}
                  onChange={formik.handleChange}
                  name="isSuperUser"
                  color="primary"
                />
              }
              label="Super User?"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.createButton}
              disableFocusRipple={true}
              disableRipple={true}
              type="submit"
            >
              Add User
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddUser;
