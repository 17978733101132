// libraries
import React, { useEffect, useState } from "react";

// styles
import useStyles from "./styles";
import { Box, Button, Typography } from "@material-ui/core";
import LogList from "./components/logList";
import CustomCheckbox from "../components/customCheckbox";
import { useAppDispatch, useAppSelector } from "../hooks";
import { get } from "../services/api/api";
import { addSelectedDriver } from "../store/driver/actions";

interface Driver {
  id: number;
  name: string;
  phone_number: number;
  isSelected: boolean;
}

const Logs = () => {
  const classes = useStyles();
  const user = useAppSelector((state) => state.user.access);
  const dispatch = useAppDispatch();
  const { selectedDrivers } = useAppSelector((state) => state.drivers);
  const [drivers, setDrivers] = useState<Driver[]>([]);

  const fetchDrivers = async () => {
    try {
      const response = await get(
        `api/v1/organizations/${user[0].org_id}/drivers`
      );
      // Checking if selectedDrivers exist and performing the mapping
      if (selectedDrivers && selectedDrivers.length > 0) {
        const updatedDrivers = response.data.drivers.map((driver) => {
          const driverInfo = selectedDrivers.find(
            (selectedDriver: Driver) => selectedDriver.id === driver.id
          );
          return {
            ...driver,
            isSelected: driverInfo ? driverInfo.isSelected : false,
          };
        });
        setDrivers(updatedDrivers);
        return;
      } else {
        const updatedDrivers = response.data.drivers.map((driver) => {
          return {
            ...driver,
            isSelected: false,
          };
        });
        setDrivers(updatedDrivers);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (driverInfo) => {
    const updatedSelectedDrivers = drivers.map((selectedDriver: Driver) => {
      if (selectedDriver.id === driverInfo.id) {
        // Toggle the isSelected property
        return {
          ...selectedDriver,
          isSelected: !selectedDriver.isSelected,
        };
      }
      return selectedDriver;
    });
    setDrivers(updatedSelectedDrivers);
    // Filter the array to only include objects with isSelected true
    const selectedDriversToDispatch = updatedSelectedDrivers.filter(
      (driver) => driver.isSelected
    );

    dispatch(addSelectedDriver(selectedDriversToDispatch));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography variant="h6">Reports</Typography>
      </Box>
      <Box className={classes.driversBox}>
        <Box className={classes.textContainer}>
          <Typography variant="h6">Select Drivers</Typography>
        </Box>
        <Box className={classes.driversCheckBoxes}>
          {drivers?.map((item: Driver, idx: number) => (
            <CustomCheckbox
              key={`driver-${idx}`}
              label={item.name}
              driver={item}
              handleChange={handleChange}
            />
          ))}
        </Box>
      </Box>
      <LogList />
      <Box display="flex" justifyContent="center">
        <Button
          size="small"
          variant="contained"
          disableFocusRipple={true}
          disableRipple={true}
          className={classes.btnContained}
          type="submit"
        >
          Generate
        </Button>
      </Box>
    </Box>
  );
};

export default Logs;
