import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import { useStyles as styles } from "./styles";
import { useAppSelector } from "../hooks";
import PatientTable from "./components/patientTable";
import weelTheme from "../components/material-ui/theme";

import { useNavigate } from "react-router-dom";
import { get } from "../services/api/api";
import { Box, Typography } from "@material-ui/core";

interface Patient {
  patientId: number;
  dateCreated: string;
  totalDeliveries: number;
  unit: number;
  address: string;
  name: string;
  phoneNumber: number;
}

const PatientsContainer: React.FC<{}> = (props) => {
  const classes = styles();
  const nav = useNavigate();

  const org = useAppSelector((state) => state.organization);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const fetchPatients = (offset) => {
    if (!hasMore) {
      return;
    }
    get(
      `/api/v1/organizations/${Number(org?.org_id)}/patients?offset=${offset}`
    )
      .then((response) => {
        if (response.data != null && response.data.patients.length > 0) {
          setPatients([...patients, ...response.data.patients]);
          setTotal(response.data.totalPatients);
          setHasMore(response.data.hasMore);
        }
      })
      .catch((err) => {
        console.log("error :>> ", err);
      });
  };

  useEffect(() => {
    fetchPatients(patients.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={2} className={classes.toolbar}>
          <Typography variant="h6" id="tableTitle" component="div">
            Customers
          </Typography>
          <Box className={classes.btnBox}>
            <Button
              fullWidth
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => {
                nav("/customers/create");
              }}
              className={classes.btnStyle}
            >
              Create
            </Button>
          </Box>
        </Grid>
      </Grid>
      {patients != null ? (
        <div className={classes.patientWrapper}>
          <br />
          <PatientTable
            patients={patients.length > 0 ? patients : []}
            totalPatients={total}
            fetchPatients={fetchPatients}
          />
        </div>
      ) : (
        <div className={classes.spinner}>
          <Loader
            type="Bars"
            color={weelTheme.palette.secondary.main}
            height={100}
            width={100}
          />
        </div>
      )}
    </div>
  );
};

export default PatientsContainer;
