import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-hot-toast";
import { AddressSearch } from "../../../components/addressSearch";
import useStyles from "./styles";
import { addNewOrganization } from "../../../services/api/requests";
import StyledPhoneInput from "../../../components/styledPhoneInput";
import { Checkbox, FormControlLabel } from "@material-ui/core";

type GeoLocationType = {
  lat: number;
  long: number;
};

const AddOrgForm: React.FC<{}> = (props) => {
  const classes = useStyles();
  const [geoLocation, setGeoLocation] = useState<GeoLocationType>({
    lat: 0,
    long: 0,
  });

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition((pos) => {
      setGeoLocation({ lat: pos.coords.latitude, long: pos.coords.longitude });
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      orgType: "",
      phoneNumber: "",
      email: "",
      unit: "",
      address: "",
      addressKey: "",
      lat: 0,
      long: 0,
      dist: 0,
      self_managed: false,
    },
    onSubmit: (values) => {
      addNewOrganization(values)
        .then((res) => {
          toast.success(
            `Successfully added ${values.name} as a new Organization`
          );
          formik.resetForm();
        })
        .catch((err) => {
          toast.error(
            "Woops! something went wrong when trying to create a new organization"
          );
          console.error(err);
        });
    },
  });

  const handleAddressSelect = (address: any) => {
    formik.values.address = address.address;
    formik.values.addressKey = address.id;
    formik.values.lat = address.lat;
    formik.values.long = address.long;
    formik.values.dist = address.dist;
  };

  return (
    <Paper className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Add Organization</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="name"
              required={true}
              size="small"
              className={classes.nameInput}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.name}
              label="Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" size="small">
              <InputLabel id="type-outlined-label">Type</InputLabel>
              <Select
                required={true}
                className={classes.largeInput}
                label="Type"
                labelId="type-outlined-label"
                defaultValue={"pharmacy"}
                value={formik.values.orgType}
                name="orgType"
                onChange={formik.handleChange}
              >
                <MenuItem key={3} value={"service_provider"}>
                  Service Provider
                </MenuItem>
                <MenuItem key={5} value={"pharmacy"}>
                  Pharmacy
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledPhoneInput formik={formik} fieldName="phoneNumber" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="email"
              required={true}
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
              label="Email"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="unit"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.unit}
              variant="outlined"
              label="Unit"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={classes.addressSearchField}>
              <AddressSearch
                onAddressSelect={handleAddressSelect}
                currentAddress={formik.values.address}
                lat={geoLocation.lat}
                long={geoLocation.long}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.self_managed}
                  onChange={formik.handleChange}
                  name="self_managed"
                  color="primary"
                />
              }
              label="Self Managed?"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              className={classes.createButton}
              disableFocusRipple={true}
              disableRipple={true}
              type="submit"
            >
              Add Organization
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddOrgForm;
