import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRoot: {
      width: "100%",
      borderBottom: "1px solid #CFCFCF",
    },
    cellRoot: {
      borderBottom: "none",
      fontWeight: 600,
      lineHeight: "22px",
      whiteSpace: "nowrap",
      padding: "1rem 1.5rem .75rem 1.375rem",
    },
  })
);
