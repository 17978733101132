import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    card: {
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "column",
      alignItems: "center",
      width: "350px",
      height: "500px",
      margin: "auto",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2.5),
    },
    textField: {
      marginTop: "20px",
      width: "250px",
      height: "50px",
      color: "#52a0ff",
      "&:hover": {
        color: "#52a0ff",
      },
    },
    button: {
      color: "#fff",
      marginTop: "20px",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logo: {
      height: "70px",
      width: "70px",
      margin: "25px auto",
      alignSelf: "flex-start",
    },
    errorCtnr: {
      marginTop: "15px",
      width: "90%",
      backgroundColor: theme.palette.error.light,
      color: "#ffffff",
      fontSize: "12px",
      padding: "15px",
      height: "20px",
      textAlign: "center",
      borderRadius: "10px",
    },

    startText: {
      fontWeight: theme.typography.fontWeightRegular as
        | "normal"
        | "bold"
        | number,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1.25),
    },
    hereText: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      fontWeight: theme.typography.fontWeightRegular as
        | "normal"
        | "bold"
        | number,
      color: "#4340E5",
      cursor: "pointer",
    },
    link: {
      fontWeight: theme.typography.fontWeightRegular as
        | "normal"
        | "bold"
        | number,
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    termOfUseContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    loadingOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
      zIndex: 1000, // Ensure it's above all other content
    },
  })
);

export default useStyles;
