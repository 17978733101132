import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "column wrap",
      marginBottom: theme.spacing(8),
    },
    icon: {
      cursor: "pointer",
      color: theme.palette.common.black,
      height: "16px",
      width: "16px",
      zIndex: 1,
    },
    heading: {
      marginTop: "0px !important",
      color: theme.palette.common.black,
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      lineHeight: "22px !important",
    },
    paper: {
      width: "100%",
      borderRadius: "10px",
      marginBottom: "22px",
    },
    title: {
      "font-weight": `${theme.typography.fontWeightBold} `,
      marginTop: "0px",
    },
    subTitle: {
      fontWeight: theme.typography.fontWeightBold as "normal" | "bold" | number,
      marginTop: "5px",
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      borderBottom: "1px solid #CFCFCF",
    },
    body: {
      padding: "15px",
    },
  })
);
