export const LOGIN_ERROR = "LOGIN_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export type AuthError = {
  isLoginErr: boolean,
  errorMsg: string
}

export type FailedAuthentication = {
  type: typeof LOGIN_ERROR, 
  payload: AuthError
}

export type ClearLoginError = {
  type: typeof CLEAR_ERROR
}

export type AuthErrorActionTypes = FailedAuthentication | ClearLoginError;
