import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Grid } from "@material-ui/core";
import DeliveriesListing from "../Deliverieslisting";

const DragAndDropList: React.FC = () => {
  const [items, setItems] = useState(
    Array(6)
      .fill(null)
      .map((_, index) => `item-${index}`)
  );

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <Grid
            container
            direction="column"
            spacing={2}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((_, index) => (
              <DeliveriesListing index={index} />
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropList;
