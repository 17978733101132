import { ADD_USER_ACCESS,CHANGE_USER_ACCESS, UserAccessList, AccessActionTypes } from './types'


export const changeUserAccess = (updatedAccess) => {
  return {
    type: CHANGE_USER_ACCESS,
    access: updatedAccess,
  };
};

export const insertNewUserAccess = (access: UserAccessList): AccessActionTypes => {
    return {
        type: ADD_USER_ACCESS, 
        access,
    }
}