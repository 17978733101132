import { path } from "ramda";
import { User } from "./types";

const initialState: User = {
  user_id: "",
  unique_id: "",
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  user_role: "",
  verified: false,
  active: false,
  loggedIn: false,
};

export const currentUser = (
  state: User = initialState,
  action: Object
): User => {
  const type: any = path(["type"], action);
  const payload: any = path(["payload"], action);

  if (type) {
    switch (type) {
      case "USER_LOGIN":
        return {
          user_id: payload.user_id,
          unique_id: payload.unique_id,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          username: payload.username,
          user_role: payload.user_role,
          verified: payload.verified,
          active: payload.active,
          loggedIn: payload.loggedIn,
        };
      case "USER_LOGOUT":
        return Object.assign({}, state, payload);
      default:
        return state;
    }
  }

  return state;
};
