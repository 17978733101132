import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { getPlaces } from "../../services/places";

type AddressSearchProps = {
  onAddressSelect: any;
  currentAddress: string;
  lat: number;
  long: number;
};

export const AddressSearch: React.FC<AddressSearchProps> = (props) => {
  const { onAddressSelect, lat, long, currentAddress } = props;
  const [searchResults, setSearchResults] = useState<any>([]);

  const onSearch = async (query: any) => {
    if (query.length > 0) {
      let results = await getPlaces(query, lat, long);

      setSearchResults(results);
    }
  };

  const sanitizeResults = () => {
    let cleanAddresses = searchResults.map((adr) => adr.address);
    return cleanAddresses;
  };

  const selectAddress = (address: any) => {
    let selected = searchResults.find((addr) => addr.address === address);
    onAddressSelect(selected);
  };

  return (
    <>
      <Autocomplete
        freeSolo={true}
        size="small"
        renderInput={(params) => <TextField {...params} variant="outlined" name="address" label="Address"/>}
        value={currentAddress}
        onInputChange={_.throttle((event, value, reason) => {
          if (reason === "input") {
            onSearch(value);
          }
        }, 3000)}
        onChange={(event, value, reason) => {
          if (reason === "selectOption") {
            selectAddress(value);
          }
        }}
        options={sanitizeResults()}
        filterOptions={(x) => x}
        defaultValue={currentAddress}
      />
    </>
  );
};
