import { addPharmacy, getPharmacies, updatePharmacyInfo } from "./actions";
import { get, post, put } from "../../services/api/api";
import { toast } from "react-hot-toast";

export const fetchPharmacies =
  (org_id: string, offset: number) => (dispatch: any) => {
    get(
      `/api/v1/organizations/${Number(org_id)}/pharmacies?offset=${offset}`
    ).then((resp) => {
      console.log(" resp.data.pharmacies :>> ", resp.data.pharmacies?.length);
      if (resp.data != null && resp.data.pharmacies.length > 0) {
        dispatch(getPharmacies(resp.data));
      }
    });
    return;
  };

export const addNewPharmacy = (pharmacyInfo, orgId: any) => {
  return async (dispatch: any) => {
    try {
      const url = `/api/v1/organizations/${Number(orgId)}/pharmacies`;
      await post(url, pharmacyInfo);
      dispatch(addPharmacy(pharmacyInfo));
      toast.success("Successfully created new pharmacy!");
    } catch (err) {
      const error = err as Error;
      toast.error(
        "Something went wrong while creating new pharmacy: " + error.message
      );
      console.error(err);
    }
  };
};

export const updatePharmacy = (pharmacyId, updatedPharmacyInfo) => {
  return async (dispatch) => {
    try {
      const url = `/api/v1/pharmacies/${pharmacyId}`;
      await put(url, { pharmacy: updatedPharmacyInfo });
      dispatch(updatePharmacyInfo(pharmacyId, updatedPharmacyInfo));
      toast.success("Successfully updated pharmacy!");
    } catch (err) {
      const error = err as Error;
      toast.error(
        "Something went wrong while updating pharmacy: " + error.message
      );
      console.error(err);
    }
  };
};
