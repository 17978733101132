import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      borderRadius: "10px !important",
    },
    title: {
      marginTop: "0px !important",
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      borderBottom: "1px solid #CFCFCF",
    },
    body: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(2),
    },
    container: {
      marginBottom: theme.spacing(3),
    },
    formOuterContainer: {
      paddingTop: theme.spacing(1),
      maxHeight: 198,
      overflowX: "hidden",
      overflowY: "auto",
      marginBottom: theme.spacing(1),
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    addIcon: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
      background: theme.palette.secondary.main,
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
    radioGroup: {
      marginBottom: theme.spacing(2),
    },
    saveBtn: {
      whiteSpace: "nowrap",
      width: "240px",
      "text-transform": "capitalize !important",
    },
    label: {
      color: theme.palette.common.black,
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      lineHeight: "22px",
    },
    formContainer: {
      display: "flex",
      gap: theme.spacing(2.5),
      marginBottom: theme.spacing(3),
    },
    priceContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    costPerDelivery: {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: theme.typography.fontWeightBold as "normal" | "bold" | number,
      marginLeft: theme.spacing(2.5),
    },
  })
);
