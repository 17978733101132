export const GET_PHARMACIES = "GET_PHARMACIES";
export const ADD_PHARMACY = "ADD_PHARMACY";
export const UPDATE_PHARMACY = "UPDATE_PHARMACY";

export type PharmacyInfo = {
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  extension: number;
  unit: string;
  address: string;
  addressKey: string;
  lat: number;
  long: number;
  dist: number;
  totalDeliveries: number;
  totalSpent: number;
};

export type Pharmacies = {
  pharmacies: {
    [id: string]: PharmacyInfo;
  };
  morePharmacies: boolean;
  totalPharmacies: number;
};

type GetPharmacies = {
  type: typeof GET_PHARMACIES;
  payload: Pharmacies;
};

type AddPharmacy = {
  type: typeof ADD_PHARMACY;
  payload: PharmacyInfo;
};

type UpdatePharmacy = {
  type: typeof UPDATE_PHARMACY;
  payload: PharmacyInfo;
};

export type PharmacyAction = AddPharmacy | GetPharmacies | UpdatePharmacy;
