import { getOrders, addOrder, getNewOrders } from "./actions";
import { get, post } from "../../services/api/api";
import { toast } from "react-hot-toast";

export const retrieveOrders =
  (org_id: number, offset: number, date: string) => (dispatch: any) => {
    const url: string = `/api/v1/organizations/${Number(
      org_id
    )}/orders?delivery_date=${date}`;
    get(url)
      .then((resp) => {
        if (resp.data != null && resp.data.ordersList > 0) {
          dispatch(
            offset === 0 ? getNewOrders(resp.data) : getOrders(resp.data)
          );
        } else {
          // dispatch(getOrders(resp.data));
          dispatch(
            offset === 0 ? getNewOrders(resp.data) : getOrders(resp.data)
          );
        }
      })
      .catch((err) => {
        toast.error("Something went wrong when looking for the orders!");
        console.error(err);
      });
  };

export const createOrder = (org_id: number, order: any, nav: any) => {
  return (dispatch: any) => {
    const url: string = `/api/v1/organizations/${org_id}/orders`;
    post(url, {
      ...order,
    })
      .then((resp) => {
        dispatch(addOrder(order));
      })
      .then((resp) => {
        toast.success("Successfully created an order!");
        nav("/deliveries");
      })
      .catch((err) => {
        toast.error("Something went wrong while creating an order.");
        console.error(err);
      });
  };
};
