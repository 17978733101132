import { HeaderCell } from "./components/logTable/types";

export const travelRow = [
  {
    ref: 100001,
    customer:
      "Mesam Haider <br/> 50 Baylawn drive, Markham, ON, L3s 2R8, Canada",

    preparedAt: "09/24/2023 4:00 PM",
    preparedBy: "Sam Peter",
    pickUpAt: "09/24/2023 5:00 PM",
    deliveryAt: "09/24/2023 5:40 PM",
    notes:
      "When delivery was being completed the patient refused to open the door. ",
    courierName: "Aoun Muhammad",
    travel: "25 km",
  },
];

export const cashRow = [
  {
    ref: 100001,
    customer:
      "Mesam Haider <br/> 50 Baylawn drive, Markham, ON, L3s 2R8, Canada",
    deliveredAt: "09/24/2023 5:40 PM",
    notes:
      "When delivery was being completed the patient refused to open the door. ",
    courierName: "Aoun Muhammad",
    amountRequested: "$ 10.20",
    amountCollected: "$ 10.20",
    collectionMethod: "Cash",
  },
];

export const travelHeadCells: Array<HeaderCell> = [
  {
    id: "ref",
    leftAlign: true,
    disablePadding: false,
    label: "Delivery Reference",
  },
  {
    id: "customer",
    leftAlign: true,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "preparedAt",
    leftAlign: true,
    disablePadding: false,
    label: "Prepared At",
  },
  {
    id: "preparedBy",
    leftAlign: true,
    disablePadding: false,
    label: "Prepared By",
  },
  {
    id: "pickedUpAt",
    leftAlign: true,
    disablePadding: false,
    label: "Picked Up At",
  },
  {
    id: "deliveredAt",
    leftAlign: true,
    disablePadding: false,
    label: "Delivered At",
  },
  {
    id: "deliveryNotes",
    leftAlign: true,
    disablePadding: false,
    label: "Delivery Notes",
  },
  {
    id: "courierName",
    leftAlign: true,
    disablePadding: false,
    label: "Courier Name",
  },
  {
    id: "distanceTraveled",
    leftAlign: true,
    disablePadding: false,
    label: "Distance Traveled",
  },
];

export const cashHeadCells: Array<HeaderCell> = [
  {
    id: "ref",
    leftAlign: true,
    disablePadding: false,
    label: "Delivery Reference",
  },
  {
    id: "customer",
    leftAlign: true,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "deliverAt",
    leftAlign: true,
    disablePadding: false,
    label: "Deliver At",
  },

  {
    id: "deliveryNotes",
    leftAlign: true,
    disablePadding: false,
    label: "Delivery Notes",
  },
  {
    id: "courierName",
    leftAlign: true,
    disablePadding: false,
    label: "Courier Name",
  },
  {
    id: "amountRequested",
    leftAlign: true,
    disablePadding: false,
    label: "Amount Requested",
  },
  {
    id: "amountCollected",
    leftAlign: true,
    disablePadding: false,
    label: "Amount Collected",
  },
  {
    id: "collectionMethod",
    leftAlign: true,
    disablePadding: false,
    label: "Collection Method",
  },
];
