import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";

import Typography from "@material-ui/core/Typography";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Stack } from "@mui/material";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import { useStyles } from "./style";

import "./style.css";
import DriverAssignment from "./components/driverAssignment";
import MenuPopupState from "../components/menuPopupState";
import StatusChip from "../components/statusChip";
import { fetchTagsByOrders } from "../services/api/requests";
import { useAppSelector } from "../hooks";
import { transformString } from "../services/helper";
import { Button } from "@material-ui/core";

const OrderDetails: React.FC<{}> = () => {
  const location = useLocation();
  const styles = useStyles();
  const nav = useNavigate();
  const org = useAppSelector((state) => state.organization);
  const [tags, setTags] = useState([]);
  const { deliveryId } = useParams();

  let currentOrder;
  if (deliveryId !== undefined) {
    currentOrder = location.state.order;
  }

  const goBack = () => nav("/deliveries", { replace: true });
  useEffect(() => {
    const tagsBox = document.getElementById("tagsBox");
    if (tagsBox) {
      tagsBox.style.overflowY = "auto";

      (tagsBox.style as any).scrollbarWidth = "none"; // For Firefox
      (tagsBox.style as any).msOverflowStyle = "none"; // For Internet Explorer 10+
      (tagsBox.style as any).WebkitScrollbar = "none"; // For Safari and Chrome
    }
    getTagsByOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTagsByOrder = async () => {
    try {
      const response = await fetchTagsByOrders(org.org_id, deliveryId);
      setTags(response.data.tags);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Grid container xs={12} item spacing={4} className={styles.root}>
      <Grid item sm={10} style={{ padding: 0, paddingLeft: 16 }}>
        <Stack
          mb={0}
          display="flex"
          flexDirection="row"
          spacing={0}
          padding={0}
          marginTop={2}
          alignItems="center"
        >
          <NavigateBeforeIcon
            onClick={() => goBack()}
            className={styles.icon}
          />
          <Typography variant="h6" component="div" className={styles.heading}>
            {currentOrder.name}
          </Typography>
        </Stack>
      </Grid>
      <Grid id="tagsBox" item sm={12} md={8} lg={7} className={styles.tagsBox}>
        <Box className={styles.tagSection}>
          <StatusChip status={currentOrder.status} />
          <Chip
            className={styles.statusChip}
            size="small"
            label={currentOrder.type}
          />
          {tags?.map((tag, idx) => (
            <Chip key={idx} className={styles.chip} size="small" label={tag} />
          ))}
        </Box>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          nav(`/deliveries/${deliveryId}/mapview`, {
            state: { order: currentOrder },
          });
        }}
      >
        View Map
      </Button>
      <Grid item sm={12} md={8} lg={7}>
        <Paper className={styles.card}>
          <Box className={styles.header}>
            <Typography variant="subtitle1" className={styles.subtitleClass}>
              Patient Details
            </Typography>
            <MenuPopupState onDeleteClick={() => { }} />
          </Box>
          <Typography variant="body1" className={styles.textStyle}>
            {currentOrder.submitted_by}
          </Typography>
          <Typography variant="body1" className={styles.textStyle}>
            {currentOrder.unit} {currentOrder.to_address}
          </Typography>
          {currentOrder?.phone_number && (
            <Box className={styles.IconBox}>
              <PhoneIphoneIcon />
              <Typography variant="body1" className={styles.textStyle}>
                {currentOrder.phone_number}
              </Typography>
            </Box>
          )}
          {currentOrder?.email && (
            <Box className={styles.IconBox}>
              <AlternateEmailIcon />
              <Typography variant="body1" className={styles.textStyle}>
                {currentOrder?.email}
              </Typography>
            </Box>
          )}
          <Typography
            variant="subtitle1"
            className={styles.subtitleClass}
            style={{ marginTop: 13 }}
          >
            Order Details
          </Typography>
          <Typography variant="body1" className={styles.textStyle}>
            Delivery Date:
            {moment(currentOrder.delivery_date)
              .utc()
              .format("dddd, MMMM Do YYYY")}
            , {transformString(currentOrder.delivery_preference)}{" "}
            {currentOrder.delivery_preference !== "no_preference" &&
              moment(currentOrder.preference_start)
                .utc()
                .format(" h:mm:ss a")}{" "}
            {currentOrder.delivery_preference === "between" &&
              `and ${moment(currentOrder.preference_end)
                .utc()
                .format(" h:mm:ss a")}`}
          </Typography>
          <Typography variant="body1" className={styles.textStyle}>
            Amount: ${currentOrder.amount} CAD
          </Typography>
          {currentOrder.amount_collected > 0 && (
            <Typography variant="body1" className={styles.textStyle}>
              Amount Collected : ${currentOrder.amount_collected} CAD
            </Typography>
          )}
          {currentOrder?.payment_method && (
            <Typography variant="body1" className={styles.textStyle}>
              Payment method: {currentOrder?.payment_method}
            </Typography>
          )}
          <Typography variant="subtitle1" className={styles.subtitleClass}>
            Notes
          </Typography>
          <Box
            style={{
              marginTop: 12,
              border: "1px solid #CFCFCF",
              padding: 12,
              borderRadius: 5,
              minHeight: 50,
            }}
          >
            {currentOrder?.notes}
          </Box>
        </Paper>
      </Grid>
      <Grid item sm={12} md={8} lg={7}>
        <Paper className={styles.card}>
          <Grid item container sm={11}>
            <Box className={styles.subHeader}>
              <Typography variant="body1" className={styles.subtitleClass}>
                Driver Assignment
              </Typography>
            </Box>
            <Typography variant="body1" className={styles.textStyle}>
              The driver assigned to the order. If no one is assigned, you can
              assign someone here.
            </Typography>
            <Grid item sm={8} md={8} lg={8} style={{ marginTop: 16 }}>
              <DriverAssignment
                orderId={deliveryId}
                driverId={currentOrder?.driver_id}
                status={currentOrder.status}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
