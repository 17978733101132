import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    color: "#FFFFFF",
    width: "500px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
    padding: "14px",
  },
  staffList: {
    borderTop: "1px solid #e8e8e8",
    borderBottom: "1px solid #e8e8e8",
    height: "150px",
    overflowY: "auto",
  },
  member: {
    margin: "5px",
  },
  noStaffMsg: {
    color: "#cfcfcf",
    marginLeft: "35%",
    marginTop: "40px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },

  clearBtn: {
    color: "#ffffff",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
}));
