import { path } from "ramda";
import { GET_PHARMACIES, ADD_PHARMACY, Pharmacies } from "./types";

const initialPharmacyState: Pharmacies = {
  pharmacies: {},
  morePharmacies: false,
  totalPharmacies: 0,
};

const pharmacies = (
  state: Pharmacies = initialPharmacyState,
  action: any
): Pharmacies => {
  const type: any = path(["type"], action);
  const payload: any = path(["payload"], action);

  if (type) {
    switch (type) {
      case ADD_PHARMACY:
        return Object.assign({}, state, payload);
      case GET_PHARMACIES:
        console.log("payload.pharmacies :>> ", payload);
        return {
          ...state,
          pharmacies: Object.assign({}, state.pharmacies, payload.pharmacies),
          morePharmacies: payload.morePharmacies,
          totalPharmacies: payload.totalPharmacies,
        };
      default:
        return state;
    }
  }
  return state;
};

export default pharmacies;
