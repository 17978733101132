import React from "react";
import {
  Paper,
  FormControl,
  FormGroup,
  Box,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { FormikProps } from "formik";
import { FormikCreateOrder } from "../types";

type Tag = { id: number; name: string };

type AddDeliveryTagsProps = {
  formik: FormikProps<FormikCreateOrder>;
  subItems: Array<Tag>;
};

const AddDeliveryItems: React.FC<AddDeliveryTagsProps> = (props) => {
  const { formik, subItems } = props;

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.body}>
          <Box className={classes.content}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <Typography
                  variant="h6"
                  component="div"
                  className={classes.heading}
                >
                  Items
                </Typography>
                {subItems &&
                  subItems?.map((item, idx) => (
                    <Grid direction="row" className={classes.mainGrid}>
                      <Grid item xs={5}>
                        <Typography variant="subtitle1">{item.name}</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          type='number'
                          className={classes.textField}
                          name={`order.sub_item_${item.name.toLowerCase()}`}
                          size="small"
                          onChange={formik.handleChange}
                          value={formik.values.order[`sub_item_${item.name.toLowerCase()}`]}

                          variant="outlined"
                          label=""
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                  ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default AddDeliveryItems;
