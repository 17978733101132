import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column wrap",
    marginBottom: theme.spacing(8),
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.common.black,
    height: "16px",
    width: "16px",
    zIndex: 1,
  },
  heading: {
    marginTop: "0px !important",
  },
  rightGrids: {
    marginBottom: 16
  }
}));
