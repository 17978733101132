export const WEEL_ADMIN = "weel_admin";
export const DRIVER = "driver";
export const DELIVERY_ADMIN = "delivery_admin";
export const ORG_ADMIN = "pharmacy_owner";
export const COURIER_ADMIN = "courier_admin";
export const PHARMACY_EMPLOYEE = "pharmacy_employee";

export type UserRole =
  | typeof WEEL_ADMIN
  | typeof DRIVER
  | typeof DELIVERY_ADMIN
  | typeof ORG_ADMIN
  | typeof COURIER_ADMIN
  | null;
