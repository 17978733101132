import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: "100%",
      width: "100%",
      marginBottom: theme.spacing(2),
      borderRadius: "10px",
    },
    table: {
      flexFlow: "column nowrap",
    },
    pages: {
      borderTop: "1px solid #CFCFCF",
    },
    row: {
      flexFlow: "row nowrap",
      borderBottom: "1px solid #CFCFCF",
      width: "100%",
      "&:hover": {
        background: "#F5F5F5",
        cursor: "pointer",
      },
    },
    cells: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      borderBottom: "none",
      height: "20px",
      fontSize: "12px",
      paddingTop: "10px",
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
    },
    emptyRowsTitle: {
      color: "#CFCFCF",
      fontWeight: 700,
      lineHeight: "22px",
    },
    zeroResults: {
      lineHeight: 12.5,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      borderBottom: "none",
      fontSize: "18px",
      fontWeight: 600,
      opacity: 0.3,
      padding: "16px",
      width: "100%",
      textAlign: "center",
    },
  })
);
