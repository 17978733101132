export function transformString(input) {
  // Replace underscores with spaces
  let result = input?.replace(/_/g, " ");

  // Capitalize the first letter of each word
  result = result?.replace(/\b\w/g, (char) => char.toUpperCase());

  return result;
}

export function formatCanadianPhoneNumber(phoneNumber) {
  // Convert phoneNumber to a string and remove any non-digit characters
  const cleanedPhoneNumber = String(phoneNumber).replace(/\D/g, "");
  // Check if the number has 10 digits
  if (cleanedPhoneNumber.length === 11) {
    // Format the number into Canadian format +1 (###) ###-####
    return `+1 (${cleanedPhoneNumber.substring(
      0,
      3
    )}) ${cleanedPhoneNumber.substring(3, 6)}-${cleanedPhoneNumber.substring(
      6
    )}`;
  } else {
    // Return the original number if it doesn't have 10 digits
    return phoneNumber;
  }
}