import React from "react";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useStyles as styles } from "./styles";
import { useAppSelector } from "../hooks";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";

import ChangeDriverInfo from "./changeDriver";

const ChangeDriver: React.FC<{}> = () => {
  const classes = styles();

  const nav = useNavigate();

  const org = useAppSelector((state) => state.organization);
  const baseStyle = {
    fontSize: "0.75rem",
    fontWeight: "700",
    width: "6rem",
    height: "1.8rem",
    borderRadius: 5,
    marginBottom: 15,
  };
  const goDrivers = () => {
    nav("/drivers");
  };

  return (
    <Box className={classes.root}>
      <Stack
        mb={2}
        display="flex"
        flexDirection="row"
        spacing={0}
        alignItems="center"
      >
        <NavigateBeforeIcon
          onClick={() => {
            goDrivers();
          }}
          className={classes.icon}
        />
        <Typography variant="h6" component="div" className={classes.heading}>
          Drivers
        </Typography>
      </Stack>
      <Grid
        item
        xs={4}
        md={3}
        container
        style={{ paddingTop: "0", paddingBottom: "0" }}
      >
        <Chip
          style={{
            backgroundColor: "#D0E7B7",
            color: "#47824A",
            ...baseStyle,
          }}
          size="small"
          label="Active Route"
        />
      </Grid>
      <ChangeDriverInfo orgId={org?.org_id} />
    </Box>
  );
};

export default ChangeDriver;
