import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

import { HeaderCell } from "../types";
import { useStyles as styles } from "./styles";
import Typography from "@mui/material/Typography";

type TableHeaderProps = {
  headerCells: Array<HeaderCell>;
  orderDirection: "asc" | "desc";
  handleRequestSort: (property: string) => void;
  orderBy: string;
};

export const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const classes = styles();
  const { headerCells, orderDirection, orderBy, handleRequestSort } = props;

  return (
    <TableHead>
      <TableRow className={classes.headerRoot}>
        {headerCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.leftAlign ? "left" : "center"}
            className={classes.cellRoot}
            sortDirection={orderBy === headCell.id ? orderDirection : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? orderDirection : "asc"}
                onClick={() => handleRequestSort(headCell.id)}
              >
                <Typography variant="body1" fontWeight={"600"}>
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="body1" fontWeight={"600"}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
