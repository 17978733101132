import {
  ADD_ORDER,
  GET_ORDERS,
  Order,
  Orders,
  OrderAction,
  SEARCH_ORDERS,
} from "./types";

export const getOrders = (orders: Orders): OrderAction => {
  return {
    type: GET_ORDERS,
    payload: orders,
  };
};

export const getNewOrders = (orders: Orders) => {
  return {
    type: SEARCH_ORDERS,
    payload: orders,
  };
};

export const addOrder = (order: Order): OrderAction => {
  return {
    type: ADD_ORDER,
    payload: order,
  };
};
