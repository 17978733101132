import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      minHeight: "46px",
    },
    title: {
      flex: "1 1 100%",
      color: theme.palette.common.black,
      lineHeight: "22px",
    },
    searchIcon: {
      marginLeft: `${theme.spacing(2)}px !important`,
      background: `${theme.palette.primary.main} !important`,
      color: theme.palette.common.white,
      height: "32px !important",
      width: "32px !important",
      borderRadius: "5px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px",
      },
      padding: theme.spacing(0.5),
      cursor: "pointer",
    },
    searchContainer: {
      marginTop: 10,
      height: "32px !important",
      width: "416px",
      marginBottom: theme.spacing(2),
      borderRadius: "10px !important",
    },
  })
);
