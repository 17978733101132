import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-hot-toast";

import StatCard from "../components/statCard";
import { getAdminStats } from "../services/api/requests";
import AddOrgForm from "./components/addOrgForm";
import AssignServiceProvider from "./components/assignServiceProviderForm";
import AddUser from "./components/addUserForm";
import DeleteOrgForm from "./components/deleteOrgForm";
import DeleteUser from "./components/deleteUserForm";
import DataLookupTable from "./components/dataLookupTable";
import AssignUserForm from "./components/assignUserForm";

type AdminMetrics = {
  serviceProviders: number;
  pharmacies: number;
  delivered: number;
};

const AdminContainer: React.FC<{}> = (props) => {
  const [metrics, setMetrics] = useState<AdminMetrics>({
    serviceProviders: 0,
    pharmacies: 0,
    delivered: 0,
  });

  // Will load initial metrics for weel admins
  useEffect(() => {
    getAdminStats()
      .then((res) => {
        setMetrics({
          serviceProviders: res.data.serviceProviders,
          pharmacies: res.data.pharmacies,
          delivered: res.data.totaldeliveries,
        });
      })
      .catch((err) => {
        toast.error("Failed to fetch the stats!");
      });
  }, []);

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <DataLookupTable />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatCard label="Service Providers" stat={metrics.serviceProviders} />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatCard label="Pharmacies" stat={metrics.pharmacies} />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatCard label="Deliveries Completed" stat={metrics.delivered} />
      </Grid>
      <Grid item xs={12}>
        <AddOrgForm />
      </Grid>
      <Grid item xs={12}>
        <AddUser />
      </Grid>
      <Grid item xs={12} md={6}>
        <AssignServiceProvider />
      </Grid>
      <Grid item xs={12} md={6}>
        <AssignUserForm />
      </Grid>

      <Grid item xs={12} md={6}>
        <DeleteOrgForm />
      </Grid>
      <Grid item xs={12} md={6}>
        <DeleteUser />
      </Grid>
    </Grid>
  );
};

export default AdminContainer;
