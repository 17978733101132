// App.tsx
import React, { useEffect } from "react";

declare global {
  interface Window {
    initMap: () => void;
  }
}
const map_api_key = process.env.REACT_APP_MAP_API_KEY
const citymap: Record<
  string,
  { center: google.maps.LatLngLiteral; population: number }
> = {
  chicago: {
    center: { lat: 41.878, lng: -87.629 },
    population: 2714856,
  },
  newyork: {
    center: { lat: 40.714, lng: -74.005 },
    population: 8405837,
  },
  losangeles: {
    center: { lat: 34.052, lng: -118.243 },
    population: 3857799,
  },
  vancouver: {
    center: { lat: 49.25, lng: -123.1 },
    population: 603502,
  },
};

const MapComponent: React.FC = () => {
  useEffect(() => {
    const initMap = () => {
      // Create the map.
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 4,
          center: { lat: 37.09, lng: -95.712 },
          mapTypeId: "terrain",
        }
      );

      // Construct the circle for each value in citymap.
      for (const city in citymap) {
        new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map,
          center: citymap[city].center,
          radius: Math.sqrt(citymap[city].population) * 100,
        });
      }
    };

    // Load the Google Maps script
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${map_api_key}&callback=initMap`;
    script.defer = true;
    script.async = true;
    window.initMap = initMap;
    document.head.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  return <div id="map" style={{ height: "100vh", width: "100%" }} />;
};

export default MapComponent;
