import axios from 'axios'

type AddressObj = {
  id: string,
  address: string, 
  lat: number, 
  long: number, 
  dist: number
}

// getPlaces will use the search API to find your address results. 
export const getPlaces = async (query: string, lat: number, long: number) => {
  const baseUrl = 'https://api.tomtom.com/search/2/search';
  const queryString = `limit=10&countrySet=CA&lat=${lat}&lon=${long}&extendedPostalCodesFor=Addr&idxSet=PAD,Addr&key=${process.env.REACT_APP_TOM_TOM_API_KEY}`;
  const searchRes = await axios.get(`${baseUrl}/${query}.json?${queryString}`);
  const addresses: Array<AddressObj> = []; 

  searchRes.data.results.forEach(res => {
    const addressObj: AddressObj = {id: "", address: "", lat: 0, long: 0, dist: 0};
    addressObj.id = res.id;
    addressObj.address = res.address.freeformAddress + ", " + res.address.country; 
    addressObj.lat = res.position.lat;
    addressObj.long = res.position.lon;
    addressObj.dist = res.dist; 

    addresses.push(addressObj);
  });

  return addresses;
}
