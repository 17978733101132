import React from "react";
import {
  Paper,
  FormControl,
  FormGroup,
  FormControlLabel,
  Box,
  Checkbox,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { FormikProps } from "formik";
import { FormikCreateOrder } from "../types";

type Tag = { id: number; name: string };

type AddDeliveryTagsProps = {
  formik: FormikProps<FormikCreateOrder>;
  tags: Array<Tag>;
};

const AddDeliveryTags: React.FC<AddDeliveryTagsProps> = (props) => {
  const { formik, tags } = props;

  const classes = useStyles();

  const addOrRemoveTags = (tag) => {
    const currentTags = formik.values.order.tags;
    const tagId = tag.id;
    if (currentTags.includes(tagId)) {
      // Remove the tag ID from the array
      formik.setFieldValue(
        "order.tags",
        currentTags.filter((value) => value !== tagId)
      );
    } else {
      // Add the tag ID to the array
      formik.setFieldValue("order.tags", [...currentTags, tagId]);
    }
  };

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.body}>
          <Box className={classes.content}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {tags &&
                  tags.map((tag, idx) => (
                    <FormControlLabel
                      key={`delivery-tag-${idx}`}
                      className={classes.formGroup}
                      classes={{ label: classes.label }}
                      control={
                        <Checkbox
                          onChange={() => {
                            addOrRemoveTags(tag);
                          }}
                          checked={formik.values.order.tags.includes(tag.id)}
                          className={classes.checkbox}
                        />
                      }
                      label={tag.name}
                      value={tag.id}
                    />
                  ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default AddDeliveryTags;
