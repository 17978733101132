import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stack: {
      marginBottom: theme.spacing(5),
    },
    paper: {
      width: "100%",
      borderRadius: "5px !important",
    },

    title: {
      marginTop: "0px !important",
      lineHeight: "22px !important",
      fontWeight: 700,
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },
    body: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(3.5),
    },

    textField: {
      borderRadius: 5,
      "& .MuiOutlinedInput-root": {
        borderRadius: 5,
      },
    },
    subtitle: {
      marginRight: `${theme.spacing(2)}px !important`,
    },
    subtitleContainer: {
      marginBottom: theme.spacing(2),
      flexDirection: "row",
      display: "flex",
      alignItems: "flex-end",
    },
    line: {
      flex: "1 1 auto",
      height: 1,
      background: "#CFCFCF",
    },
    gridContainer: {
      marginBottom: theme.spacing(2),
    },
    radioGroup: {
      marginLeft: theme.spacing(1.2),
      marginBottom: theme.spacing(2),
    },
    createOrder: {
      whiteSpace: "nowrap",
      width: "157.09px",
      "text-transform": "capitalize !important",
      boxShadow: "none",
    },
    label: {
      color: theme.palette.common.black,
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      lineHeight: "22px",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    select: {
      "&:focus": {
        backgroundColor: "inherit",
      },
    },

    addIcon: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
      background: theme.palette.secondary.main,
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
    deleteIcon: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
      background: theme.palette.error.main,
      "&:hover": {
        background: theme.palette.error.main,
      },
    },
    addressText: {
      lineHeight: "22px !important",
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      marginBottom: theme.spacing(1),
    },
    addressContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    notesMargin: {
      marginTop: "1.1rem",
    },
    unitBox: {
      pointerEvents: "none",
      marginRight: "0.5rem",
    },
    searchBox: {
      position: "relative",
    },
    searchIcon: {
      position: "absolute",
      top: ".9rem",
      right: "1rem",
      color: "#3C3C3C",
    },
    textArea: {
      padding: 5,
      width: "98%",
      outline: 0,
      borderColor: "#cac2c2",
      borderRadius: 5,
      "& .MuiOutlinedInput-root": {
        borderRadius: 5,
      },
    },
  })
);
