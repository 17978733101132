import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Paper, Button } from "@material-ui/core";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { useFormik, FormikProps } from "formik";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CreateNewBulk from "./createNewBulk";
import { useStyles as styles } from "./styles";
import { createOrder } from "../store/orders/middleware";
import { FormikCreateOrder } from "./types";
import { RootState } from "../store/types";

const BulkActionCreate: React.FC = () => {
  const classes = styles();

  const nav = useNavigate();

  const dispatch = useDispatch();

  const org = useSelector((state: RootState) => state.organization);

  const formik: FormikProps<FormikCreateOrder> = useFormik<FormikCreateOrder>({
    initialValues: {
      customer: {
        customer_id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        extension: 0,
        notes: "",
        fullAddress: [
          {
            unit: "",
            latitude: 0,
            longitude: 0,
            address: "",
            preffered: false,
          },
        ],
        addressKey: "",
      },
      order: {
        status: "ready",
        type: "",
        delivery_date: "",
        delivery_preference: "",
        delivery_preference_start: "",
        delivery_preference_end: "",
        amount: 0.0,
        recurrence: "",
        notes: "",
        boxes: 0,
        submitted_by: "",
        tags: [],
        from_unit: "",
        from_address: "",
        from_latitude: 0,
        from_longitude: 0,
        to_unit: "",
        to_address: "",
        to_latitude: 0,
        estimated_distance: "",
        to_longitude: 0,
      },
    },
    onSubmit: (values) => {
      const { to_unit, to_longitude, to_address, to_latitude } = values.order;
      const { type } = values.order;
      const order = {
        from_unit: type === "pickup" ? to_unit : org.unit,
        from_address: type === "pickup" ? to_address : org.address,
        from_latitude: type === "pickup" ? to_latitude : org.lat,
        from_longitude: type === "pickup" ? to_longitude : org.long,
        to_unit: type === "delivery" ? to_unit : org.unit,
        to_address: type === "delivery" ? to_address : org.address,
        to_latitude: type === "delivery" ? to_latitude : org.lat,
        to_longitude: type === "delivery" ? to_longitude : org.long,
        delivery_preference_start:
          values.order.delivery_preference_start + ":00",
        delivery_preference_end: values.order.delivery_preference_end
          ? values.order.delivery_preference_end + ":00"
          : null,
      };
      dispatch(
        createOrder(
          Number(org.org_id),
          {
            customer_id: values.customer.customer_id,
            order: {
              ...values.order,
              ...order,
            },
          },
          nav
        )
      );
    },
  });

  useEffect(() => {
    if (
      formik.values.order.delivery_preference === "before" ||
      formik.values.order.delivery_preference === "after"
    ) {
      formik.setFieldValue("order.delivery_preference_end", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.order.delivery_preference]);

  const goToBulk = () => {
    nav("/bulk-actions");
  };

  return (
    <Box className={classes.root}>
      <Stack
        mb={2}
        display="flex"
        flexDirection="row"
        spacing={0}
        alignItems="center"
      >
        <NavigateBeforeIcon
          onClick={() => {
            goToBulk();
          }}
          className={classes.icon}
        />
        <Typography variant="h6" component="div" className={classes.heading}>
          Location Name
        </Typography>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <CreateNewBulk formik={formik} />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box className={classes.actions}>
            <Typography variant="h6" fontWeight={700}>
              Boxes
            </Typography>
            <Button
              fullWidth
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => {
                nav("/bulk-actions/create");
              }}
              className={classes.btnStyle}
            >
              Create Box
            </Button>
          </Box>
          <Paper className={classes.secondPaper} elevation={4}></Paper>
        </Grid>
      </form>
    </Box>
  );
};

export default BulkActionCreate;
