import {
  GET_ORG,
  UPDATE_ORG,
  OrgActions,
  Organization,
  ADD_TAG,
  DELETE_TAG,
} from "./types";

const initialOrgState: Organization = {
  org_name: "",
  unit: "",
  address: "",
  addressKey: "",
  lat: 0,
  long: 0,
  dist: 0,
  org_settings: {},
  phone_number: "",
  email: "",
  org_tags: [],
  date_created: "",
  org_id: "",
  ext: 0,
};

const organizations = (
  state: Organization = initialOrgState,
  action: OrgActions
): Organization => {
  const type = action?.type;

  switch (type) {
    case GET_ORG:
    case UPDATE_ORG:
      return Object.assign({}, state, action?.payload);
    case ADD_TAG:
      return {
        ...state,
        org_tags: [...state.org_tags, action.tag],
      };
    case DELETE_TAG:
      return {
        ...state,
        org_tags: state.org_tags.filter((tag) => tag !== action.tag),
      };
    default:
      return state;
  }
};

export default organizations;
