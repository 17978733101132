import { 
  USER_LOGIN, 
  ADD_USER, 
  User, 
  UserLogin, 
  AddUser, 
  UserLogout, 
  USER_LOGOUT 
} from './types'
 
export const login = (user: User): UserLogin => { 
  return {
    type: USER_LOGIN, 
    payload: user
  }
}

export const addUser = (user: User): AddUser => { 
  return {
    type: ADD_USER, 
    payload: user
  }
}

export const logout = (user: User): UserLogout => {
  return {
    type: USER_LOGOUT, 
    payload: user
  }
} 
