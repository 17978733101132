import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stack: {},
    paper: {
      width: "100%",
      borderRadius: "10px !important",
    },
    title: {
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      marginTop: "0px !important",
      color: theme.palette.common.black,
      lineHeight: "22px !important",
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      borderBottom: "1px solid #CFCFCF",
    },
    body: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(3.5),
      paddingTop: theme.spacing(4),
    },
    select: {
      "&:focus": {
        backgroundColor: "inherit",
      },
    },
    subtitle: {
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      marginRight: `${theme.spacing(2)}px !important`,
    },
    subtitleContainer: {
      marginBottom: theme.spacing(2),
      flexDirection: "row",
      display: "flex",
      alignItems: "flex-end",
    },
    line: {
      flex: "1 1 auto",
      height: 1,
      background: "#CFCFCF",
    },
    gridContainer: {
      marginBottom: theme.spacing(4),
    },
    radioGroup: {
      marginBottom: theme.spacing(2),
    },
    createOrder: {
      whiteSpace: "nowrap",
      width: "9.75rem",
      "text-transform": "capitalize !important",
    },
    deleteBtn: {
      whiteSpace: "nowrap",
      width: "9.75rem",
      color: theme.palette.common.white,
      backgroundColor: `#FE5A5A`,
      "text-transform": "capitalize ",
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
      },
      marginLeft: 11,
    },
    label: {
      color: theme.palette.common.black,
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      lineHeight: "22px",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    textField: {
      borderRadius: "5px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px",
      },
    },
    addIcon: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
      background: theme.palette.secondary.main,
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
    deleteIcon: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
      background: theme.palette.error.main,
      width: 30,
      height: 30,
      "&:hover": {
        background: theme.palette.error.main,
      },
    },
    addressText: {
      lineHeight: "22px !important",
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      marginBottom: theme.spacing(1),
    },
    addressContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      width: "100%",
    },
    addressSearchField: {
      fontSize: "13px",
    },
    addressErrorField: {
      padding: "-0.5px",
      margin: 0,
      fontSize: "0.75rem",
      textAlign: "left",
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
      marginTop: theme.spacing(2),
      outline: "solid 1px red",
      borderRadius: "4px",
    },
    errMessage: {
      marginTop: "4px",
      marginLeft: "14px",
      marginRight: "14px",
      color: "#FE5A5A",
      fontSize: "0.75rem",
    },
    subText: {
      color: "#CFCFCF",
      fontSize: "0.75rem",
      position: "absolute",
      right: 0,
      bottom: "-1.5rem",
    },
    textArea: {
      padding: 5,
      width: "98%",
      outline: 0,
      borderColor: "#cac2c2",
      borderRadius: 5,
      "& .MuiOutlinedInput-root": {
        borderRadius: 5,
      },
    },
  })
);
