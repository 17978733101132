import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    height: "76vh",
  },
  logo: {
    marginTop: 20,
    height: "59px",
    width: "59px",
  },
  gridContainer: {
    marginBottom: theme.spacing(0),
    width: "100%",
    textAlign: "center",
  },
  paper: {
    width: "100%",
    maxWidth: "350px",
    height: "500px",
    borderRadius: "5px !important",
    padding: 15,
    position: "relative",
  },
  buttonContainer: {
    position: "absolute",
    bottom: 20,
    left: "50%",
    transform: "translateX(-50%)",
    width: "50%",
  },
  switchBtn: {
    position: "absolute",
    bottom: 20,
    left: "50%",
    transform: "translateX(-50%)",
  },
  pharmaciesList: {
    marginTop: theme.spacing(4),
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  listContainer: {
    maxHeight: "310px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
    },
  },
  pharmacyItem: {
    width: "100%",
    height: "auto",
    backgroundColor: "white",
    border: "1px solid #CFCFCF",
    "&:hover": {
      backgroundColor: "#D9D9D980",
    },
    borderRadius: "5px",
    marginBottom: 20,
  },
  pharmacyTextName: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3C3C3C",
    width: "100%",
    textAlign: "center",
    textTransform: "capitalize",
  },
  pharmacyTextAddress: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#3C3C3C",
    width: "100%",
    textAlign: "center",
    textTransform: "capitalize",
  },
}));
