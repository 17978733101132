import React from "react";
import Chip from "@material-ui/core/Chip";
import { transformString } from "../../services/helper";

type StatusChipProps = {
  status: string;
};

const sanitizeStatus = (dirtyStatus: string): any => {
  const baseStyle = {
    fontSize: "0.75rem",
    fontWeight: "700",
    width: "6rem",
    height: "1.8rem",
    borderRadius: 5,
  };
  switch (dirtyStatus?.toLowerCase()) {
    case "ready":
      return (
        <Chip
          style={{
            backgroundColor: "#FFEF62",
            color: "#B28704",
            ...baseStyle,
          }}
          size="medium"
          label="Ready"
        />
      );
    case "cancelled":
      return (
        <Chip
          style={{
            backgroundColor: "#F27573",
            color: "#AA2E25",
            ...baseStyle,
          }}
          size="small"
          label="Cancelled"
        />
      );
    case "customer_declined":
      return (
        <Chip
          style={{
            backgroundColor: "#F27573",
            color: "#AA2E25",
            ...baseStyle,
          }}
          size="small"
          label="Declined"
        />
      );
    case "customer_absent":
      return (
        <Chip
          style={{
            backgroundColor: "#F27573",
            color: "#AA2E25",
            ...baseStyle,
          }}
          size="small"
          label="Absent"
        />
      );
    case "return":
      return (
        <Chip
          style={{
            backgroundColor: "#9C9AF9",
            color: "#4340E5",
            ...baseStyle,
          }}
          size="small"
          label="Enroute"
        />
      );
    case "delivered":
      return (
        <Chip
          style={{
            backgroundColor: "#D0E7B7",
            color: "#47824A",
            ...baseStyle,
          }}
          size="small"
          label="Delivered"
        />
      );
    case "started":
      return (
        <Chip
          style={{
            backgroundColor: "#72CFF8",
            color: "#3788AC",
            ...baseStyle,
          }}
          size="small"
          label={"Delivered"}
        />
      );
    default:
      return (
        <Chip
          style={{
            backgroundColor: "#ffef62",
            color: "#b28704",
            ...baseStyle,
          }}
          size="small"
          label={transformString(dirtyStatus)}
        />
      );
  }
};

const StatusChip: React.FC<StatusChipProps> = (props) => {
  const { status } = props;

  return sanitizeStatus(status);
};

export default StatusChip;
