import React from "react";
import {
  Paper,
  TextField,
  Box,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FormikProps } from "formik";
import { useStyles } from "./styles";
import { FormikPharmacy } from "../../types";

type ServiceAgreementProps = {
  formik: FormikProps<FormikPharmacy>;
  readOnly?: boolean;
};

const ServiceAgreement: React.FC<ServiceAgreementProps> = (props) => {
  const { formik, readOnly } = props;

  const classes = useStyles();

  const handleAdd = () => {
    formik.setFieldValue("zones", [
      ...formik.values.zones,
      {
        zone: "",
        start: "",
        end: "",
        rate: 0.0,
        unit: "",
      },
    ]);
  };

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div" className={classes.title}>
            Service Agreement
          </Typography>
        </Box>
        <Box className={classes.body}>
          <Box>
            <Box>
              <RadioGroup
                className={classes.radioGroup}
                row
                name="serviceAgreement"
                value={formik.values.serviceAgreement}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  classes={{ label: classes.label }}
                  value="zone"
                  control={<Radio />}
                  label="Zone"
                  disabled={readOnly}
                />
                <FormControlLabel
                  classes={{ label: classes.label }}
                  value="perDelivery"
                  control={<Radio />}
                  label="Per Delivery"
                  disabled={readOnly}
                />
              </RadioGroup>
            </Box>
            {formik.values["serviceAgreement"] === "perDelivery" && (
              <Box className={classes.priceContainer}>
                <TextField
                  style={{ width: 320 }}
                  variant="outlined"
                  size="small"
                  label="Price"
                  name="price"
                  fullWidth={true}
                  onChange={formik.handleChange}
                  value={formik.values.price}
                  disabled={readOnly}
                />
                <Typography variant="h6" className={classes.costPerDelivery}>
                  / Cost per delivery
                </Typography>
              </Box>
            )}
            {formik.values["serviceAgreement"] === "zone" && (
              <Box className={classes.container}>
                <Box className={classes.formOuterContainer}>
                  {formik.values.zones.map((_, idx) => (
                    <Box key={idx}>
                      <Box className={classes.formContainer}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Zone"
                          name={`zones[${idx}].zone`}
                          fullWidth={true}
                          onChange={formik.handleChange}
                          value={`${formik.values.zones[idx].zone}`}
                          disabled={readOnly}
                        />
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Start"
                          name={`zones[${idx}].start`}
                          fullWidth={true}
                          onChange={formik.handleChange}
                          value={`${formik.values.zones[idx].start}`}
                          disabled={readOnly}
                        />
                        <TextField
                          variant="outlined"
                          size="small"
                          label="End"
                          name={`zones[${idx}].end`}
                          fullWidth={true}
                          onChange={formik.handleChange}
                          value={`${formik.values.zones[idx].end}`}
                          disabled={readOnly}
                        />
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Rate"
                          name={`zones[${idx}].rate`}
                          fullWidth={true}
                          type="number"
                          onChange={formik.handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          value={`${formik.values.zones[idx].rate}`}
                          disabled={readOnly}
                        />
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="unit">Unit</InputLabel>
                          <Select
                            label="Unit"
                            labelId="unit"
                            name={`zones[${idx}].unit`}
                            fullWidth
                            onChange={formik.handleChange}
                            value={`${formik.values.zones[idx].unit}`}
                            disabled={readOnly}
                          >
                            <MenuItem value={"km"}>KM</MenuItem>
                            <MenuItem value={"mile"}>Mile</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box className={classes.iconContainer}>
                  <IconButton
                    className={classes.addIcon}
                    onClick={handleAdd}
                    color="secondary"
                    aria-label="add"
                    size="small"
                    disabled={readOnly}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              className={classes.saveBtn}
              type="submit"
              disabled={readOnly}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ServiceAgreement;
