import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import "./style.css";

interface Destination {
  lat: number;
  lng: number;
  label: string;
  address: string;
}

interface MapProps {
  apiKey: string;
  transportMode: "car" | "bike";
  start: { lat: number; lng: number };
  destinations: Destination[];
  showOverlay?: boolean;
  children?: React.ReactNode;
}

const DeliveryMap: React.FC<MapProps> = ({
  apiKey,
  transportMode,
  start,
  destinations,
  showOverlay = false,
  children,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [selectedDestination, setSelectedDestination] =
    useState<Destination | null>(null);
  const [isRightOverlayVisible, setIsRightOverlayVisible] = useState(true); // State to control right overlay visibility

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      script.defer = true;
      script.onload = initMap;
      document.head.appendChild(script);
    };

    const initMap = () => {
      const google = window.google;
      const map = new google.maps.Map(mapRef.current!, {
        zoom: 7,
        center: start,
      });

      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
      });

      const startIcon = {
        url:
          transportMode === "car"
            ? "https://maps.google.com/mapfiles/kml/shapes/cabs.png"
            : "https://maps.google.com/mapfiles/kml/shapes/cycling.png",
        scaledSize: new google.maps.Size(40, 40),
      };

      const intermediateIcon = {
        url: "https://maps.google.com/mapfiles/kml/paddle/blu-circle.png",
        scaledSize: new google.maps.Size(40, 40),
      };

      const homeIcon = {
        url: "https://maps.google.com/mapfiles/kml/shapes/homegardenbusiness.png",
        scaledSize: new google.maps.Size(40, 40),
      };

      new google.maps.Marker({ position: start, map: map, icon: startIcon });

      destinations.forEach((dest, index) => {
        const isLastDestination = index === destinations.length - 1;
        const marker = new google.maps.Marker({
          position: { lat: dest.lat, lng: dest.lng },
          map: map,
          icon: isLastDestination ? homeIcon : intermediateIcon,
          label: dest.label,
        });

        marker.addListener("click", () => {
          setSelectedDestination(dest);
        });
      });

      const waypoints = destinations.slice(0, -1).map((dest) => ({
        location: new google.maps.LatLng(dest.lat, dest.lng),
        stopover: true,
      }));

      const lastDestination = destinations[destinations.length - 1];

      directionsService.route(
        {
          origin: start,
          destination: new google.maps.LatLng(
            lastDestination.lat,
            lastDestination.lng
          ),
          waypoints: waypoints,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK" && response) {
            directionsRenderer.setDirections(response);
            const bounds = new google.maps.LatLngBounds();
            response.routes[0].overview_path.forEach((point) => {
              bounds.extend(point);
            });
            map.fitBounds(bounds);
          }
        }
      );

      // Add listener to toggle overlay visibility when clicking on the map
      google.maps.event.addListener(map, "click", () => {
        setIsRightOverlayVisible((prevState) => !prevState); // Toggle the right overlay on map click
      });
    };

    loadGoogleMapsScript();

    return () => {
      const script = document.querySelector(
        `script[src^="https://maps.googleapis.com/maps/api/js?key=${apiKey}"]`
      );
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [apiKey, transportMode, start, destinations]);

  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{ position: "relative", width: "100%", height: "600px" }}
    >
      <div ref={mapRef} style={{ height: "100%", width: "100%" }} />

      {/* Center children vertically and set one on the left and the other on the right */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          height: "80%", // Set height to 80% of the map's height
          width: "calc(100% - 20px)", // Adjust width to fit within the parent
          pointerEvents: "none", // Allow clicks to pass through to the map
          overflowY: "auto",
        }}
        className="no-scrollbar"
      >
        {children &&
          React.Children.map(children, (child, index) => {
            // Conditionally render the right child
            if (index === 1 && !isRightOverlayVisible) return null;
            return <div style={{ pointerEvents: "auto" }}>{child}</div>;
          })}
      </Grid>
    </Grid>
  );
};

export default DeliveryMap;
