import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
  },
  tab: {
    "&.Mui-selected": {
      backgroundColor: "#3c3c3c",
      color: "#FFFFFF",
    },
    "&.MuiTab-root": {
      minHeight: "1.875rem",
      lineHeight: "inherit",
    },
  },
  tabRoot: {
    height: "1.875rem",
    minHeight: "1.875rem",
  },
}));

export default useStyles;
