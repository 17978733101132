import {
  UserAccessList,
  ADD_USER_ACCESS,
  CHANGE_USER_ACCESS,
  AccessActionTypes,
} from "./types";

const initialState: UserAccessList = [];

export const currentUserAccess = (
  state: UserAccessList = initialState,
  action: AccessActionTypes
): UserAccessList => {
 const type: any = action?.type;
  switch (type) {
    case ADD_USER_ACCESS:
      return action.access;
    case CHANGE_USER_ACCESS:
      return action.access || state;
    default:
      return state;
  }
};
