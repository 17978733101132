// libraries
import React, { useEffect, useState } from "react";

// components
import StyledPhoneSearch from "../../../../../components/styledPhoneSearch";

// constants
import { get } from "../../../../../services/api/api";

type OrgSearchProps = {
  setData: (value: any) => void;
  currentValue: string;
  patients: object[];
};

export const OrganizationSearch: React.FC<OrgSearchProps> = (props) => {
  const { currentValue, setData } = props;
  const [query, setQuery] = useState(null);

  const onSearch = async () => {
    try {
      const response = await get(`api/v1/admin/lookup?phone_number=${query}`);
      setData(response?.data?.results || []);
    } catch (error) {
      setData([]);
    }
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!query) return;
      onSearch();
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <StyledPhoneSearch
      value={currentValue}
      handleChange={(text) => setQuery(text)}
    />
  );
};
