import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { toast } from "react-hot-toast";

import { useStyles } from "./styles";
import { addTag, deleteTag } from "../../../store/organizations/middleware";
import { Tag } from "../../../store/organizations/types";

type AddTagsProps = {
  orgId: string;
  tags: Array<Tag>;
};

const AddTags: React.FC<AddTagsProps> = (props) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [tagError, setTagError] = useState<boolean>(false);

  const { orgId, tags } = props;

  const formik = useFormik({
    initialValues: {
      newTag: "",
    },
    onSubmit: (values) => {
      if (values.newTag === "") {
        setTagError(true);
        toast.error("Tag cannot be empty.", {
          duration: 1000,
        });
        return;
      }

      dispatch(addTag(Number(orgId), values.newTag));
      formik.setFieldValue("newTag", "");
    },
  });

  const removeTag = (tag: Tag) => dispatch(deleteTag(Number(orgId), tag));
  return (
    <Paper className={styles.tagForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Tag Management</Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.tagListContainer}>
            {tags === null || tags.length === 0 ? (
              <div className={styles.noTagsMsg}>No Tags Found</div>
            ) : (
              tags?.map((tag, idx) => (
                <Chip
                  key={`tag-${idx}`}
                  className={styles.tag}
                  label={<Typography variant="body1"> {tag?.name}</Typography>}
                  onDelete={() => removeTag(tag)}
                  component="div" // Or specify the appropriate component type (e.g., "span", "button", etc.)
                />
              ))
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            className={styles.tagInput}
            id="tags"
            error={tagError}
            name="newTag"
            size="small"
            variant="outlined"
            placeholder="Enter a new tag..."
            fullWidth={true}
            value={formik.values.newTag}
            onChange={formik.handleChange}
            onClick={() => {
              if (tagError) {
                setTagError(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth={true}
            onClick={() => {
              formik.handleSubmit();
            }}
            disableFocusRipple={true}
            disableRipple={true}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddTags;
