import { useFormik } from "formik";
import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import cross from "../../assets/cross.svg";

import * as Yup from "yup";

import { TextField, Button, Grid, Typography, Box } from "@material-ui/core";
import StyledPhoneInput from "../../components/styledPhoneInput";

import useStyles from "./styles";

export default function AddUserModal({ open, handleClose, handleOpen }) {
  const classes = useStyles();

  const accountSchema = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    first_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Invalid first name"),
    last_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Invalid last name"),
    ext: Yup.number().optional(),
    email: Yup.string().email("Invalid email").required("Required"),
    phone_number: Yup.string()
      .required("Required")
      .matches(/^\d{11}$/, "Invalid phone number"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      ext: "",
      email: "",
      type: "",
    },
    onSubmit: () => {},
    enableReinitialize: true,
    validationSchema: accountSchema,
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: {
            backgroundColor: "#FFFFFF99",
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box className={classes.headerAlignment}>
              <Typography variant="subtitle1" className={classes.subtitleClass}>
                Add a New User Account
              </Typography>
              <Button className={classes.logo} onClick={handleClose}>
                <img src={cross} alt="Logo" />
              </Button>
            </Box>

            <Typography variant="body1" className={classes.textStyle}>
              Enter the details of the user you wish to add.
            </Typography>
            <Box style={{ marginRight: 16 }}>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    id="username"
                    name="username"
                    label="Username"
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    id="password"
                    name="password"
                    label="Password"
                    size="small"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
              </Grid>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                className={classes.gridContainer}
                container
                spacing={2}
                alignItems="center"
              >
                <Grid
                  item
                  style={{ lineHeight: "22px !important", marginTop: 8 }}
                  xs={6}
                >
                  <StyledPhoneInput
                    formik={formik}
                    fieldName="phone_number"
                    style={{ width: "100%" }}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <span className={classes.errorStyle}>
                      {formik.errors.phone_number}
                    </span>
                  ) : null}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    name="pharmacist_account.ext"
                    label="Ext"
                    value={formik.values.ext}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                    type="number"
                    error={formik.touched.ext && !!formik.errors.ext}
                    helperText={formik.touched.ext && formik.errors.ext}
                  />
                </Grid>
              </Grid>
              <Grid
                className={classes.gridContainer}
                container
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    label="Email"
                    name="pharmacist_account.email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginTop: 12 }}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="type-outlined-label">Type</InputLabel>
                    <Select
                      required={true}
                      label="Type"
                      labelId="type-outlined-label"
                      defaultValue={"driver"}
                      value={formik.values.type}
                      name="type"
                      onChange={formik.handleChange}
                    >
                      <MenuItem key={3} value={"driver"}>
                        Driver
                      </MenuItem>
                      <MenuItem key={5} value={"pharmacy_owner"}>
                        Pharmacy Owner
                      </MenuItem>
                      <MenuItem key={5} value={"pharmacy_employee"}>
                        Pharmacy Employee
                      </MenuItem>
                      <MenuItem key={3} value={"courier_admin"}>
                        Courier Admin
                      </MenuItem>
                      <MenuItem key={3} value={"weel_admin"}>
                        Weel Admin
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.btnGrid}>
                <Button
                  fullWidth={true}
                  style={{ width: "50%" }}
                  onClick={handleOpen}
                  disableFocusRipple={true}
                  disableRipple={true}
                >
                  Add User
                </Button>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
