import { makeStyles } from "@material-ui/core/styles";

export const useStyles = (props) =>
  makeStyles((theme) => ({
    bar: {
      background: "#FFFFFF",
      boxShadow: "none",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // zIndex: 10000,
      position: "static",
      top: 0,
    },
    menu: {
      backgroundColor: "#f5f5f5",
    },
  }));
