import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            margin: "auto",
        },
        gridContainer: {
            marginBottom: theme.spacing(0),
            display: "flex",
            width: "100%",
        },
        gridTags: {
            marginBottom: theme.spacing(0),
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "baseline",
            marginLeft: "7px",
        },
        textField: {
            lineHeight: "22px !important",
            borderRadius: "5px",
            "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
            },
            "font-weight": `${theme.typography.fontWeightBold} !important`,
            marginBottom: theme.spacing(1),
            marginTop: 0,
        },
        stack: {
            marginBottom: theme.spacing(3),
        },
        paper: {
            width: "100%",
            borderRadius: "10px !important",
            marginBottom: 30,
        },
        title: {
            marginTop: "0px !important",
            color: "#3C3C3C",
            fontWeight: 700,
        },
        subTitle: {
            marginLeft: 5,
            "font-weight": `${theme.typography.fontWeightBold} !important`,
            marginTop: "0px !important",
            color: "#CFCFCF",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px !important",
        },
        underline: {
            marginTop: 10,
            bottom: 0,
            left: 0,
            width: "100%",
            height: "1px",
            background: "#CFCFCF",
        },
        header: {
            paddingLeft: theme.spacing(1.5),
            padding: "12px 12px 18px 12px",
            display: "flex",
            alignItems: "center",
        },
        body: {
            paddingBottom: theme.spacing(3.5),
            paddingTop: theme.spacing(2),
        },
        line: {
            flex: "1 1 auto",
            height: 1,
            background: "#CFCFCF",
        },
        createDriver: {
            whiteSpace: "nowrap",
            width: "420px",
            height: "60px",
            borderRadius: "10px !important",
            color: theme.palette.common.white,
            // backgroundColor: `${theme.palette.secondary.main} !important`,
            backgroundColor: "#3C3C3C",
            "font-weight": "bold !important",
            "text-transform": "capitalize !important",
            "&:hover": {
                backgroundColor: "#3C3C3C",
                //backgroundColor: theme.palette.secondary.light,
            },
            fontSize: "20px !important",
        },
        deleteBox: {
            color: theme.palette.common.white,
            background: theme.palette.error.main,
            "&:hover": {
                background: theme.palette.error.main,
            },
            textAlign: "center",
            borderRadius: 10,
            padding: 5,
            width: "50px",
        },
        deleteIcon: {
            color: theme.palette.common.white,
            marginLeft: theme.spacing(2),
            //background: theme.palette.error.main,
            "&:hover": {
                background: theme.palette.error.main,
            },
        },
        addressSearchField: {
            fontSize: "13px",
            marginTop: theme.spacing(2),
        },
        addressErrorField: {
            padding: "-0.5px",
            margin: 0,
            fontSize: "0.75rem",
            textAlign: "left",
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
            marginTop: theme.spacing(2),
            outline: "solid 1px red",
            borderRadius: "4px",
        },
        errMessage: {
            marginTop: "4px",
            marginLeft: "14px",
            marginRight: "14px",
            color: "#FE5A5A",
            fontSize: "0.75rem",
        },
        label: {
            color: theme.palette.common.black,
            "font-weight": `${theme.typography.fontWeightBold} !important`,
            lineHeight: "22px",
        },
        logo: {
            height: "70px",
            width: "70px",
            margin: "25px auto",
            alignSelf: "flex-start",
        },
        addPharmacy: {
            whiteSpace: "nowrap",
            "font-weight": "bold !important",
            "text-transform": "capitalize !important",
            height: "30px",
        },
        errorCtnr: {
            marginTop: "15px",
            width: "80%",
            backgroundColor: theme.palette.error.light,
            color: "#ffffff",
            fontSize: "12px",
            padding: "15px",
            height: "20px",
            textAlign: "center",
            borderRadius: "10px",
        },

        startText: {
            fontWeight: theme.typography.fontWeightRegular as
                | "normal"
                | "bold"
                | number,
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(1.25),
        },
        createOrder: {
            whiteSpace: "nowrap",
            width: "420px",
            "font-weight": "bold !important",
            "text-transform": "capitalize !important",
        },
        deleteBtn: {
            whiteSpace: "nowrap",
            width: "420px",
            "text-transform": "capitalize ",
            marginTop: "15px",
        },
        belowBox: {
            marginTop: "2.313rem",
        },
        detailText: {
            color: "#3C3C3C",
        },
        textStyle: {
            marginTop: 3,
        },
        subtitleClass: {
            color: "#3C3C3C",
            fontWeight: 700,
        },
        card: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            padding: "0.9375rem",
            borderRadius: "0.625rem",
            paddingBottom: "2rem",
            background: "#FFF",
            boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            marginBottom: 30,

        },
        flexClass: {
            display: "flex",
            justifyContent: "space-between",
        },
        btnStyle: {
            backgroundColor: "transparent !important",
            width: "auto",
            "&:hover": {
                backgroundColor: theme.palette.secondary.light,
            },
        },
    })
);

export default useStyles;
