import { addPatient } from "./actions";
import { get, post } from "../../services/api/api";
import { toast } from "react-hot-toast";

export const fetchPatients =
  (org_id: string, offset: number) => (dispatch: any) => {
    get(
      `/api/v1/organizations/${Number(org_id)}/patients?offset=${offset}`
    ).then((resp) => {});
    return;
  };

export const addNewPatient = (patientInfo, orgId: any, nav) => {
  return async (dispatch: any) => {
    try {
      const url = `/api/v1/organizations/${Number(orgId)}/patients`;
      await post(url, { ...patientInfo });

      dispatch(addPatient(patientInfo));
      toast.success("Successfully created new customer!");
      nav("/customers");
    } catch (err) {
      const error = err as Error;
      toast.error(
        "Something went wrong while creating new customer: " + error.message
      );
      console.error(err);
    }
  };
};
