import React from "react";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { OrgStaff } from "../../";
import { useStyles } from "./styles";
import {
  ORG_ADMIN,
  DRIVER,
  COURIER_ADMIN,
  PHARMACY_EMPLOYEE,
} from "../../../services/roles";
import StyledPhoneSearch from "../../../components/styledPhoneSearch";
import { addStaffMember } from "../../../services/api/requests";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/types";

type ManageStaffProps = {
  staff: Array<OrgStaff>;
};

const ManageStaff: React.FC<ManageStaffProps> = (props: ManageStaffProps) => {
  const classes = useStyles();
  const org = useSelector((state: RootState) => state.organization);

  const { staff } = props;

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      role: "",
      first_name: "",
      last_name: "",
      phone_number: null,
      email: "",
    },
    onSubmit: async (values) => {
      try {
        await addStaffMember(org.org_id, values);
      } catch (error) {}
    },
  });

  return (
    <Paper className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Staff Management</Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.staffList}>
            {staff === null || staff.length === 0 ? (
              <Typography variant="h6" className={classes.noStaffMsg}>
                No Staff
              </Typography>
            ) : (
              staff.map((staffMember) => (
                <Chip
                  className={classes.member}
                  label={staffMember.username}
                  onDelete={() => console.log(staffMember)}
                />
              ))
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Add New User</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="add-staff-username"
            variant="outlined"
            size="small"
            label="Username"
            name="username"
            fullWidth={true}
            value={formik.values.username}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="add-staff-password"
            variant="outlined"
            size="small"
            label="Password"
            name="password"
            fullWidth={true}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            id="add-staff-role-select"
            label="Role"
            name="role"
            fullWidth={true}
            value={formik.values.role}
            onChange={formik.handleChange}
          >
            <MenuItem value={DRIVER}>Driver</MenuItem>
            <MenuItem value={ORG_ADMIN}>Pharmacy Owner</MenuItem>
            <MenuItem value={PHARMACY_EMPLOYEE}>Pharmacy Employee</MenuItem>
            <MenuItem value={COURIER_ADMIN}>Courier Admin</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="add-staff-firstname"
            variant="outlined"
            size="small"
            label="First Name"
            name="first_name"
            fullWidth={true}
            value={formik.values.first_name}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="add-staff-lastname"
            variant="outlined"
            size="small"
            label="Last Name"
            name="last_name"
            fullWidth={true}
            value={formik.values.last_name}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="add-staff-email"
            variant="outlined"
            size="small"
            label="Email"
            name="email"
            fullWidth={true}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <TextField
            id="add-staff-phone"
            variant="outlined"
            size="small"
            label="Phone Number"
            name="phoneNumber"
            fullWidth={true}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
          /> */}
          <StyledPhoneSearch
            value={formik.values.phone_number}
            handleChange={(value) =>
              formik.setFieldValue("phone_number", Number(value))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth={true}
            onClick={() => {
              formik.handleSubmit();
            }}
            disableFocusRipple={true}
            disableRipple={true}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            className={classes.clearBtn}
            fullWidth={true}
            onClick={() => {
              formik.handleSubmit();
            }}
            disableFocusRipple={true}
            disableRipple={true}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManageStaff;
