import React from "react";
import { Navigate } from "react-router-dom";

type ProtectedRouteProps = {
  authenticated: boolean;
  children: JSX.Element;
};

const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
  const { authenticated, children } = props;

  if (!authenticated) {
    return <Navigate to="/login" replace state={{ loginErr: false }} />;
  }

  return children;
};

export default ProtectedRoute;
