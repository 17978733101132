import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: "5px !important",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "230px",
      },
    },
    title: {
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      marginTop: "0px !important",
      color: theme.palette.common.black,
      lineHeight: "22px !important",
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },
    body: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(5),
      overflow: "auto",
    },
    content: {
      height: "calc(160px + 60px)",
    },
    label: {
      fontSize: ".875rem",
      color: "#3C3C3C",
      lineHeight: "1.375rem",
      fontWeight: 700,
    },
    checkbox: {
      marginRight: 11,
      width: 10,
      height: 10,
    },
    radioBox: {
      display: "flex",
    },
    formGroup: {
      marginTop: 8,
    },
  })
);
