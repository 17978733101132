import { useFormik, FormikProps } from "formik";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Typography,
  Radio,
  IconButton,
  TextareaAutosize,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import { Stack } from "@mui/material";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { AddressSearch } from "../../components/addressSearch";
import { addNewPatient } from "../../store/patients/middleware";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyledPhoneSearch from "../../components/styledPhoneSearch";

type CreateNewPatientProps = {
  orgId: string;
};

const CreateNewPatient: React.FC<CreateNewPatientProps> = (props) => {
  const { orgId } = props;

  const nav = useNavigate();

  const dispatch = useDispatch();
  const [addressError, setAddressError] = useState(true);

  useEffect(() => {
    let isMounted = true; // Add a boolean flag to track the mounted state
    window.navigator.geolocation.getCurrentPosition((pos) => {
      if (isMounted) {
        // Check if the component is still mounted before updating the state
      }
    });
    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const transformedValues = {
        first_name: values.first_name,
        last_name: values.last_name,
        addresses: values.addresses,
        email: values.email,
        phone_number: parseInt(values.phone_number),
        ext: parseInt(values.ext),
        notes: values.notes,
      };
      dispatch(addNewPatient(transformedValues, orgId, nav));
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const schema = Yup.object().shape({
    first_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "First name should contain only letters"),
    last_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Last name should contain only letters"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone_number: Yup.string()
      .required("Required")
      .matches(/^\+?[0-9]{8,}$/, "Invalid phone number"),
    ext: Yup.number()
      .integer("Extension should be an integer")
      .nullable()
      .optional(),
    addresses: Yup.array()
      .of(
        Yup.object().shape({
          unit: Yup.string().optional(),
          address: Yup.string().optional(),
          lat: Yup.number().required("Lat is required"),
          long: Yup.number().required("Long is required"),
          preferred: Yup.boolean().required("Preferred is required"),
        })
      )
      .min(1, "At least one address is required"),
    notes: Yup.string().nullable().optional(),
  });

  const formik: FormikProps<any> = useFormik<any>({
    initialValues: {
      first_name: "",
      last_name: "",
      addresses: [
        {
          unit: "",
          address: "",
          lat: 0,
          long: 0,
          preferred: true,
        },
      ],
      email: "",
      phone_number: "",
      ext: null,
      notes: "",
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: schema,
  });

  const classes = useStyles();

  const handleAddressSelect = (address: any, idx: number) => {
    if (address) {
      setAddressError(false);
    }
    formik.setFieldValue(`addresses[${idx}].address`, address.address);
    formik.setFieldValue(`addresses[${idx}].lat`, address.lat);
    formik.setFieldValue(`addresses[${idx}].long`, address.long);
  };

  const handleChangeRadio = (idx) => {
    const fullAddressState = formik.values.addresses.map((address) => {
      return {
        ...address,
        preferred: false,
      };
    });
    fullAddressState[idx].preferred = true;

    formik.setFieldValue("addresses", fullAddressState);
  };

  const handleDelete = (idx) => {
    const fullAddressState = formik.values.addresses.filter((_, i) => {
      return i !== idx;
    });
    if (formik.values.addresses[idx].preferred === true) {
      fullAddressState[0].preferred = true;
    }
    formik.setFieldValue("addresses", fullAddressState);
  };

  const handleAdd = () => {
    if (formik.values.addresses.length < 3) {
      formik.setFieldValue("addresses", [
        ...formik.values.addresses,
        {
          unit: "",
          preferred: false,
          address: "",
          lat: 0,
          long: 0,
        },
      ]);
      return;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div" className={classes.title}>
            New Customers
          </Typography>
        </Box>
        <Box className={classes.body}>
          <Stack
            className={classes.stack}
            display="flex"
            flexDirection="column"
          >
            <Grid className={classes.gridContainer} container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  label="First Name"
                  name="first_name"
                  onChange={formik.handleChange}
                  required
                  fullWidth
                  margin="normal"
                  error={
                    formik.touched.first_name && !!formik.errors.first_name
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  name="last_name"
                  label="Last Name"
                  onChange={formik.handleChange}
                  required
                  fullWidth
                  margin="normal"
                  error={formik.touched.last_name && !!formik.errors.last_name}
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                />
              </Grid>
            </Grid>
            <TextField
              className={classes.textField}
              variant="outlined"
              size="small"
              name="email"
              label="Email"
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              required
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Grid className={classes.gridContainer} container spacing={2}>
              <Grid item xs={9} className={classes.phoneContainer}>
                <StyledPhoneSearch
                  value={formik.values.phone_number}
                  handleChange={(value) =>
                    formik.setFieldValue("phone_number", value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  name="ext"
                  label="Ext"
                  onChange={formik.handleChange}
                  fullWidth
                  margin="normal"
                  error={formik.touched.ext && !!formik.errors.ext}
                  helperText={
                    formik.touched.ext && formik.errors.ext && "Required"
                  }
                />
              </Grid>
            </Grid>
            <div>
              {formik.values.addresses.map((_, idx) => (
                <div
                  key={idx}
                  style={{ marginBottom: 12 }}
                  className={classes.addressContainer}
                >
                  <Radio
                    checked={
                      formik.values.addresses[idx].preferred === true
                        ? true
                        : false
                    }
                    onChange={() => {
                      handleChangeRadio(idx);
                    }}
                    style={{ marginRight: 12, padding: 0 }}
                    value={formik.values.addresses[idx].preferred}
                    name={`location[${idx}].preferred`}
                  />
                  <div style={{ width: 125, flexShrink: 0, paddingRight: 8 }}>
                    <TextField
                      className={classes.textField}
                      name={`addresses[${idx}].unit`}
                      type="text"
                      size="small"
                      error={false}
                      label="Unit"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.addresses[idx].unit}
                    />
                  </div>

                  <Box
                    className={
                      formik.touched.addresses && !!formik.errors.addresses
                        ? classes.addressErrorField
                        : classes.addressSearchField
                    }
                  >
                    <AddressSearch
                      onAddressSelect={(value) =>
                        handleAddressSelect(value, idx)
                      }
                      currentAddress={formik.values?.addresses[idx]?.address}
                      lat={formik.values?.addresses[idx]?.lat}
                      long={formik.values?.addresses[idx]?.long}
                    />
                  </Box>
                  {formik.touched.addresses && !!formik.errors.addresses && (
                    <div className={classes.errMessage}>
                      {formik.touched.addresses && formik.errors.addresses}
                    </div>
                  )}

                  {formik.values.addresses.length > 1 && (
                    <IconButton
                      className={classes.deleteIcon}
                      onClick={() => {
                        handleDelete(idx);
                      }}
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}
              {formik.values.addresses.length < 3 && (
                <Box className={classes.iconContainer}>
                  <IconButton
                    className={classes.addIcon}
                    onClick={handleAdd}
                    color="secondary"
                    aria-label="add"
                    size="small"
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              )}
            </div>

            <TextareaAutosize
              className={classes.textArea}
              name="notes"
              placeholder="Notes"
              minRows={3}
              onChange={formik.handleChange}
            />
          </Stack>
          <Box display="flex" justifyContent="center">
            <Button
              size="small"
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              className={classes.createPatient}
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting || addressError}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Paper>
    </form>
  );
};

export default CreateNewPatient;
