import { useFormik } from "formik";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { GeoLocationType } from "../../ordersCreate/patientLookup/types";
import { AddressSearch } from "../../components/addressSearch";
import { addNewPharmacy } from "../../store/pharmacy/middleware";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyledPhoneInput from "../../components/styledPhoneInput";

type CreateNewPharmacyProps = {
  orgId: string;
};

const CreateNewPharmacy: React.FC<CreateNewPharmacyProps> = (props) => {
  const { orgId } = props;

  const nav = useNavigate();

  const [geoLocation, setGeoLocation] = useState<GeoLocationType>({
    lat: 0,
    long: 0,
  });

  const dispatch = useDispatch();

  const goPharmacies = () => {
    nav("/pharmacies");
  };

  useEffect(() => {
    let isMounted = true; // Add a boolean flag to track the mounted state
    window.navigator.geolocation.getCurrentPosition((pos) => {
      if (isMounted) {
        // Check if the component is still mounted before updating the state
        setGeoLocation({
          lat: pos.coords.latitude,
          long: pos.coords.longitude,
        });
      }
    });
    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, []);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      dispatch(addNewPharmacy(values, orgId));
      goPharmacies();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const mainSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Name should contain only letters"),
    phoneNumber: Yup.string()
      .required("Required")
      .matches(/^\d{11}$/, "Invalid phone number"),
    ext: Yup.number().optional(),
    email: Yup.string().email("Invalid email").required("Required"),
    unit: Yup.string().optional(),
    address: Yup.string().required("Required"),
    latitude: Yup.number().required("Required"),
    longitude: Yup.number().required("Required"),
  });

  const accountSchema = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    first_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Invalid first name"),
    last_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Invalid last name"),
    ext: Yup.number().optional(),
    email: Yup.string().email("Invalid email").required("Required"),
    phone_number: Yup.string()
      .required("Required")
      .matches(/^\d{11}$/, "Invalid phone number"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      ext: "",
      email: "",
      unit: "",
      address: "",
      latitude: 0,
      longitude: 0,
      pharmacist_account: {
        username: "",
        password: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        ext: "",
        email: "",
      },
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      ...mainSchema.fields,
      pharmacist_account: accountSchema,
    }),
  });

  const classes = useStyles();
  const handleAddressSelect = (address: any) => {
    formik.setFieldValue("address", address.address);
    formik.setFieldValue("lat", address.lat);
    formik.setFieldValue("long", address.long);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div" className={classes.title}>
            Create a new Pharmacy
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.body}>
          <Grid
            className={classes.gridContainer}
            container
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={5}>
              <Typography
                variant="h6"
                component="div"
                className={classes.subTitle}
              >
                Pharmacy Information
              </Typography>
            </Grid>
          </Grid>
          <Stack
            className={classes.stack}
            display="flex"
            flexDirection="column"
          >
            <TextField
              className={classes.textField}
              variant="outlined"
              size="small"
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              alignItems="center"
            >
              <Grid
                item
                style={{ lineHeight: "22px !important", marginTop: 8 }}
                xs={5}
                md={5}
              >
                <StyledPhoneInput formik={formik} fieldName="phoneNumber" />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <span
                    style={{
                      marginTop: "4px",
                      marginLeft: "14px",
                      marginRight: "14px",
                      color: "#FE5A5A",
                      fontSize: "0.75rem",
                    }}
                  >
                    {formik.errors.phoneNumber}
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={3} md={3}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  name="ext"
                  label="Ext"
                  value={formik.values.ext}
                  onChange={formik.handleChange}
                  fullWidth
                  type="number"
                  margin="normal"
                  error={formik.touched.ext && !!formik.errors.ext}
                  helperText={
                    formik.touched.ext && formik.errors.ext && "Required"
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                className={classes.textField}
                variant="outlined"
                size="small"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid className={classes.gridContainer} container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  name="unit"
                  value={formik.values.unit}
                  onChange={formik.handleChange}
                  label="Unit"
                  fullWidth
                  margin="normal"
                  error={formik.touched.unit && !!formik.errors.unit}
                  helperText={formik.touched.unit && formik.errors.unit}
                />
              </Grid>
              <Grid item xs={9}>
                <Box
                  className={
                    formik.touched.address && !!formik.errors.address
                      ? classes.addressErrorField
                      : classes.addressSearchField
                  }
                >
                  <AddressSearch
                    onAddressSelect={handleAddressSelect}
                    currentAddress={formik.values.address}
                    lat={geoLocation.lat}
                    long={geoLocation.long}
                  />
                </Box>
                {formik.touched.address && !!formik.errors.address && (
                  <div className={classes.errMessage}>
                    {formik.touched.address && formik.errors.address}
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  component="div"
                  className={classes.subTitle}
                >
                  Admin Details
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.gridContainer} container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  className={classes.textField}
                  id="pharmacist_account.username"
                  name="pharmacist_account.username"
                  label="Username"
                  size="small"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formik.values.pharmacist_account.username}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.pharmacist_account?.username &&
                    Boolean(formik.errors.pharmacist_account?.username)
                  }
                  helperText={
                    formik.touched.pharmacist_account?.username &&
                    formik.errors.pharmacist_account?.username
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.textField}
                  id="pharmacist_account.password"
                  name="pharmacist_account.password"
                  label="Password"
                  size="small"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  value={formik.values.pharmacist_account.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.pharmacist_account?.password &&
                    Boolean(formik.errors.pharmacist_account?.password)
                  }
                  helperText={
                    formik.touched.pharmacist_account?.password &&
                    formik.errors.pharmacist_account?.password
                  }
                />
              </Grid>
            </Grid>
            <Grid className={classes.gridContainer} container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  className={classes.textField}
                  id="pharmacist_account.first_name"
                  name="pharmacist_account.first_name"
                  label="First Name"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={formik.values.pharmacist_account.first_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.pharmacist_account?.first_name &&
                    Boolean(formik.errors.pharmacist_account?.first_name)
                  }
                  helperText={
                    formik.touched.pharmacist_account?.first_name &&
                    formik.errors.pharmacist_account?.first_name
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.textField}
                  id="pharmacist_account.last_name"
                  name="pharmacist_account.last_name"
                  label="Last Name"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={formik.values.pharmacist_account.last_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.pharmacist_account?.last_name &&
                    Boolean(formik.errors.pharmacist_account?.last_name)
                  }
                  helperText={
                    formik.touched.pharmacist_account?.last_name &&
                    formik.errors.pharmacist_account?.last_name
                  }
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              alignItems="center"
            >
              <Grid
                item
                style={{ lineHeight: "22px !important", marginTop: 8 }}
                xs={5}
                md={5}
              >
                <StyledPhoneInput
                  formik={formik}
                  fieldName="pharmacist_account.phone_number"
                />
                {formik.touched.pharmacist_account?.phone_number &&
                formik.errors.pharmacist_account?.phone_number ? (
                  <span
                    style={{
                      marginTop: "4px",
                      marginLeft: "14px",
                      marginRight: "14px",
                      color: "#FE5A5A",
                      fontSize: "0.75rem",
                    }}
                  >
                    {formik.errors.pharmacist_account.phone_number}
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  name="pharmacist_account.ext"
                  label="Ext"
                  value={formik.values.pharmacist_account.ext}
                  onChange={formik.handleChange}
                  fullWidth
                  margin="normal"
                  type="number"
                  error={
                    formik.touched.pharmacist_account?.ext &&
                    !!formik.errors.pharmacist_account?.ext
                  }
                  helperText={
                    formik.touched.pharmacist_account?.ext &&
                    formik.errors.pharmacist_account?.ext
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                className={classes.textField}
                variant="outlined"
                size="small"
                label="Email"
                name="pharmacist_account.email"
                value={formik.values.pharmacist_account.email}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                error={
                  formik.touched.pharmacist_account?.email &&
                  !!formik.errors.pharmacist_account?.email
                }
                helperText={
                  formik.touched.pharmacist_account?.email &&
                  formik.errors.pharmacist_account?.email
                }
              />
            </Grid>
          </Stack>
          <Box display="flex" justifyContent="end">
            <Button
              type="submit"
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              className={classes.createPharmacy}
              disabled={formik.isSubmitting}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Paper>
    </form>
  );
};

export default CreateNewPharmacy;
