import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { useStyles } from "./style";

type StatCardProps = {
  label: string;
  stat: number | string;
};

const StatCard: React.FC<StatCardProps> = (props) => {
  const { label, stat } = props;
  const classes = useStyles();

  return (
    <Card sx={{ maxHeight: 120 }} className={classes.cardBody}>
      <CardContent>
        <Typography className={classes.title} variant="body1">
          {label}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          fontWeight={"600"}
          className={classes.subHeading}
        >
          {stat}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatCard;
