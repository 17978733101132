import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

const ErrorRoute: React.FC<{}> = props => {

  return ( 
    <Box>
      <Typography variant="h1" align="center">Oops!</Typography>
      <Typography variant="h4" align="center">Looks like the page you are looking for can't be found.</Typography>
    </Box>
  );
}

export default ErrorRoute;