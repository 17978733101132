import { get } from "../../../services/api/api";
import { insertNewUserAccess } from "./actions";

export const getUserAccess = (user_id: string) => {
  return (dispatch: any) => {
    const url = `/api/v1/users/${Number(user_id)}/useraccess`;
    get(url)
      .then((resp) => {
        dispatch(insertNewUserAccess(resp.data.user_access_list));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};
