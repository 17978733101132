import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1.625rem",
    flexDirection: "column",
  },
  header: {
    marginBottom: "1.25rem !important",
  },
  btnContained: {
    whiteSpace: "nowrap",
    width: "375px",
    color: theme.palette.common.white,
    // backgroundColor: `${theme.palette.secondary.main} !important`,
    backgroundColor: "#3C3C3C",
    "text-transform": "capitalize !important",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.light,
      backgroundColor: "#3C3C3C",
    },
    marginTop: "38px",
  },
  heading: {
    fontSize: "1.25rem",
    color: "#000000",
    lineHeight: "1.375rem",
    fontWeight: 700,
  },
  driversBox: {
    borderRadius: ".625rem",
    boxShadow: "0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.25)",
    padding: ".875rem 1.125rem 1.4375rem 1.125rem",
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  placeHolder: {
    fontSize: ".9375rem",
    color: "#B6B6B6",
    lineHeight: "1.375rem",
  },
  textContainer: {
    display: "flex",
    gap: 10,
  },
  driversCheckBoxes: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
    marginTop: 12,
    marginLeft: 10,
  },
}));

export default useStyles;
