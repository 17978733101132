export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const ADD_USER = "ADD_USER";

export type User = {
  user_id: string, 
  unique_id: string,
  firstName: string, 
  lastName: string, 
  email: string, 
  username: string,
  user_role: string,
  verified: boolean,
  active: boolean,
  loggedIn: boolean
}

// ACTIONS
export type UserLogin = {
  type: typeof USER_LOGIN //typeof returns the type of the variable you are calling
  payload: User
}

export type AddUser = {
  type: typeof ADD_USER, 
  payload: User
}

export type UserLogout = {
  type: typeof USER_LOGOUT, 
  payload: User
}
