import React, { useState } from "react";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-hot-toast";

import useStyles from "./styles";
import { deleteOrganization } from "../../../services/api/requests";
import { Box } from "@material-ui/core";

const DeleteOrgForm: React.FC<{}> = () => {
  const [delId, setDelId] = useState("");
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      id: "",
      confirmDeleting: false,
    },
    onSubmit: (values) => {
      deleteOrganization(Number(values?.id))
        .then((res) => {
          toast.success(`Successfully deleted Organization ${values.id}`);
          formik.resetForm();
          setDelId("");
        })
        .catch((err) => {
          toast.error(
            "Woops! something went wrong when trying to delete organization"
          );
          console.error(err);
        });
    },
  });

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <Paper className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.heading} variant="h6">
              Delete Organization
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              name="id"
              required={true}
              size="small"
              className={classes.nameInput}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.id}
              onPaste={handlePaste}
              label="Id"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              name="delId"
              required={true}
              size="small"
              className={classes.nameInput}
              onChange={(e) => {
                setDelId(e.currentTarget.value);
              }}
              value={delId}
              onPaste={handlePaste}
              variant="outlined"
              label="Confirm Id"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              className={classes.confirmingText}
              control={
                <Checkbox
                  className={classes.confirmCheckbox}
                  disabled={
                    !formik.values.id || !delId || formik.values.id !== delId
                  }
                  checked={formik.values.confirmDeleting}
                  onChange={formik.handleChange}
                  required={true}
                  size="medium"
                  name="confirmDeleting"
                  color="primary"
                />
              }
              label="I confirm that the user above will be deleted forever."
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box className={classes.createButtonContainer}>
              <Button
                disabled={
                  !formik.values.id || !delId || formik.values.id !== delId
                }
                className={classes.createButton}
                disableFocusRipple={true}
                disableRipple={true}
                type="submit"
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default DeleteOrgForm;
