// calculate progress on POST request
const uploadProgress = (progressEvent: any) => {
  let percentage: number = 0;
  const totalLength = progressEvent?.lengthComputable
    ? progressEvent?.total
    : progressEvent?.target?.getResponseHeader("content-length") ||
      progressEvent?.target?.getResponseHeader("x-decompressed-content-length");
  if (totalLength !== null) {
    percentage = Math.round((progressEvent.loaded * 100) / totalLength);
  }
  return percentage;
};

const onDownloadProgress = (progressEvent: any) => {
  let percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  return percentCompleted;
};

export { uploadProgress, onDownloadProgress };
