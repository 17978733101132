import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")] : {
      width: "400px"
    },
    [theme.breakpoints.up("sm")] : {
      width: "1075px"
    },
    [theme.breakpoints.between("sm","md")] : {
      width: "650px"
    },
    padding: "15px",
    minHeight: "200px",
  },
  heading: {
    fontWeight: "bold"
  },
  nameInput: {
    width: "250px"
  },
  largeInput: {
    width: "250px"
  },
  selectLabel: {
    fontSize: "13px"
  },
  searchField: {
    margin: "25px 0 0 15px"
  },
  addressSearchField: {
    width: "250px",
    fontSize: "13px"
  },
  createButton: {
    width: "150px",
    height: "40px",
    color:"#fff",
    // backgroundColor: '#3C3C3C',
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      // backgroundColor: '#3C3C3C',
      backgroundColor: theme.palette.secondary.light
    }, 
    fontSize: "15px"
  }
}));

export default useStyles;