import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    patientWrapper: {
      display: "flex",
      marginTop: theme.spacing(4),
      maxWidth: "100%",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      minWidth: "100%",
    },
    spinner: {
      margin: "50%",
    },
    textField: {
      width: "9.75rem",
      flexDhrink: 0,
      borderRadius: "10px",
      height: "1.875rem",
      background: "#FFF",
      marginRight: ".875rem",
    },
    inputFeild: {
      height: "1.875rem",
    },
    btnBox: {
      display: "flex",
    },
    btnStyle: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      width: "9.75rem",
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  })
);
