export const GET_DRIVERS = "GET_DRIVERS";
export const ADD_DRIVER = "ADD_DRIVER";
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const ADD_SELECTED_DRIVERS = "ADD_SELECTED_DRIVERS";

export type DriverInfo = {
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  extension: number;
  unit: string;
  address: string;
  addressKey: string;
  lat: number;
  long: number;
  dist: number;
  totalDeliveries: number;
  totalSpent: number;
};

export type Driver = {
  id: number;
  name: string;
  phone_number: number;
  isSelected: boolean;
};

export type Drivers = {
  drivers: {
    [id: string]: DriverInfo;
  };
  moreDrivers: boolean;
  totalDrivers: number;
  selectedDrivers: Driver[];
};

type GetDrivers = {
  type: typeof GET_DRIVERS;
  payload: Drivers;
};

type AddDriver = {
  type: typeof ADD_DRIVER;
  payload: DriverInfo;
};

type UpdateDriver = {
  type: typeof UPDATE_DRIVER;
  payload: DriverInfo;
};

export type DriverAction = AddDriver | GetDrivers | UpdateDriver | Driver;
