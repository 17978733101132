import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useStyles as styles } from "./styles";
import { useAppSelector } from "../hooks";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MenuPopupState from "../components/menuPopupState";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";

import Paper from "@material-ui/core/Paper";

import { fetchPatientDetail } from "../services/api/requests";
import AddUserModal from "./addUserModal";

const ChangePharmacy: React.FC<{}> = () => {
  const classes = styles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const nav = useNavigate();
  const { pharmacyId } = useParams();

  const org = useAppSelector((state) => state.organization);

  const goPharmacies = () => {
    nav("/pharmacies");
  };

  const openServiceAgreement = () => {
    nav(`/pharmacy/service-agreement/${pharmacyId}`);
  };
  const getPharmacyInfo = async () => {
    try {
      // let pharmacyIdInt = pharmacyId ? parseInt(pharmacyId) : pharmacyId;
      // const response = await fetchPatientDetail(org.org_id, pharmacyIdInt);
      // console.log("response :>> ", response);
    } catch (error) { }
  };

  useEffect(() => {
    getPharmacyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Stack
        mb={2}
        display="flex"
        flexDirection="row"
        spacing={0}
        alignItems="center"
      >
        <NavigateBeforeIcon
          onClick={() => {
            goPharmacies();
          }}
          className={classes.icon}
        />
        <Typography variant="h6" component="div" className={classes.heading}>
          Pharmacies
        </Typography>
      </Stack>
      <Grid item sm={12} md={8} lg={7}>
        <Paper className={classes.card}>
          <Box className={classes.header}>
            <Typography variant="subtitle1" className={classes.subtitleClass}>
              Details
            </Typography>
            <MenuPopupState
              items={[
                {
                  name: "Modify Service Agreement",
                  handleClick: openServiceAgreement,
                },
              ]}
              onDeleteClick={() => { }}
            />
          </Box>
          <Typography variant="body1" className={classes.textStyle}>
            currentOrder.submitted_by
          </Typography>
          <Typography variant="body1" className={classes.textStyle}>
            currentOrder.unit currentOrder.to_address
          </Typography>
          <Box className={classes.IconBox}>
            <PhoneIphoneIcon />
            <Typography variant="body1" className={classes.textStyle}>
              currentOrder.phone_number
            </Typography>
          </Box>

          <Box className={classes.IconBox}>
            <AlternateEmailIcon />
            <Typography variant="body1" className={classes.textStyle}>
              currentOrder?.email
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            className={classes.subtitleClass}
            style={{ marginTop: 13 }}
          >
            Admin Details
          </Typography>
          <Typography variant="body1" className={classes.textStyle}>
            Delivery Date: hello
          </Typography>
          <Typography variant="body1" className={classes.textStyle}>
            Amount: $122 CAD
          </Typography>
          <Typography variant="body1" className={classes.textStyle}>
            Amount Collected : $122 CAD
          </Typography>

          <Typography variant="body1" className={classes.textStyle}>
            Payment method: Cash
          </Typography>

          <Typography variant="subtitle1" className={classes.subtitleClass}>
            Notes
          </Typography>
          <Box
            style={{
              marginTop: 12,
              border: "1px solid #CFCFCF",
              padding: 12,
              borderRadius: 5,
              minHeight: 50,
            }}
          >
            notes
          </Box>
        </Paper>
      </Grid>
      <Grid item sm={12} md={8} lg={7} style={{ marginTop: 29 }}>
        <Paper className={classes.card}>
          <Box className={classes.header}>
            <Typography variant="subtitle1" className={classes.subtitleClass}>
              Users
            </Typography>
            <MenuPopupState onDeleteClick={() => { }} />
          </Box>
          <Typography variant="body1" className={classes.textStyle}>
            These are the following accounts that exist for employees of this
            pharmacy.
          </Typography>
          <Box style={{ marginTop: 20 }}>
            <Box className={classes.flexClass}>
              <Typography variant="body1" className={classes.textStyle}>
                Markham & 14th Avenue Pharmacy
              </Typography>
              <Button className={classes.btnStyle}>
                <Typography variant="body1" className={classes.textStyle}>
                  Remove
                </Typography>
              </Button>
            </Box>
            <Box className={classes.flexClass}>
              <Typography variant="body1" className={classes.textStyle}>
                Elson Street Pharmacy
              </Typography>
              <Button className={classes.btnStyle}>
                <Typography variant="body1" className={classes.textStyle}>
                  Remove
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box style={{ marginTop: 79 }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                fullWidth={true}
                style={{ width: "50%" }}
                onClick={handleOpen}
                disableFocusRipple={true}
                disableRipple={true}
              >
                Add New User
              </Button>
            </Grid>
          </Box>
          <AddUserModal
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        </Paper>
      </Grid>
    </Box>
  );
};

export default ChangePharmacy;
