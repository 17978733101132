import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useStyles as styles } from "./styles";
import { useAppSelector } from "../hooks";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";

import CreateNewDriver from "./createNewDriver";

const CreateDriver: React.FC<{}> = () => {
  const classes = styles();

  const nav = useNavigate();

  const org = useAppSelector((state) => state.organization);

  const goDrivers = () => {
    nav("/drivers");
  };

  return (
    <Box className={classes.root}>
      <Stack
        mb={2}
        display="flex"
        flexDirection="row"
        spacing={0}
        alignItems="center"
      >
        <NavigateBeforeIcon
          onClick={() => {
            goDrivers();
          }}
          className={classes.icon}
        />
        <Typography variant="h6" component="div" className={classes.heading}>
          Drivers
        </Typography>
      </Stack>
      <CreateNewDriver orgId={org?.org_id} />
    </Box>
  );
};

export default CreateDriver;
