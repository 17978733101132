import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import cross from "../../assets/cross.svg";

import { Button, Grid, Typography, Box } from "@material-ui/core";

import useStyles from "./styles";

export default function SwitchZoneModal({
  open,
  handleClose,
  handleOpen,
  value,
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: {
            backgroundColor: "#FFFFFF99",
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box className={classes.headerAlignment}>
              <Typography variant="subtitle1" className={classes.subtitleClass}>
                Attention!
              </Typography>
              <Button className={classes.logo} onClick={handleClose}>
                <img src={cross} alt="Logo" />
              </Button>
            </Box>

            <Box
              style={{
                marginRight: 16,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid className={classes.gridContainer}>
                <Typography variant="body1">
                  You are about to switch from a{" "}
                  {value === 0 ? "per delivery service" : "zone-based"} service
                  agreement to a{" "}
                  {value === 0 ? "zone-based" : "per delivery service"}{" "}
                  agreement.
                  {"\n"}Do you wish to proceed?{"\n"}
                  You can switch back at any time, but this may affect
                  reporting.
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.btnGrid}>
                <Button
                  fullWidth={true}
                  style={{ width: "20%" }}
                  onClick={handleOpen}
                  disableFocusRipple={true}
                  disableRipple={true}
                >
                  Switch
                </Button>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
