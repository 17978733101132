import { GET_ORG, ADD_TAG, Organization, DELETE_TAG, Tag } from "./types";

export const getOrg = (org: Organization) => {
  return {
    type: GET_ORG,
    payload: org,
  };
};

export const addNewTag = (tag: string) => {
  return {
    type: ADD_TAG,
    tag,
  };
};

export const tagDelete = (tag: Tag) => {
  return {
    type: DELETE_TAG,
    tag,
  };
};
