export const GET_PATIENTS = "GET_PATIENTS";
export const ADD_PATIENT = "ADD_PATIENT";

export type PatientInfo = {
  customerId: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  extension: number,
  unit: string,
  address: string,
  addressKey: string,
  lat: number,
  long: number,
  dist: number,
  totalDeliveries: number,
  totalSpent: number
};

export type Patients = {
  patients: {
    [id: string]: PatientInfo
  },
  morePatients: boolean, 
  totalPatients: number
}

type GetPatients = {
  type: typeof GET_PATIENTS, 
  payload: Patients
}

type AddPatient = {
  type: typeof ADD_PATIENT,
  payload: PatientInfo
}

export type PatientAction = AddPatient | GetPatients;