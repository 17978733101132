import React from "react";
import { useSelector } from "react-redux";
import { Drawer, MenuItem, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FeaturedPlayList,
  SettingsApplications,
  AccountBox,
  Subtitles,
  SportsSoccer,
  SwapVert,
  DriveEta,
  LocalPharmacy,
} from "@material-ui/icons";

import { useStyles } from "./styles";
import { WEEL_ADMIN, ORG_ADMIN, COURIER_ADMIN } from "../../services/roles";

type NavbarProps = {
  showNavbar: boolean;
  toggleNavbar: any;
};

type RouteList = Array<{
  label: string;
  path: string;
  icon?: React.ReactElement;
}>;

const Navbar: React.FC<NavbarProps> = (props) => {
  const classes = useStyles(props);
  const nav = useNavigate();
  const location = useLocation();
  const { account: user, access } = useSelector((state: any) => state.user);

  const weelAdminRoutes: RouteList = [
    {
      label: "Admin Console",
      path: "/admin",
    },
  ];

  const customerRoutes: RouteList = [
    {
      label: "Deliveries",
      path: "/deliveries",
      icon: <FeaturedPlayList className={classes.icon} />,
    },
    {
      label: "Customers",
      path: "/customers",
      icon: <AccountBox className={classes.icon} />,
    },
    {
      label: "Manage",
      path: "/manage",
      icon: <SettingsApplications className={classes.icon} />,
    },
    {
      label: "Reports",
      path: "/logs",
      icon: <Subtitles className={classes.icon} />,
    },
  ];

  const deliveryAdminRoutes: RouteList = [
    {
      label: "Pharmacies",
      path: "/pharmacies",
      icon: <LocalPharmacy className={classes.icon} />,
    },
    {
      label: "Drivers",
      path: "/drivers",
      icon: <DriveEta className={classes.icon} />,
    },
    {
      label: "Manage",
      path: "/manage",
      icon: <SettingsApplications className={classes.icon} />,
    },
  ];

  const changeRoute = (path: string) => {
    nav(path, {});
  };

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open={props.showNavbar}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.routeContainer}>
        {user.user_role === WEEL_ADMIN
          ? weelAdminRoutes.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  onClick={() => changeRoute(item.path)}
                  className={`${classes.item} ${
                    location.pathname.includes(item.path)
                      ? classes.itemSelected
                      : ""
                  }`}
                >
                  {item.icon}
                  <Typography className={classes.label}>
                    {item.label}
                  </Typography>
                </MenuItem>
              );
            })
          : access[0]?.org_type === "pharmacy" // org_type
          ? customerRoutes.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  onClick={() => changeRoute(item.path)}
                  className={`${classes.item} ${
                    location.pathname.includes(item.path)
                      ? classes.itemSelected
                      : ""
                  }`}
                >
                  {item.icon}
                  <Typography className={classes.label}>
                    {item.label}
                  </Typography>
                </MenuItem>
              );
            })
          : access[0]?.org_type === "courier"
          ? deliveryAdminRoutes.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  onClick={() => changeRoute(item.path)}
                  className={`${classes.item} ${
                    location.pathname.includes(item.path)
                      ? classes.itemSelected
                      : ""
                  }`}
                >
                  {item.icon}
                  <Typography className={classes.label}>
                    {item.label}
                  </Typography>
                </MenuItem>
              );
            })
          : null}
      </div>
      <div className={classes.navFoot}>
        {access?.length > 1 &&
          (user.user_role === COURIER_ADMIN ||
            user.user_role === ORG_ADMIN) && (
            <MenuItem
              onClick={() => changeRoute("/pharmacies/switch")}
              className={`${classes.item} ${
                "/pharmacies/switch" === location.pathname
                  ? classes.itemSelected
                  : ""
              }`}
            >
              <SwapVert className={classes.icon} />

              <Typography className={classes.label}>{"Switch"}</Typography>
            </MenuItem>
          )}
        <MenuItem
          onClick={() => changeRoute("")}
          className={`${classes.item} ${
            "" === location.pathname ? classes.itemSelected : ""
          }`}
        >
          <SportsSoccer className={classes.icon} />
          <Typography className={classes.label}>{"Help?"}</Typography>
        </MenuItem>
      </div>
    </Drawer>
  );
};

export default Navbar;
