import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useStyles as styles } from "./styles";
import Button from "@material-ui/core/Button";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import weelTheme from "../components/material-ui/theme";
import DriversTable from "./components/driversTable";
import { Typography } from "@mui/material";
import { fetchDriversByOrg } from "../services/api/requests";
import { useAppSelector } from "../hooks";

interface DriversProps {
  readOnly?: boolean;
}

const Drivers: React.FC<DriversProps> = (props) => {
  const nav = useNavigate();
  const org = useAppSelector((state) => state.organization);
  const [drivers, setdrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = styles();
  const handleCreateClick = () => {
    nav(`/drivers/create`);
  };

  const getDrivers = async () => {
    try {
      setLoading(true);
      const response = await fetchDriversByOrg(org?.org_id);
      setdrivers(response.data.drivers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.btnContainer}>
        <Typography variant="h6" fontWeight={700}>
          Drivers
        </Typography>
        <Button
          fullWidth
          className={classes.createBtn}
          disableFocusRipple={true}
          disableRipple={true}
          onClick={handleCreateClick}
        >
          Create
        </Button>
      </Box>
      {!loading ? (
        <div className={classes.driversWrapper}>
          <br />
          <DriversTable drivers={drivers} />
        </div>
      ) : (
        <div className={classes.spinner}>
          <Loader
            type="Bars"
            color={weelTheme.palette.secondary.main}
            height={100}
            width={100}
          />
        </div>
      )}
    </div>
  );
};

export default Drivers;
