import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "./header";
import { HeaderCell } from "./types";
import { Orders, Zones } from "../../store/orders/types";
import { useStyles as styles } from "./styles";
import StatusChip from "../../components/statusChip";
import "./style.css";
import { Box, Chip, IconButton, Menu, TableFooter } from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteZone } from "../../services/api/requests";
import MenuPopupState from "../../components/menuPopupState";

type AgreementTableProps = {
  orders: Zones[];
  getMoreOrders: () => void;
  orgId: number;
  pharmacyId: string | undefined;
  onDelete: () => void;

};

const headCells: Array<HeaderCell> = [
  {
    id: "name",
    leftAlign: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "startDistance",
    leftAlign: true,
    disablePadding: false,
    label: "Start Distance",
  },
  {
    id: "endDistance",
    leftAlign: true,
    disablePadding: false,
    label: "End Distance",
  },
  {
    id: "unit",
    leftAlign: false,
    disablePadding: false,
    label: "Unit",
  },
  { id: "rate", leftAlign: true, disablePadding: false, label: "Rate" },
  {
    id: "dateCreated",
    leftAlign: true,
    disablePadding: false,
    label: "Date Created",
  },
  {
    id: "dateDelivery",
    leftAlign: true,
    disablePadding: false,
    label: "Delivery Date ",
  },
];

const sanitizeDate = (deliveryDate: string): string => {
  const date = moment(deliveryDate).calendar();
  return date;
};

const ServiceAgreementTable: React.FC<AgreementTableProps> = (props) => {
  const classes = styles();
  const rowsPerPage: number = 5; // the number of rows per page in the order table

  const nav = useNavigate();

  const [page, setPage] = useState(0);
  const { getMoreOrders, orders, orgId, pharmacyId, onDelete } = props;
  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page) {
      getMoreOrders();
    }
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      orders?.length - page * rowsPerPage
    );
  const formatDate = (dateString: string) => {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Format the date to "9:00 AM"
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleTimeString("en-US", options);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <Paper className={classes.paper}>
      <TableContainer className="table-delivery">
        <Table className={classes.table} size="small" aria-label="orders table">
          <TableHeader headerCells={headCells} />
          <TableBody>
            {orders?.map((order, idx) => {


              const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
              };

              const handleClose = () => {
                setAnchorEl(null);
              };

              const handleDelete = async () => {
                const resposne = await deleteZone(orgId, pharmacyId, order.id)
                onDelete()
              };
              return (
                <TableRow
                  className={classes.row}
                  tabIndex={-1}
                  key={`enhanced-table-checkbox-${idx}`}
                  onClick={() => {

                  }}
                  style={{
                    backgroundColor: idx % 2 === 0 ? "#F6F8FCCC" : "#FFFFFF", // Alternate colors
                  }}
                >
                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">{order.name}</Typography>
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">
                      {order.zone_start}
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">
                      {order.zone_end}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >

                    <Typography variant="body1">
                      {order.unit}
                    </Typography>

                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">
                      {order.rate}
                    </Typography>

                  </TableCell>


                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">
                      {sanitizeDate(order.date_created)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">
                      {sanitizeDate(order.delivery_date)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">

                    <MenuPopupState onDeleteClick={() => { handleDelete() }} />
                  </TableCell>
                </TableRow>
              );
            })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            {emptyRows > 0 && orders?.length > 0 && (
              <TableRow
                style={{ height: 38 * emptyRows, borderBottom: "none" }}
              >
                <TableCell colSpan={8} style={{ borderBottom: "none" }} />
              </TableRow>
            )}
            {orders?.length === 0 && (
              <TableRow style={{ height: 375, borderBottom: "none" }}>
                <TableCell colSpan={8} style={{ borderBottom: "none" }}>
                  <Typography
                    className={classes.emptyRowsTitle}
                    variant="h6"
                    align="center"
                    component="div"
                  >
                    No Zone
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              className={classes.pages}
              rowsPerPageOptions={[]}
              count={orders?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} of ${count} Zone`
              }
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

export default ServiceAgreementTable;
