import { 
  GET_PATIENTS,
  ADD_PATIENT,
  PatientAction,
  Patients, 
  PatientInfo
} from "./types";

export const getPatients = (patients: Patients): PatientAction => {
  return {
    type: GET_PATIENTS,
    payload: patients
  }
}

export const addPatient = (patient: PatientInfo): PatientAction => {
  return {
    type: ADD_PATIENT,
    payload: patient
  }
}