import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column wrap",
    marginBottom: theme.spacing(8),
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.common.black,
    height: "16px",
    width: "16px",
    zIndex: 1,
  },
  heading: {
    marginTop: "0px !important",
  },
  secondPaper: {
    width: "100%",
    borderRadius: "5px !important",
    minHeight: "15vh",
    marginTop: "1.1875rem",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    marginTop: "1.375rem",
    justifyContent: "space-between",
    width: "100%",
  },
  btnStyle: {
    width: "9.75rem",
  },
}));
