import React from "react";
import { Grid } from "@material-ui/core";
import ServiceAgreement from "./components/serviceAgreement";
import { useStyles as styles } from "./styles";
import { useFormik, FormikProps } from "formik";
import { FormikPharmacy } from "./types";
interface PharmacyProps {
  readOnly?: boolean;
}

const Pharmacy: React.FC<PharmacyProps> = (props) => {
  const { readOnly } = props;
  const classes = styles();

  const formik: FormikProps<FormikPharmacy> = useFormik<FormikPharmacy>({
    initialValues: {
      serviceAgreement: "zone",
      price: "",
      zones: [
        {
          zone: "",
          start: "",
          end: "",
          rate: 0.0,
          unit: "",
        },
      ],
    },
    onSubmit: () => {},
  });

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12} md={7}>
          <ServiceAgreement readOnly={readOnly} formik={formik} />
        </Grid>
      </form>
    </div>
  );
};

export default Pharmacy;
