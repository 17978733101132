import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStyles from "./styles";
import SwitchZoneModal from "../../switchZoneModal";

function ServiceAgreementTabs({ value, setValue, onChange }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);



  const handleOpen = () => {
    // setOpen(true);
    setValue(value === 1 ? 0 : 1)
    onChange()
    handleClose()
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // setValue(newValue);
    setOpen(true)

  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="service agreement tabs"
        className={classes.tabRoot}
      >
        <Tab label="Delivery" className={classes.tab} />
        <Tab label="Zone" className={classes.tab} />
      </Tabs>
      <SwitchZoneModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        value={value}
      />

    </Paper>
  );
}

export default ServiceAgreementTabs;
