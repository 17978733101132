import React from "react";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-hot-toast";

import { assignPharmacyToServiceProvider } from "../../../services/api/requests";

import useStyles from "./styles";

const AssignServiceProvider: React.FC<{}> = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      service_provider_id: "",
      pharmacy_id: "",
    },
    onSubmit: (values) => {
      assignPharmacyToServiceProvider(values)
        .then((resp) => {
          toast.success(`Successfully assigned pharmacy to service provider`);
          formik.resetForm();
        })
        .catch((err) => {
          toast.error(
            `Woops! something went wrong when trying to assign a pharmacy to a service provider`
          );
          console.error(err);
        });
    },
  });

  return (
    <Paper className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.heading} variant="h6">
              Assign Service Provider
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="service_provider_id"
              size="small"
              type="number"
              className={classes.nameInput}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.service_provider_id}
              label="Service Provider"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="pharmacy_id"
              size="small"
              type="number"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.pharmacy_id}
              variant="outlined"
              label="Pharmacy"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              className={classes.createButton}
              disableFocusRipple={true}
              disableRipple={true}
              type="submit"
            >
              Link
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AssignServiceProvider;
