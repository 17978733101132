import React from "react";
import { Navigate } from "react-router-dom";

type AuthorizedRouteProps = {
  authenticated: boolean;
  allowedRoles: string[];
  currentRole: string;
  children: JSX.Element;
};

const AuthorizedRoute = (props: AuthorizedRouteProps): JSX.Element => {
  const { authenticated, allowedRoles, currentRole, children } = props;

  if (!authenticated) {
    return <Navigate to="/login" replace state={{ loginErr: false }} />;
  }

  if (!allowedRoles.includes(currentRole)) {
    return <Navigate to="/error" replace />;
  }

  return children;
};

export default AuthorizedRoute;
