import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: "100%",
      width: "100%",
      marginBottom: theme.spacing(2),
      borderRadius: "10px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    },

    table: {
      flexFlow: "column nowrap",
      margin: "0 .75rem",
    },
    pages: {
      borderTop: "1px solid #CFCFCF",
    },
    row: {
      flexFlow: "row nowrap",
      borderBottom: "1px solid #CFCFCF",
      width: "100%",
      //paddingLeft: "5px",
      "&:hover": {
        background: "#F5F5F5",
        cursor: "pointer",
      },
    },

    cells: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      borderBottom: "none",
      height: "20px",
      minWidth: 60,
      paddingTop: "10px",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      textAlign: "left",
      lineHeight: "22px",
      padding: "1rem 1.5rem .75rem 1.375rem",
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
    },
    emptyRowsTitle: {
      color: "#CFCFCF",
      fontWeight: theme.typography.fontWeightBold as "normal" | "bold" | number,
      lineHeight: "22px",
    },
  })
);
