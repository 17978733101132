import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "column wrap",
      marginBottom: theme.spacing(8),
      overflow: "hidden",
    },
    icon: {
      cursor: "pointer",
      color: theme.palette.common.black,
      height: "16px",
      width: "16px",
      zIndex: 1,
    },
    heading: {
      marginTop: "0px !important",
      color: theme.palette.common.black,
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      lineHeight: "22px !important",
    },
    paper: {
      width: "100%",
      borderRadius: "10px",
      marginBottom: "22px",
      background: "#FFF",
      boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    title: {
      marginTop: "0px ",
      lineHeight: "22px",
      textTransform: "capitalize",
    },
    subTitle: {
      color: " #CFCFCF",
      lineHeight: "22px",
      marginTop: "15px",
      fontWeight: 600,
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      borderBottom: "1px solid #CFCFCF",
    },
    body: {
      padding: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    btnContained: {
      whiteSpace: "nowrap",
      width: "17%",
      color: theme.palette.common.white,
      backgroundColor: "#3C3C3C",
      "text-transform": "capitalize !important",
      "&:hover": {
        // backgroundColor: theme.palette.secondary.light,
        backgroundColor: "#3C3C3C",
        opacity: "0.85",
      },
    },
  })
);
