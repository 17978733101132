import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "./header";
import { HeaderCell } from "./types";
import { useStyles as styles } from "./styles";
import { TableFooter, Typography } from "@material-ui/core";
import moment from "moment";

import "./style.css";

type PatientTableProps = {
  patients: any[];
  totalPatients: Number;
  fetchPatients: (offset: number) => void;
};

const sanitizeDate = (deliveryDate: string): string => {
  const date = moment(deliveryDate).calendar();
  return date;
};

const headCells: Array<HeaderCell> = [
  { id: "Name", leftAlign: true, disablePadding: false, label: "Name" },
  {
    id: "phoneNumber",
    leftAlign: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "createdAt",
    leftAlign: true,
    disablePadding: false,
    label: "Created At",
  },
  {
    id: "totalDeliveries",
    leftAlign: true,
    disablePadding: false,
    label: "Total Deliveries",
  },
];

const PatientTable: React.FC<PatientTableProps> = (props) => {
  const classes = styles();
  const nav = useNavigate();

  const rowsPerPage = 5;
  const [page, setPage] = useState(0);
  const { patients, totalPatients, fetchPatients } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page) {
      fetchPatients(patients?.length);
    }
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, patients?.length - page * rowsPerPage);

  function formatCanadianPhoneNumber(phoneNumber) {
    // Convert phoneNumber to a string and remove any non-digit characters
    const cleanedPhoneNumber = String(phoneNumber).replace(/\D/g, "");
    // Check if the number has 10 digits
    if (cleanedPhoneNumber.length === 11) {
      // Format the number into Canadian format +1 (###) ###-####
      return `+1 (${cleanedPhoneNumber.substring(
        0,
        3
      )}) ${cleanedPhoneNumber.substring(3, 6)}-${cleanedPhoneNumber.substring(
        6
      )}`;
    } else {
      // Return the original number if it doesn't have 10 digits
      return phoneNumber;
    }
  }

  return (
    <Paper className={classes.paper}>
      <TableContainer className="patient-table">
        <Table
          className={classes.table}
          size="small"
          aria-label="patients table"
        >
          <TableHeader headerCells={headCells} />
          <TableBody>
            {patients?.length > 0 &&
              patients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((patient, idx) => {
                  return (
                    <TableRow
                      className={classes.row}
                      tabIndex={-1}
                      key={`enhanced-table-checkbox-${idx}`}
                      onClick={() => {
                        nav(`/customers/${patient.patientId}`);
                      }}
                      style={{
                        backgroundColor:
                          idx % 2 === 0 ? "#F6F8FCCC" : "#FFFFFF", // Alternate colors
                      }}
                    >
                      <TableCell
                        className={classes.cells}
                        align="center"
                        padding="normal"
                        title={
                          typeof patient.unit === "string" &&
                          typeof patient.address === "string"
                            ? patient.unit !== ""
                              ? patient.unit + "-" + patient.address
                              : patient.address
                            : "Invalid Data"
                        }
                      >
                        <Typography variant="body1">
                          {patient.name}
                          <br />
                          {patient.address}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.cells}
                        align="center"
                        padding="normal"
                      >
                        <Typography variant="body1">
                          {formatCanadianPhoneNumber(
                            patient.phoneNumber || "---"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.cells}
                        align="center"
                        padding="normal"
                      >
                        <Typography variant="body1">
                          {sanitizeDate(patient.dateCreated)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.cells}
                        align="center"
                        padding="normal"
                        style={{ textAlign: "center" }}
                      >
                        <Typography variant="body1">
                          {patient?.totalDeliveries || 0}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}

            {emptyRows > 0 && patients?.length > 0 && (
              <TableRow
                style={{ height: 38 * emptyRows, borderBottom: "none" }}
              >
                <TableCell colSpan={8} style={{ borderBottom: "none" }} />
              </TableRow>
            )}
            {patients.length === 0 && (
              <TableRow style={{ height: 375, borderBottom: "none" }}>
                <TableCell colSpan={8} style={{ borderBottom: "none" }}>
                  <Typography
                    className={classes.emptyRowsTitle}
                    variant="h6"
                    align="center"
                    component="div"
                  >
                    No Patients available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              className={classes.pages}
              rowsPerPageOptions={[]}
              count={totalPatients as number}
              rowsPerPage={rowsPerPage as number} // Ensure rowsPerPage is of primitive number type
              page={page as number} // Ensure page is of primitive number type
              labelDisplayedRows={({ from, to, count }) =>
                count === 0 ? null : `${from}-${to} of ${count} patients`
              }
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

export default PatientTable;
