import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "./header";
import { HeaderCell } from "./types";
import { Orders } from "../../store/orders/types";
import { useStyles as styles } from "./styles";
import StatusChip from "../../components/statusChip";
import "./style.css";
import { Box, Chip, TableFooter } from "@material-ui/core";

type BulkTableProps = {
  orders: Orders;
  getMoreOrders: () => void;
};

const headCells: Array<HeaderCell> = [
  {
    id: "location",
    leftAlign: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "address",
    leftAlign: true,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "phoneNumber",
    leftAlign: false,
    disablePadding: false,
    label: "Phone Number",
  },
  { id: "email", leftAlign: true, disablePadding: false, label: "Email" },
  {
    id: "dateCreated",
    leftAlign: true,
    disablePadding: false,
    label: "Date Created",
  },
];

const sanitizeDate = (deliveryDate: string): string => {
  const date = moment(deliveryDate).calendar();
  return date;
};

const BulkTable: React.FC<BulkTableProps> = (props) => {
  const classes = styles();
  const rowsPerPage: number = 5; // the number of rows per page in the order table

  const nav = useNavigate();

  const [page, setPage] = useState(0);
  const { getMoreOrders, orders } = props;
  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page) {
      getMoreOrders();
    }
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      Object.keys(orders.ordersList).length - page * rowsPerPage
    );
  const formatDate = (dateString: string) => {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Format the date to "9:00 AM"
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleTimeString("en-US", options);
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer className="table-delivery">
        <Table className={classes.table} size="small" aria-label="orders table">
          <TableHeader headerCells={headCells} />
          <TableBody>
            {Object.entries(orders.ordersList)
              .map((value, idx) => {
                let orderId = value[0];
                let order = value[1];
                let isPickup = order.type === "pickup";
                let address = isPickup ? order.from_address : order.to_address;
                let unit = isPickup ? order.from_unit : order.to_unit;
                return (
                  <TableRow
                    className={classes.row}
                    tabIndex={-1}
                    key={`enhanced-table-checkbox-${idx}`}
                    onClick={() => {
                      nav(`/deliveries/${orderId}`, {
                        state: { order: order },
                      });
                    }}
                    style={{
                      backgroundColor: idx % 2 === 0 ? "#F6F8FCCC" : "#FFFFFF", // Alternate colors
                    }}
                  >
                    <TableCell
                      className={classes.cells}
                      align="left"
                      padding="normal"
                    >
                      <Typography variant="body1">{order.name}</Typography>
                    </TableCell>
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={unit !== "" ? unit + "-" + address : address}
                    >
                      <TableCell
                        className={classes.cells}
                        align="left"
                        padding="normal"
                      >
                        <Typography variant="body1">
                          {unit} {address}
                        </Typography>
                      </TableCell>
                    </Tooltip>
                    <TableCell
                      className={classes.cells}
                      align="left"
                      padding="normal"
                    >
                      <StatusChip status={order.status} />
                    </TableCell>
                    <TableCell
                      className={classes.cells}
                      align="left"
                      padding="normal"
                    >
                      <Box className={classes.container}>
                        <Typography className={classes.text}>
                          {isPickup ? "P" : "D"}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      className={classes.cells}
                      align="left"
                      padding="normal"
                    >
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={`${order.delivery_preference} ${formatDate(
                          order.preference_start
                        )} ${
                          order.delivery_preference === "between"
                            ? formatDate(order.preference_end)
                            : ""
                        }`}
                      >
                        <Chip
                          style={{
                            backgroundColor: "#ECECEA",
                            color: "#222",
                            fontSize: "0.75rem",
                            fontWeight: "600",
                            width: "11.25rem",
                            height: "2.063rem",
                            borderRadius: 5,
                          }}
                          label={`${order.delivery_preference} ${formatDate(
                            order.preference_start
                          )} ${
                            order.delivery_preference === "between"
                              ? formatDate(order.preference_end)
                              : ""
                          }`}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.cells}
                      align="left"
                      padding="normal"
                    >
                      <Typography variant="body1">${order.amount}</Typography>
                    </TableCell>

                    <TableCell
                      className={classes.cells}
                      align="left"
                      padding="normal"
                    >
                      <Typography variant="body1">
                        {sanitizeDate(order.date_created)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.cells}
                      align="left"
                      padding="normal"
                    >
                      <Typography variant="body1">
                        {sanitizeDate(order.delivery_date)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            {emptyRows > 0 && Object.entries(orders.ordersList).length > 0 && (
              <TableRow
                style={{ height: 38 * emptyRows, borderBottom: "none" }}
              >
                <TableCell colSpan={8} style={{ borderBottom: "none" }} />
              </TableRow>
            )}
            {Object.entries(orders.ordersList).length === 0 && (
              <TableRow style={{ height: 375, borderBottom: "none" }}>
                <TableCell colSpan={8} style={{ borderBottom: "none" }}>
                  <Typography
                    className={classes.emptyRowsTitle}
                    variant="h6"
                    align="center"
                    component="div"
                  >
                    No Bulk Locations
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              className={classes.pages}
              rowsPerPageOptions={[]}
              count={orders.totalOrders}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} of ${count} Bulk Locations`
              }
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

export default BulkTable;
