import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      width: "100%",
      maxWidth: "100%",
      borderRadius: ".3125rem",
      padding: "0 .75rem",
    },
    table: {
      flexFlow: "column nowrap",
    },
    pages: {
      display: "contents",
    },
    row: {
      flexFlow: "row nowrap",
      borderBottom: "1px solid #CFCFCF",
      width: "100%",
      "&:hover": {
        background: "#F5F5F5",
        cursor: "pointer",
      },
    },
    cells: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      borderBottom: "none",
      textAlign: "left",
      padding: "0.375rem 1.5rem 0.375rem 1.375rem",
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
    },
    emptyRowsTitle: {
      color: "#CFCFCF",
      fontWeight: theme.typography.fontWeightBold as "normal" | "bold" | number,
      lineHeight: "22px",
    },
    tableContainer: {
      minHeight: 500,
    },
  })
);
