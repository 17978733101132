import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";
import { TableHeader } from "../header";
import { HeaderCell } from "./types";
import { useStyles as styles } from "./styles";
import "./style.css";
import { Typography } from "@mui/material";

type TableProps = {
  row: any[];
  headCells: Array<HeaderCell>;
  componentRef: any;
};

const LogTable: React.FC<TableProps> = (props) => {
  const classes = styles();
  const { row, headCells } = props;
  const scrollBarStyles: React.CSSProperties = {};

  const formatDate = (inputDate) => {
    const createdDate = new Date(inputDate);

    // Options for formatting the date and time
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit", // Short year (e.g., 23)
      month: "2-digit", // Zero-padded month (e.g., 03)
      day: "2-digit", // Zero-padded day (e.g., 01)
    };

    // Format the date and time together with a line break
    const formattedDate = createdDate.toLocaleDateString(undefined, options);

    // Options for formatting the time
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric", // Hour in 12-hour format (e.g., 5)
      minute: "numeric", // Minutes (e.g., 40)
      hour12: true, // Use 12-hour format (AM/PM)
    };

    // Format the time using toLocaleTimeString()
    const formattedTime = createdDate.toLocaleTimeString(
      undefined,
      timeOptions
    );
    // Return the formatted date and time
    return `${formattedDate}<br/>${formattedTime}`;
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer
        style={{
          ...scrollBarStyles,
          padding: "0 0.75rem",
        }}
        className="table-logs"
      >
        <Table
          ref={props.componentRef}
          className={classes.table}
          size="small"
          aria-label="patients table"
        >
          <TableHeader headerCells={headCells} />
          <TableBody>
            {row.length > 0 ? (
              row.map((item, idx) => (
                <TableRow
                  className={classes.row}
                  style={{
                    backgroundColor: idx % 2 === 0 ? "#F6F8FCCC" : "#FFFFFF", // Alternate colors
                  }}
                  tabIndex={-1}
                  key={`enhanced-table-checkbox-${idx}`}
                >
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {typeof item.delivery_reference === "string" ? (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: item.delivery_reference,
                        }}
                        variant="body1"
                      ></Typography>
                    ) : (
                      <span>{String(item)}</span> // Handle other types gracefully
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    <Typography variant="body1">
                      {item.customer}
                      <br />
                      {item.customer_address}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {typeof item.prepared_at === "string" ? (
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: formatDate(item.prepared_at),
                        }}
                      ></Typography>
                    ) : (
                      <Typography variant="body1">
                        {formatDate(String(item.prepared_at))}
                      </Typography> // Handle other types gracefully
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {typeof item.prepared_by === "string" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.prepared_by,
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {String(item.prepared_by)}
                      </Typography> // Handle other types gracefully
                    )}
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {typeof item.picked_up_at === "string" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatDate(item.picked_up_at),
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {formatDate(String(item.picked_up_at))}
                      </Typography> // Handle other types gracefully
                    )}
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {typeof item.delivered_at === "string" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatDate(item.delivered_at),
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {formatDate(String(item.delivered_at))}
                      </Typography> // Handle other types gracefully
                    )}
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {typeof item.delivery_notes === "string" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.delivery_notes,
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {String(item.delivery_notes)}
                      </Typography> // Handle other types gracefully
                    )}
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {typeof item.courier_id === "string" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.courier_id,
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {String(item.courier_id)}
                      </Typography> // Handle other types gracefully
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    {typeof item.distance_travelled === "string" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.distance_travelled,
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {String(item.distance_travelled)} kms
                      </Typography> // Handle other types gracefully
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default LogTable;
