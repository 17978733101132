import React, { useEffect, useState } from 'react';
import { TextField } from "@material-ui/core";
import { useStyles as styles } from "./styles";

const RateInput = ({ serviceRate, onUpdateRate }) => {
    const [rate, setRate] = useState(serviceRate);
    const classes = styles();

    // Handle input change
    const handleChange = (event) => {
        setRate(event.target.value);
    };



    // Handle keypress to detect Enter key
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Call the API to update the value
            onUpdateRate(rate);
        }
    };


    return (
        <TextField
            required={true}
            className={classes.textField}
            name="rate"
            type="number"
            size="small"
            label="Rate"
            variant="outlined"
            value={rate}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
        />
    );
};

export default RateInput;
