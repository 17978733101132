import { useFormik } from "formik";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import * as Yup from "yup";
import React from "react";
import useStyles from "./styles";
import { addNewDriver } from "../../store/driver/middleware";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyledPhoneInput from "../../components/styledPhoneInput";

type CreateNewDriverProps = {
  orgId: string;
};

const CreateNewDriver: React.FC<CreateNewDriverProps> = (props) => {
  const { orgId } = props;

  const nav = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      dispatch(addNewDriver(values, orgId, nav));
      // goDrivers();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const schema = Yup.object().shape({
    first_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "First name should contain only letters"),
    last_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Last name should contain only letters"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone_number: Yup.number().required("Required"),
    username: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      phone_number: "",

      email: "",
      first_name: "",
      last_name: "",
      password: "",
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: schema,
  });

  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div" className={classes.title}>
            Create a new driver
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.body}>
          <Stack
            className={classes.stack}
            display="flex"
            flexDirection="column"
          >
            <Grid className={classes.gridContainer} container spacing={2}>
              <Grid item xs={5} className={classes.grid}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  label="username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  fullWidth
                  margin="normal"
                  error={formik.touched.username && !!formik.errors.username}
                  helperText={formik.touched.username && formik.errors.username}
                  required={true}
                />
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  name="password"
                  label="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  fullWidth
                  margin="normal"
                  error={formik.touched.password && !!formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}
                  required={true}
                />
              </Grid>
            </Grid>
            <Grid className={classes.gridContainer} container spacing={2}>
              <Grid item xs={5} className={classes.grid}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  label="First Name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  fullWidth
                  margin="normal"
                  error={
                    formik.touched.first_name && !!formik.errors.first_name
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                  required={true}
                />
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  name="last_name"
                  label="Last Name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  fullWidth
                  margin="normal"
                  error={formik.touched.last_name && !!formik.errors.last_name}
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                  required={true}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              alignItems="center"
            >
              <Grid
                item
                style={{
                  lineHeight: "22px !important",
                }}
                xs={6}
                md={6}
              >
                <StyledPhoneInput formik={formik} fieldName="phone_number" />
              </Grid>
            </Grid>
            <Grid item xs={8} style={{ margin: "9px 0" }}>
              <TextField
                className={classes.textField}
                variant="outlined"
                size="small"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                required={true}
              />
            </Grid>
          </Stack>
          <Box display="flex" justifyContent="center">
            <Button
              type="submit" // Add this line
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              className={classes.createDriver}
              disabled={formik.isSubmitting}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Paper>
    </form>
  );
};

export default CreateNewDriver;
