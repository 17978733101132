import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    createBtn: {
      borderRadius: "10px",
      height: "40px",
      color: theme.palette.common.white,
      fontSize: "15px",
      lineHeight: "34.05px",
      fontWeight: theme.typography.fontWeightBold as "normal" | "bold" | number,
      backgroundColor: "#3C3C3C",
      // backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: "#3C3C3C",
        // backgroundColor: theme.palette.secondary.light,
      },
      marginBottom: 5,
    },
    patientWrapper: {
      display: "flex",
      marginTop: theme.spacing(4),
      maxWidth: "100%",
    },
    spinner: {
      margin: "50%",
    },
  })
);
