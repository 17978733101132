// libraries
import React, { useEffect } from "react";

import { Grid, Box, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { FormikProps, useFormik } from "formik";
import { useNavigate, useParams } from "react-router";

// components
import UpdatePatientForm from "./updatePatientForm";

// styles
import { useStyles } from "./style";
import { FormikPatient } from "./types";
import { ax } from "../services/api/api";
import { RootState } from "../store/types";
import { useSelector } from "react-redux";

const PatientDetails: React.FC<{}> = () => {
  const classes = useStyles();
  const org = useSelector((state: RootState) => state.organization);
  const { patientId } = useParams();
  const nav = useNavigate();
  const formik: FormikProps<FormikPatient> = useFormik<FormikPatient>({
    initialValues: {
      id: org.org_id,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      ext: 0,
      notes: "",
      distance: 0,
      locations: [
        {
          id: 0,
          unit: "",
          lat: 0,
          long: 0,
          address: "",
          preferred: true,
        },
      ],
    },
    onSubmit: (values) => {
      updatePatient();
    },
  });
  const fetchPatient = async () => {
    try {
      const response = await ax.get(
        `api/v1/organizations/${org.org_id}/patients/${patientId}`
      );

      // Assuming response.data contains the patient data
      const patientData = response.data;

      const preferredLocation = patientData.locations.find(
        (item) => item.preferred === true
      );
      // Set the form's initial values based on the API response
      formik.setValues({
        id: patientData.id,
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        email: patientData.email,
        phoneNumber: patientData.phoneNumber,
        ext: patientData.ext,
        notes: patientData.notes,
        locations: patientData.locations,
        distance: preferredLocation.distance, // Include the distance property with an appropriate value
      });
    } catch (error) {}
  };
  const updatePatient = async () => {
    try {
      const patient = {
        patient: formik.values,
      };
      await ax.put(
        `api/v1/organizations/${org.org_id}/patients/${patientId}`,
        patient
      );
      nav("/customers");
    } catch (error) {}
  };

  useEffect(() => {
    fetchPatient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className={classes.root}>
        <Stack
          mb={2}
          display="flex"
          flexDirection="row"
          spacing={0}
          alignItems="center"
        >
          <NavigateBeforeIcon
            onClick={() => {
              nav("/customers");
            }}
            className={classes.icon}
          />
          <Typography variant="h6" component="div">
            {formik.values.firstName + " " + formik.values.lastName}
          </Typography>
        </Stack>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <UpdatePatientForm formik={formik} />
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default PatientDetails;
