//libraries
import {
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Typography,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import StatCard from "../statCard";
import CloseButton from "../../components/closeButton";
import MenuListComposition from "../menuPopupState";

//constants
import useStyles from "./styles";
import {
  fetchDriverPharmacies,
  fetchDriversDetailsById,
} from "../../services/api/requests";
import { formatCanadianPhoneNumber } from "../../services/helper";

type CreateNewDriverProps = {
  orgId: string;
};

const pharmaciesMock = {
  pharmacies: {
    1: {
      name: "14th & Markham Shoppers",
      address: "50 Baylawn driver, Markham ON, Canada L3S 2R8",
      admin: "Admin",
      contact: "Contact",
      created: "Today",
    },
    2: {
      name: "Greg’s Shoppers Drugmart",
      address: "50 Baylawn driver, Markham ON, Canada L3S 2R8",
      admin: "Admin",
      contact: "Contact",
      created: "Today",
    },
  },
};

type AdminMetrics = {
  totalDeliveries: number;
  deliveryDuration: number;
};

interface DriverInfo {
  id: number;
  org_name: string;
}

const ChangeDriverInfo: React.FC<CreateNewDriverProps> = (props) => {
  const nav = useNavigate();

  const [pharmacies, setPharmacies] = useState<string[]>([]);
  const [newPharmacyId, setNewPharmacyId] = useState("");
  const params = useParams();
  const [driverInfo, setDriverInfo] = useState({
    email: null,
    phone_number: null,
    total_deliveries: null,
    avg_completion_time: null,
  });
  const [driversPharmacies, setDriversPharmacies] = useState([]);

  const handleAddPharmacy = () => {
    if (newPharmacyId.trim() !== "") {
      if (pharmaciesMock.pharmacies[newPharmacyId]) {
        if (!pharmacies.includes(newPharmacyId)) {
          setPharmacies([...pharmacies, newPharmacyId]);
          setNewPharmacyId("");
        } else {
        }
      } else {
      }
    }
  };

  const handleСlick = (id: number) => {
    if (pharmaciesMock.pharmacies[id]) {
      const updatedPharmacies = pharmacies.filter(
        (pharmacyId) => pharmacyId !== id.toString()
      );
      setPharmacies(updatedPharmacies);
    } else {
    }
  };

  const [metrics] = useState<AdminMetrics>({
    totalDeliveries: 100,
    deliveryDuration: 23,
  });

  const classes = useStyles();

  const getDriverInfo = async () => {
    try {
      const response = await fetchDriversDetailsById(
        props.orgId,
        params.driverId
      );
      // await fetchDriverStats(props.orgId, params.driverId);
      const pharmaciesAssigResponse = await fetchDriverPharmacies(
        props.orgId,
        params.driverId
      );

      setDriversPharmacies(pharmaciesAssigResponse.data.organizations);

      setDriverInfo({
        email: response.data.email,
        phone_number: response.data.phone_number,
        avg_completion_time: null,
        total_deliveries: null,
      });
    } catch (error) { }
  };

  useEffect(() => {
    getDriverInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unAssignPharmacy = async (phamracyId) => {
    try {
    } catch (error) { }
  };

  return (
    <>
      <Grid
        className={classes.gridContainer}
        style={{ alignItems: "baseline" }}
        container
        spacing={2}
      >
        <Grid item xs={7} md={7}>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper} elevation={2}>
              <Box className={classes.header}>
                <Grid
                  className={classes.gridContainer}
                  container
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Box className={classes.flexClass}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        className={classes.title}
                      >
                        Driver Details
                      </Typography>
                      <MenuListComposition />
                    </Box>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.detailText}
                    >
                      {formatCanadianPhoneNumber(driverInfo.phone_number)}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.detailText}
                    >
                      {driverInfo.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.card}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="subtitle1"
                  className={classes.subtitleClass}
                >
                  Assigned Pharmacies
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.textStyle}>
                Below are the pharmacies the driver has been assigned to.
              </Typography>
              <Box style={{ marginTop: 20 }}>
                {driversPharmacies?.length > 0 &&
                  driversPharmacies.map((item: DriverInfo, idx) => (
                    <Box
                      key={`assign-pharmacy-${idx}`}
                      className={classes.flexClass}
                    >
                      <Typography variant="body1" className={classes.textStyle}>
                        {item?.org_name}
                      </Typography>
                      <Button
                        className={classes.btnStyle}
                        onClick={() => unAssignPharmacy(item?.id)}
                      >
                        <Typography
                          variant="body1"
                          className={classes.textStyle}
                        >
                          Remove
                        </Typography>
                      </Button>
                    </Box>
                  ))}
              </Box>
              <Typography
                variant="body1"
                className={classes.textStyle}
                style={{ marginTop: 75 }}
              >
                Enter the pharmacy name below to assign the driver to the <br />{" "}
                pharmacy.
              </Typography>
              <Stack display="flex" flexDirection="column">
                {pharmacies.map((pharmacyId, index) => {
                  const pharmacy = pharmaciesMock.pharmacies[pharmacyId];
                  return (
                    <>
                      <MenuItem
                        key={index + 1}
                        style={{
                          marginLeft: 5,
                          justifyContent: "space-between",
                          paddingTop: 15,
                          paddingBottom: 15,
                          fontSize: 22,
                          lineHeight: "22px !important",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          className={classes.title}
                          style={{ margin: 5 }}
                        >
                          {pharmacy?.name}
                        </Typography>
                        <CloseButton
                          handleClick={() => handleСlick(Number(pharmacyId))}
                        />
                      </MenuItem>
                      <Divider />
                    </>
                  );
                })}
              </Stack>
              <Grid
                className={classes.gridContainer}
                container
                spacing={2}
                alignItems="center"
                style={{ marginTop: 12 }}
              >
                <Grid item xs={8} md={9} style={{ paddingBottom: 0 }}>
                  <TextField
                    className={classes.textField}
                    style={{ width: "100%" }}
                    value={newPharmacyId}
                    variant="outlined"
                    onChange={(e) => setNewPharmacyId(e.target.value)}
                    label="Pharmacy ID"
                    margin="normal"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  container
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPharmacy}
                    className={classes.addPharmacy}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.card}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="subtitle1"
                  className={classes.subtitleClass}
                >
                  Routing
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.textStyle}>
                To view the current route that this driver is on click the
                button below.
              </Typography>

              <Grid
                className={classes.gridContainer}
                container
                spacing={2}
                alignItems="center"
                style={{ marginTop: 12 }}
              >
                <Grid
                  item
                  xs={4}
                  md={3}
                  container
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      nav(`/drivers/${params.driverId}/activeroute`);
                    }}
                    className={classes.addPharmacy}
                  >
                    Active Route
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          className={classes.gridTags}
          container
          spacing={2}
          alignItems="center"
          xs={5}
          item
          md={5}
        >
          <StatCard
            label="Total Deliveries <br/> Completed"
            stat={metrics.totalDeliveries}
          />
          <Box className={classes.belowBox}>
            <StatCard
              label="Average Delivery <br/>
              Completion Time"
              stat={`${metrics.deliveryDuration} Minutes`}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangeDriverInfo;
