import React from "react";
import { Navigate } from "react-router-dom";

type MutualRouteProps = {
  authenticated: boolean;
  authenticatedPath: string;
  unauthenticatedPath: string;
};

const MutualRoute = (props: MutualRouteProps): JSX.Element => {
  const { authenticated, authenticatedPath, unauthenticatedPath } = props;

  return authenticated ? (
    <Navigate to={authenticatedPath} replace />
  ) : (
    <Navigate to={unauthenticatedPath} replace />
  );
};

export default MutualRoute;
