import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column wrap",
    marginBottom: theme.spacing(8),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: "0.9375rem",
    borderRadius: "0.625rem",
    paddingBottom: "2rem",
    background: "#FFF",
    boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.common.black,
    height: "16px",
    width: "16px",
    zIndex: 1,
  },
  heading: {
    marginTop: "0px !important",
    color: theme.palette.common.black,
    "font-weight": `${theme.typography.fontWeightBold} !important`,
    lineHeight: "22px !important",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  subHeading: {
    margin: "0.5rem 0",
  },
  textStyle: {
    marginTop: 3,
  },
  subtitleClass: {
    color: "#3C3C3C",
    fontWeight: 700,
  },
  IconBox: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    marginTop: 9,
  },

  flexClass: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnStyle: {
    backgroundColor: "transparent !important",
    width: "auto",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  gridContainer: {
    marginBottom: theme.spacing(0),
    display: "flex",
  },
}));
