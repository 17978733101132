import React, { useState } from "react";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-hot-toast";
import useStyles from "./styles";

import { deleteUser } from "../../../services/api/requests";
import { Box } from "@material-ui/core";

const DeleteUser: React.FC<{}> = (props) => {
  const classes = useStyles();

  const [delId, setDelId] = useState("");

  const formik = useFormik({
    initialValues: {
      uniqueId: "",
      orgId: "",
      confirmDeleting: false,
    },
    onSubmit: (values) => {
      if (!values.uniqueId) {
        toast.error(`Woops! Unique Id is required`);
        return;
      }
      deleteUser(Number(values.uniqueId))
        .then((resp) => {
          if (!resp) return;
          toast.success(`Successfully deleted user ${values.uniqueId}`);
          formik.resetForm();
          setDelId("");
        })
        .catch((err) => {
          toast.error(`Woops! Something went wrong when trying to delete user`);
          console.error(err);
        });
    },
  });

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <Paper className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.heading} variant="h6">
              Delete User
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              name="uniqueId"
              size="small"
              className={classes.nameInput}
              onChange={formik.handleChange}
              value={formik.values.uniqueId}
              onPaste={handlePaste}
              variant="outlined"
              label="Unique Id"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              required={true}
              name="delId"
              size="small"
              className={classes.nameInput}
              onChange={(e) => {
                setDelId(e.currentTarget.value);
              }}
              value={delId}
              onPaste={handlePaste}
              variant="outlined"
              label="Confirm Id"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              className={classes.confirmingText}
              control={
                <Checkbox
                  disabled={
                    !formik.values.uniqueId ||
                    !delId ||
                    formik.values.uniqueId !== delId
                  }
                  checked={formik.values.confirmDeleting}
                  onChange={formik.handleChange}
                  required={true}
                  name="confirmDeleting"
                  color="primary"
                />
              }
              label="I confirm that the user above will be deleted forever."
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box className={classes.createButtonContainer}>
              <Button
                disabled={
                  !formik.values.uniqueId ||
                  !delId ||
                  formik.values.uniqueId !== delId
                }
                className={classes.createButton}
                disableFocusRipple={true}
                disableRipple={true}
                type="submit"
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default DeleteUser;
