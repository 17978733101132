import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    color: "#FFFFFF",
    width: "500px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
    padding: "14px",
  },
  addressSearchField: {
    fontSize: "13px",
  },
}));
